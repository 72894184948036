import React from 'react';
import { get } from 'lodash';
import { useAuth0 } from '@auth0/auth0-react';
import { Title, useTranslate } from 'react-admin';
import { rootTranslate } from '../const';

function Welcome() {
  const t = useTranslate();
  const { user } = useAuth0();
  const firstName = get(user, 'http://govibe.com/first_name', '');
  const lastName = get(user, 'http://govibe.com/last_name', '');
  const fullName = `${lastName} ${firstName}`;
  const wellComeString = t(`${rootTranslate}.heading__welcome`, {
    name: fullName,
  });
  return <Title title={wellComeString} />;
}

export default Welcome;
