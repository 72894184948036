import { useState } from 'react';
import { Button, Box } from '@material-ui/core';
import { Confirm, useTranslate } from 'react-admin';
import { rootTranslate } from '../const';

/**
 * Show toolbar for select raw vehicle from list
 * @param  {[number]} total             [Total number of raw vechiles]
 * @param {[boolean]} importLoading    ["true" while calling `onImport`]
 * @param {[function]} onConfirm       [will trigger when click confirm button]
 * @param {[function]} onCancel        [will trigger when click cancel button]
 * @param {[function]} onError        [will trigger when have a error]
 */
const BulkImportActionButtons = (props) => {
  const translate = useTranslate();
  const [openConfirm, setOpenConfirm] = useState(false);
  const {
    total,
    selectedIds: ids = [],
    formData = [],
    importLoading,
    onConfirm,
    onCancel,
    onError,
  } = props;
  const data = formData.filter(
    (item) => ids.findIndex((id) => id === item.id) >= 0
  );
  const hasError = data.filter((item) => {
    const errors = item?.errors;
    for (const err in errors) {
      if (errors[err]) {
        return true;
      }
    }
    return false;
  })?.length;

  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm(data, true);
    }
    setOpenConfirm(false);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const onPartialImport = () => {
    if (hasError) {
      onError();
    } else {
      setOpenConfirm(true);
    }
  };

  const dialogMessage = (
    <>
      <Box component="strong" mr={1} color="#6055DF">
        {ids.length}
      </Box>
      {translate(`${rootTranslate}.dialog_message`)}
    </>
  );

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <Box color="#000">
          <Box component="strong" color="#6055DF">
            {ids.length}
            {'/'}
          </Box>
          {`${total} ${translate('common__selected')}`}
        </Box>
        <Box display="flex">
          <Box mr={2}>
            <Button variant="contained" onClick={onCancel}>
              <Box color="error.main">
                {translate(`${rootTranslate}.cancel_button_label`)}
              </Box>
            </Button>
          </Box>

          <Button
            disabled={importLoading || !ids.length}
            variant="contained"
            color="primary"
            onClick={onPartialImport}
          >
            {translate(`${rootTranslate}.import_button_label`)}
          </Button>
        </Box>
      </Box>
      <Confirm
        isOpen={openConfirm}
        title={translate(`${rootTranslate}.dialog_title`)}
        content={dialogMessage}
        onConfirm={handleConfirm}
        onClose={handleCloseConfirm}
      />
    </>
  );
};

export default BulkImportActionButtons;
