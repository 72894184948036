import React from 'react';
import { Box, Typography } from '@material-ui/core';
import cs from 'classnames';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { createStyles, withStyles } from '@material-ui/core/styles';

const styles = (theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      border: `1px solid ${theme.palette.primary.main}`,
    },
    content: {
      padding: theme.spacing(0, 2),
    },
    icon: {
      width: '80px',
      height: '80px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: theme.typography.pxToRem(32),
      color: theme.palette.primary.main,
    },
    amount: {
      fontSize: theme.typography.pxToRem(18),
      fontWeight: 'bold',
    },
    iconSolid: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  });

function BusinessMetricItem({
  classes,
  iconSolid = false,
  amount = null,
  title = '',
}) {
  const amountText = amount !== null && amount !== undefined ? amount : '--';
  return (
    <Box className={classes.root} display="flex">
      <Box
        className={cs(classes.icon, {
          [classes.iconSolid]: iconSolid,
        })}
      >
        <AttachMoneyIcon fontSize="inherit" />
      </Box>
      <Box className={classes.content}>
        {title && (
          <Typography className={classes.title} variant="body2">
            {title}
          </Typography>
        )}
        <Typography className={classes.amount}>{amountText}</Typography>
      </Box>
    </Box>
  );
}

export default withStyles(styles)(BusinessMetricItem);
