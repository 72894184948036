import { useCallback, useState } from 'react';
import { useMutation, useTranslate, useNotify, useRefresh } from 'react-admin';
import UserStatusButton from '@bit/vibedev.web.components.user-status-button';
import DeactivateDialog from '@bit/vibedev.web.components.deactivate-dialog';
import ConfirmationDialog from '@bit/vibedev.web.components.confirmation-dialog';
import { get } from 'lodash';
import { VEHICLE_ACTION, VEHICLE_STATUS_OP, VEHICLE_STATUS } from '../const';

const StatusToggle = ({ record, source }) => {
  const { id, vin = '--' } = record;
  const status = record[source];
  const isActive = status !== VEHICLE_STATUS.UNAVAILABLE;
  const isActionable = status !== VEHICLE_STATUS.PENDING;
  const isPending = status === VEHICLE_STATUS.PENDING;

  const translate = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();

  const [open, setOpen] = useState(false);
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const [checkActivatable, { loading: activatableLoading }] = useMutation(
    {
      resource: 'vehicles',
      type: 'others',
      payload: {
        action: VEHICLE_ACTION.CHECK_DEACTIVATABLE,
        id,
      },
    },
    {
      onSuccess: () => {
        setOpen(true);
      },
      onFailure: (err) => {
        const { data } = err.body;
        if (data && data.booking_numbers && data.booking_numbers.length) {
          setAlertMessage(
            translate('resources.vehicles.messages__unable_to_deactivate', {
              bookingNumbers: data.booking_numbers.join(', '),
            })
          );
          setOpenAlertDialog(true);
          return;
        }
        const errorMessage = get(
          err,
          'body.messages.0.message',
          'ra.page.error'
        );
        notify(errorMessage, 'error');
        return;
      },
    }
  );

  const [changeStatus, { loading: changeLoading }] = useMutation(
    {
      resource: 'vehicles',
      type: 'others',
    },
    {
      onSuccess: () => {
        setOpen(false);
        refresh();
        notify('resources.vehicles.messages__change_status_sucess');
      },
    }
  );

  const onCloseAlert = useCallback((o) => setOpenAlertDialog(o), [
    setOpenAlertDialog,
  ]);

  const onToggleStatus = useCallback(
    (newStatus, reason) => {
      changeStatus({
        payload: {
          action: VEHICLE_ACTION.STATUS_CHANGE,
          id,
          data: {
            op: newStatus
              ? VEHICLE_STATUS_OP.ACTIVATE
              : VEHICLE_STATUS_OP.DEACTIVATE,
            reason,
          },
        },
      });
    },
    [changeStatus, id]
  );

  if (isPending) {
    return null;
  }

  return (
    <>
      <UserStatusButton
        status={isActive}
        onToggleStatus={onToggleStatus}
        disabled={changeLoading}
        onCheckValidToDeactivate={checkActivatable}
        activateLabel={translate('common__activate')}
        deactivateLabel={translate('common__deactivate')}
      />
      <DeactivateDialog
        open={open}
        setOpen={setOpen}
        disabled={(isActionable && activatableLoading) || changeLoading}
        onToggleStatus={onToggleStatus}
        title={translate('resources.vehicles.constants__deactivate_vehicle')}
        content={translate('resources.vehicles.constants__deactivate_message', {
          number: vin,
        })}
        descriptionLabel={translate('common__description')}
        cancelLabel={translate('common__cancel')}
        confirmLabel={translate('common__confirm')}
      />
      <ConfirmationDialog
        title={translate('resources.vehicles.constants__unable_to_deactivate')}
        open={openAlertDialog}
        onToggle={onCloseAlert}
      >
        {alertMessage}
      </ConfirmationDialog>
    </>
  );
};

export default StatusToggle;
