import { useTranslate } from 'react-admin';
import { get } from 'lodash';
import { rootTranslate as dashboardRootTranslate } from 'features/dashboard/const';
import TitleWithBackButton from 'components/TitleWithBackButton';
import { rootTranslate } from '../const';

const VehicleListTitle = ({ type = '' }) => {
  const t = useTranslate();
  const defaultTitle = t(`${rootTranslate}.name`, { smart_count: 1 });
  const titles = {
    listed: t(`${dashboardRootTranslate}.label__listed_vehicle`),
    unlisted: t(`${dashboardRootTranslate}.label__unlisted_vehicle`),
    reserved: t(`${dashboardRootTranslate}.label__reserved_vehicle`),
    booked: t(`${dashboardRootTranslate}.label__booked_vehicle`),
    'in-trip': `${t(
      `${dashboardRootTranslate}.label__in_trip`
    )} ${defaultTitle.toLocaleLowerCase()}`,
    'return-delayed': `${t(
      `${dashboardRootTranslate}.label__return_delayed`
    )} ${defaultTitle.toLocaleLowerCase()}`,
  };
  const title = get(titles, type, defaultTitle);

  return <TitleWithBackButton basePath="/">{title}</TitleWithBackButton>;
};

export default VehicleListTitle;
