import { get } from 'lodash';
import { useTranslate, TextField } from 'react-admin';
import { Card, CardContent, Box, Grid } from '@material-ui/core';
import { useTheme, withStyles } from '@material-ui/core/styles';
import Carousel from 'react-material-ui-carousel';
import CustomTypography from 'components/CustomTypography';
import VehicleStatusField from '../components/VehicleStatusField';
import ImageWrapper from 'components/ImageWrapper';
import StatusToggle from '../components/StatusToggle';
import { VEHICLE_STATUS } from '../const';
import ReservedToggle from '../components/ReservedToggle';
import MarkedAsUnavailableDate from '../components/MarkedAsUnavailableDate';

const SideCard = ({ record }) => {
  const make = get(record, 'vehicle_make.name', '--');
  const model = get(record, 'vehicle_model.name', '--');
  const year = get(record, 'vehicle_year', '--');
  const sellingPrice = get(record, 'selling_price');
  const bookValue = get(record, 'book_value');
  const belongTo = get(record, 'dealer.name', '--');
  const vehiclePhotos = get(record, 'vehicle_images', '--');
  const enabled = get(record, 'status') !== VEHICLE_STATUS.UNAVAILABLE;

  return (
    <Card>
      <CardContent>
        <Box textAlign="right">
          <VehicleStatusField record={record} />
          {!enabled && (
            <Box mt={1}>
              <TextField
                record={record}
                source="deactivate_reason"
                emptyText="--"
              />
            </Box>
          )}
        </Box>
        <Box mt={2}>
          <PhotoCarousel photos={vehiclePhotos} />
        </Box>
        <Box mt={3}>
          <CustomTypography variant="h6" component="span" fontWeight="bold">
            {`${make} ${model} `}
          </CustomTypography>
          <CustomTypography variant="h6" component="span">
            {year}
          </CustomTypography>
        </Box>
        <InfoRow label="resources.vehicles.fields.selling_price" mt={2}>
          {sellingPrice ? `$${sellingPrice}` : '--'}
        </InfoRow>
        <InfoRow label="resources.vehicles.fields.book_value" mt={2}>
          {bookValue ? `$${bookValue}` : '--'}
        </InfoRow>
        <InfoRow label="resources.vehicles.belong_to" mt={2}>
          {belongTo}
        </InfoRow>
        <Box component={Grid} container justify="space-between" pt={2}>
          <StatusToggle record={record} source="status" />
          <ReservedToggle record={record} source="reserved" />
        </Box>

        {enabled && (
          <Box
            p={3}
            mt={2}
            textAlign="center"
            border={1}
            borderRadius={5}
            borderColor="common.silverSand"
          >
            <MarkedAsUnavailableDate id={record?.id} />
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

const InfoRow = ({ children, label, ...props }) => {
  const trans = useTranslate();
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      {...props}
    >
      <CustomTypography variant="body2" fontWeight="bold">
        {trans(label)}
      </CustomTypography>
      {typeof children !== 'string' ? (
        children
      ) : (
        <CustomTypography variant="body2" textAlign="right">
          {children}
        </CustomTypography>
      )}
    </Box>
  );
};

const PhotoCarousel = withStyles({
  img: {
    transform: `translate(-50%, -50%)`,
  },
})(({ photos = [], classes }) => {
  const theme = useTheme();
  const moreThan1 = photos.length > 1;
  if (photos.length === 0) {
    return (
      <Box
        paddingTop="56.25%"
        position="relative"
        bgcolor={theme.palette.grey['200']}
        borderRadius={theme.shape.borderRadius}
      />
    );
  }
  return (
    <Carousel
      navButtonsAlwaysVisible={moreThan1}
      indicators={moreThan1}
      timeout={100}
      autoPlay={false}
    >
      {photos.map(({ url, id }) => (
        <ImageWrapper src={url} alt="vehicle-photo" key={id} />
      ))}
    </Carousel>
  );
});

export default SideCard;
