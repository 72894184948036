import { fetchUtils, HttpError } from 'react-admin';
import _ from 'lodash';

const apiUrl = process.env.REACT_APP_API_URL;

const httpClient = fetchUtils.fetchJson;

const errorHandler = (err) => {
  const errorMessage = _.get(err, 'body.messages.0.message', 'ra.page.error');
  return Promise.reject(new HttpError(errorMessage, err.status, err.body));
};

const buildHeadersFactory = async (getAccessTokenSilently, isFile = false) => {
  const headers = new Headers({ Accept: 'application/json' });
  const token = await getAccessTokenSilently();
  headers.set('Authorization', `Bearer ${token}`);

  if (isFile) {
    // https://stackoverflow.com/questions/39280438/fetch-missing-boundary-in-multipart-form-data-post#comment73780091_39281156
    delete headers['Content-Type'];
  }

  return headers;
};

const getHeaders = async ({ url, getAccessTokenSilently, isFile = false }) => {
  let headers;
  if (_.startsWith(url, apiUrl)) {
    headers = await buildHeadersFactory(getAccessTokenSilently, isFile);
  }
  return headers;
};

export const parseApi = (json) => {
  return {
    data: json.data,
    total: _.get(json, 'meta.pagination.total', 0),
  };
};

const request = async ({
  url,
  getAccessTokenSilently,
  isFile,
  method = 'GET',
  body,
  onSuccess = parseApi,
  onError = errorHandler,
}) => {
  const finalUrl = `${apiUrl}${url}`;
  const headers = await getHeaders({
    url: finalUrl,
    getAccessTokenSilently,
    isFile,
  });
  const options = {
    method,
    body,
    headers,
  };
  return httpClient(finalUrl, options)
    .then(({ json }) => onSuccess(json))
    .catch(onError);
};

export default request;
