import rootDataProvider from './dataProvider';

export function buildProviderByType(type) {
  return {
    ...rootDataProvider,
    getList: ({ params }) => {
      const { filter = {}, ...otherParams } = params;
      const { search = '', ...otherFilter } = filter;
      let extraParams = { type };

      if (type) {
        extraParams = { ...extraParams, type };
      }

      if (search) {
        extraParams = { ...extraParams, search };
      }

      return {
        url: '/vehicles',
        extraParams,
        params: {
          ...otherParams,
          filter: { ...otherFilter },
        },
        parser: (json) => {
          const { data, meta } = json;
          const { pagination } = meta || {};
          const { total } = pagination || 0;

          return {
            data,
            total,
          };
        },
      };
    },
    getOne: () => {
      return {
        url: '/vehicles',
      };
    },
    update: () => {
      return {
        url: '/vehicles',
      };
    },
    delete: () => {
      return {
        url: '/vehicles',
      };
    },
  };
}
