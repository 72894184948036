import { parseInvoices } from './parser';

const parseParams = () => {
  const hash = window.location.hash;
  const splitted = hash ? hash.split('/invoices/') : [];
  const range = splitted && splitted[1] ? splitted[1] : '';

  return range;
};

const dataProvider = {
  getList: ({ params }) => {
    const range = parseParams();

    if (!range) {
      return;
    }

    const url = `/invoices-weekly/${range}`;

    return {
      url,
      parser: (json) => {
        const { data, meta } = json;
        const { pagination } = meta || {};
        const { total } = pagination || 0;

        return {
          data: parseInvoices(data),
          total,
        };
      },
    };
  },
  others: ({ params }) => {
    const { action } = params;
    const range = parseParams();

    switch (action) {
      case 'GET_TOTAL':
        return {
          url: `/invoices-income/${range}`,
          method: 'GET',
          parser: (json) => json,
        };
      default:
        break;
    }
  },
};

export default dataProvider;
