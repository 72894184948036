import React, { useState, useMemo, useEffect } from 'react';
import { get } from 'lodash';
import { useListContext, useTranslate, required } from 'react-admin';
import { TextField, makeStyles } from '@material-ui/core';

const useStyleInput = makeStyles((theme) => ({
  input: {
    fontSize: '0.875rem',
    backgroundColor: '#fff',
    borderRadius: theme.shape.borderRadius,
    '&:disabled': {
      backgroundColor: '#F4F4F4',
    },
  },
  error: {
    '& input::placeholder': {
      color: 'red',
      opacity: 0.8,
    },
  },
}));

const TextInput = ({
  record,
  source,
  onChange,
  componentProps = {},
  showError = false,
  placeholder = '',
  notSelectedPlaceholder = '',
  ...props
}) => {
  const inputClasses = useStyleInput();
  const { id } = record;
  const t = useTranslate();
  const defaultValue = get(record, source, '');
  const [value, setValue] = useState(defaultValue);
  const [dirty, setDirty] = useState(false);
  const { selectedIds } = useListContext(props);
  const selected = selectedIds.findIndex((item) => item === id) >= 0;
  const errorMessage = useMemo(() => {
    return selected ? t(required()(value, {})) : '';
  }, [selected, value]);

  const handleChange = (event) => {
    setValue(event.target.value);
    setDirty(true);
    if (onChange) {
      onChange({
        value: event.target.value || defaultValue,
        id,
        error: selected && t(required()(event.target.value, {})),
        source,
      });
    }
  };

  // This effect only run one time when user selected record. Purpose make a initial value. We can validate check error When record is selected, but not make any change
  useEffect(() => {
    if (!dirty && selected && onChange) {
      const finalValue = value || defaultValue;
      setDirty(true);
      onChange({
        value: finalValue,
        id,
        error: t(required()(finalValue, {})),
        source,
      });
    }
  }, [selected, dirty, defaultValue, value]);

  return (
    <TextField
      defaultValue={defaultValue}
      placeholder={selected ? placeholder : notSelectedPlaceholder}
      onChange={handleChange}
      variant="outlined"
      InputProps={{
        classes: inputClasses,
      }}
      {...componentProps}
      disabled={!selected}
      error={showError && !!errorMessage}
      fullWidth
      size="small"
    />
  );
};
export default TextInput;
