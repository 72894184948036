import * as React from 'react';
import { ShowButton } from 'react-admin';
import { TextField, Filter, ReferenceField } from 'react-admin';
import { useLocation } from 'react-router-dom';
import { CustomList } from 'layout';
import { rootTranslate as rootTranslateVehicle } from 'features/vehicle/const';
import DataGridWithLoading from 'components/DataGridWithLoading';
import { TextInput } from 'components/OutlinedInputs';
import DateField from 'components/DateField';
import { parseQueryFromLocation, formatDateTime } from 'utils/format';
import VehicleMakeModelYearField from 'components/VehicleMakeModelYearField';
import { rootTranslate } from '../const';
import CustomStatusChip from './CustomStatusChip';
import BookingListTitle from './BookingListTitle';
import BookingNumberField from './BookingNumberField';

const ListFilters = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="search" alwaysOn resettable />
  </Filter>
);

const BookingList = ({
  type,
  showPickupDatetime = true,
  showDropoffDatetime = true,
  ...props
}) => {
  const location = useLocation();
  const { filter = '' } = parseQueryFromLocation(location);
  const { fromDate = null, toDate = null } = filter ? JSON.parse(filter) : {};
  const { getReturnDate: getReturnFromDate } = formatDateTime(fromDate);
  const { getReturnDate: getReturnToDate } = formatDateTime(toDate);

  return (
    <CustomList
      sort={{ field: 'number', order: 'DESC' }}
      {...props}
      title={
        <BookingListTitle type={type} fromDate={fromDate} toDate={toDate} />
      }
      filters={<ListFilters />}
      filter={{ fromDate: getReturnFromDate(), toDate: getReturnToDate() }}
      perPage={25}
    >
      <DataGridWithLoading>
        <BookingNumberField
          source="number"
          label={`${rootTranslate}.fields.number`}
          sortable={false}
        />
        <ReferenceField
          label={`${rootTranslateVehicle}.make_model_year`}
          reference="vehicles"
          source="vehicle_id"
          sortable={false}
          link={false}
        >
          <VehicleMakeModelYearField />
        </ReferenceField>
        <ReferenceField
          label={`${rootTranslateVehicle}.fields.plate_number`}
          reference="vehicles"
          source="vehicle_id"
          linkType="show"
          sortable={false}
        >
          <TextField source="plate_number" />
        </ReferenceField>
        {showPickupDatetime && (
          <DateField
            source="pickup_datetime"
            label={`${rootTranslate}.fields.pickup_datetime`}
            showTime
            sortable={false}
          />
        )}
        {showDropoffDatetime && (
          <DateField
            source="return_datetime"
            label={`${rootTranslate}.fields.return_datetime`}
            showTime
            sortable={false}
          />
        )}
        <TextField
          source="pickup_location.name"
          label={`${rootTranslate}.fields.pickup_location`}
          sortable={false}
        />
        <CustomStatusChip
          source="state"
          label={`${rootTranslate}.fields.state`}
          sortable={false}
        />
        <ShowButton label="" />
      </DataGridWithLoading>
    </CustomList>
  );
};

export default BookingList;
