export const dashboardActions = {
  UPDATE_DATE: 'UPDATE_DATE',
  SHOW_REMINDER: 'SHOW_REMINDER',
  REMEMBER_REMINDER: 'REMEMBER_REMINDER',
};

export const dashboardActionCreators = {
  updateDate: ({ fromDate, toDate }) => ({
    type: dashboardActions.UPDATE_DATE,
    payload: {
      fromDate,
      toDate,
    },
  }),
  showReminder: (show = false) => ({
    type: dashboardActions.SHOW_REMINDER,
    payload: show,
  }),
  rememberReminder: () => ({
    type: dashboardActions.REMEMBER_REMINDER,
  }),
};
