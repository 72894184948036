import React from 'react';
import { Typography } from '@material-ui/core';
import { get } from 'lodash';
import { formatDateTime } from 'utils/format';

const DateField = ({
  record,
  source,
  showTime = false,
  emptyText,
  basePath,
  ...props
}) => {
  const value = get(record, source);

  if (!value) {
    return emptyText ? (
      <Typography component="span" variant="body2">
        {emptyText}
      </Typography>
    ) : null;
  }
  const { getDisplayDateTime, getDisplayDate } = formatDateTime(value);
  const dateString = showTime ? getDisplayDateTime() : getDisplayDate();

  return (
    <Typography component="span" variant="body2">
      {dateString}
    </Typography>
  );
};

DateField.defaultProps = {
  addLabel: true,
};

export default DateField;
