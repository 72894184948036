import React from 'react';
import BookingListByType from '../components/BookingListByType';
import { type } from './const';

const BookingList = (props) => {
  return (
    <BookingListByType {...props} type={type} showDropoffDatetime={false} />
  );
};

export default BookingList;
