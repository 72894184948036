import * as React from 'react';
import cs from 'classnames';
import { Fragment } from 'react';
import List from '@material-ui/core/List';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Collapse from '@material-ui/core/Collapse';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import { ExpandMore, ChevronRightOutlined } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  header: {
    color: '#fff',
    fontSize: theme.typography.fontSize,
    padding: theme.spacing(0, 3),
    height: 40,
    lineHeight: 40,
  },
  icon: {
    minWidth: theme.spacing(4),
    color: '#fff',
  },
  sidebarIsOpen: {
    '& a': {
      paddingLeft: theme.spacing(7),
      transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    },
  },
  sidebarIsClosed: {
    '& a': {
      paddingLeft: theme.spacing(2),
      transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    },
  },
  expandIcon: {
    marginLeft: 'auto',
  },
  list: {
    background: '#38234E',
  },
}));

const SubMenu = ({
  handleToggle,
  sidebarIsOpen,
  isOpen,
  name,
  icon,
  children,
  dense,
}) => {
  const classes = useStyles();

  const header = (
    <MenuItem
      dense={dense}
      className={classes.header}
      button
      onClick={handleToggle}
    >
      <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
      {name}

      {isOpen ? (
        <ExpandMore className={classes.expandIcon} />
      ) : (
        <ChevronRightOutlined className={classes.expandIcon} />
      )}
    </MenuItem>
  );

  return (
    <Fragment>
      {sidebarIsOpen || isOpen ? (
        header
      ) : (
        <Tooltip title={name} placement="right">
          {header}
        </Tooltip>
      )}
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List
          dense={dense}
          component="div"
          disablePadding
          className={cs(classes.list, {
            [classes.sidebarIsOpen]: sidebarIsOpen,
            [classes.sidebarIsClosed]: !sidebarIsOpen,
          })}
        >
          {children}
        </List>
      </Collapse>
    </Fragment>
  );
};

export default SubMenu;
