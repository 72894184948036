import { Grid } from '@material-ui/core';
import { Labeled, TextField } from 'react-admin';

const InfoTabContent = ({ record }) => (
  <Grid container spacing={3}>
    <Grid component={Labeled} item xs={4} source="vin" resource="vehicles">
      <TextField source="vin" record={record} />
    </Grid>
    <Grid
      component={Labeled}
      item
      xs={4}
      source="allocation_type.name"
      resource="vehicles"
    >
      <TextField source="allocation_type.name" record={record} />
    </Grid>
    <Grid
      component={Labeled}
      item
      xs={4}
      source="vehicle_make"
      resource="vehicles"
    >
      <TextField source="vehicle_make.name" record={record} />
    </Grid>
    <Grid
      component={Labeled}
      item
      xs={4}
      source="vehicle_model"
      resource="vehicles"
    >
      <TextField source="vehicle_model.name" record={record} />
    </Grid>
    <Grid
      component={Labeled}
      item
      xs={4}
      source="vehicle_year"
      resource="vehicles"
    >
      <TextField source="vehicle_year" record={record} />
    </Grid>
    <Grid
      component={Labeled}
      item
      xs={4}
      source="number_of_doors"
      resource="vehicles"
    >
      <TextField source="number_of_doors" record={record} emptyText="--" />
    </Grid>
    <Grid
      component={Labeled}
      item
      xs={4}
      source="number_of_seats"
      resource="vehicles"
    >
      <TextField source="number_of_seats" record={record} emptyText="--" />
    </Grid>
    <Grid
      component={Labeled}
      item
      xs={4}
      source="fuel_type"
      resource="vehicles"
    >
      <TextField source="fuel_type" record={record} emptyText="--" />
    </Grid>
    <Grid
      component={Labeled}
      item
      xs={4}
      source="fuel_tank_capacity"
      resource="vehicles"
    >
      <TextField source="fuel_tank_capacity" record={record} emptyText="--" />
    </Grid>
    <Grid
      component={Labeled}
      item
      xs={4}
      source="exterior_color"
      resource="vehicles"
    >
      <TextField source="exterior_color" record={record} emptyText="--" />
    </Grid>
    <Grid
      component={Labeled}
      item
      xs={4}
      source="interior_color"
      resource="vehicles"
    >
      <TextField source="interior_color" record={record} emptyText="--" />
    </Grid>
    <Grid
      component={Labeled}
      item
      xs={4}
      source="stock_number"
      resource="vehicles"
    >
      <TextField source="stock_number" record={record} emptyText="--" />
    </Grid>
  </Grid>
);

export default InfoTabContent;
