import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import DateFnsUtils from '@date-io/date-fns';
import { DISPLAYED_DATE_FORMAT } from 'src/constants';

function CustomDatePicker({ providerOptions, ...props }) {
  return (
    <MuiPickersUtilsProvider {...providerOptions}>
      <DatePicker
        margin="normal"
        fullWidth
        size="small"
        inputVariant="outlined"
        format={DISPLAYED_DATE_FORMAT}
        InputProps={{
          endAdornment: <CalendarTodayIcon />,
        }}
        {...props}
      />
    </MuiPickersUtilsProvider>
  );
}

CustomDatePicker.defaultProps = {
  providerOptions: {
    utils: DateFnsUtils,
    locale: undefined,
  },
};

export default CustomDatePicker;
