import rootResource from '../';
import dataProvider from './dataProvider';
import List from './List';

export default {
  ...rootResource,
  list: List,
  create: undefined,
  dataProvider,
};
