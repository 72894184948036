export const vibeTheme = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1025,
      lg: 1280,
      xl: 1600,
    },
  },
  palette: {
    primary: {
      main: '#6055DF',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#C6C6C6',
      contrastText: '#FFFFFF',
    },
    error: {
      main: '#EF6565',
      contrastText: '#FFFFFF',
    },
    warning: {
      main: '#FFB946',
      contrastText: '#FFFFFF',
    },
    info: {
      main: '#407ACE',
      contrastText: '#FFFFFF',
    },
    success: {
      main: '#08B4A4',
      contrastText: '#FFFFFF',
    },
    red: {
      main: '#F44336',
    },
    type: 'light',
    common: {
      brandPurple: '#442C5D',
      mapBlue: '#316EE5',
      statusRed: '#EF6565',
      statusYellow: '#FFB946',
      pink: '#9673BB',
      lightBlack: '#11263C',
      lightPurple: '#64309A',
      aluminium: '#A2A3A5',
      midGrey: '#808080',
      silverSand: '#C6C6C6',
      lavender: '#E9EBFC',
      mustard: '#FFDA58',
    },
  },
  typography: {
    fontFamily: [
      'Open Sans',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  shape: {
    borderRadius: 5,
  },
  overrides: {
    MuiPaper: {
      elevation1: {
        boxShadow: 'none',
      },
      root: {
        border: '1px solid #e0e0e3',
        backgroundClip: 'padding-box',
      },
    },
    MuiButton: {
      root: {
        fontSize: '0.865rem',
        textTransform: 'unset',
        fontWeight: 'bold',
        padding: '9px 33px',
        lineHeight: 1,
        borderRadius: '5px',
      },
    },
    MuiAppBar: {
      colorSecondary: {
        color: '#808080',
        backgroundColor: '#fff',
      },
    },
    MuiLinearProgress: {
      colorPrimary: {
        backgroundColor: '#f5f5f5',
      },
      barColorPrimary: {
        backgroundColor: '#d7d7d7',
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
        '&$disabled': {
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
      },
    },
    MuiTableRow: {
      root: {
        '&$hover': {
          '&:hover': {
            backgroundColor: '#E6E8FA',
          },
        },
      },
    },
    RaLayout: {
      root: {
        backgroundColor: '#E5E2EB',
      },
      content: {
        paddingLeft: '24px',
      },
    },
    RaListToolbar: {
      toolbar: {
        flexFlow: 'row-reverse',
      },
    },
    RaDatagrid: {
      table: {
        border: '1px solid #C6C6C6',
      },
      headerRow: {
        height: '45px',
      },
      headerCell: {
        fontWeight: 'bold',
        fontSize: '0.8125rem', // 13px
        '&:not(:last-child)::after': {
          content: `''`,
          display: 'block',
          height: '80%',
          top: '10%',
          right: 0,
          position: 'absolute',
          borderRight: '1px solid #C6C6C6',
        },
      },
    },
  },
};
