import React, {
  Component,
  createElement,
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { withRouter } from 'react-router-dom';
import {
  // createMuiTheme,
  // https://github.com/mui-org/material-ui/issues/13394#issuecomment-619266831
  unstable_createMuiStrictModeTheme as createMuiTheme,
  withStyles,
  createStyles,
} from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { ComponentPropType } from 'ra-core';
import compose from 'lodash/flowRight';
import {
  AppBar as DefaultAppBar,
  Sidebar as DefaultSidebar,
  Menu as DefaultMenu,
  Notification as DefaultNotification,
  Error as DefaultError,
  defaultTheme,
} from 'react-admin';

const styles = (theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      zIndex: 1,
      minHeight: '100vh',
      backgroundColor: theme.palette.background.default,
      position: 'relative',
      minWidth: 'fit-content',
      width: '100%',
      color: theme.palette.getContrastText(theme.palette.background.default),
    },
    appFrame: {
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
    },
    contentWithSidebar: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      flexBasis: 0,
      padding: theme.spacing(3),
      maxWidth: '100vw',
      boxSizing: 'border-box',
      // paddingTop: theme.spacing(1),
      // paddingLeft: 0,
      // [theme.breakpoints.up('xs')]: {
      //   paddingLeft: 5,
      // },
      // [theme.breakpoints.down('sm')]: {
      //   padding: 0,
      // },
    },
  });

class LayoutWithoutTheme extends Component {
  state = { hasError: false, errorMessage: null, errorInfo: null };

  constructor(props) {
    super(props);
    /**
     * Reset the error state upon navigation
     *
     * @see https://stackoverflow.com/questions/48121750/browser-navigation-broken-by-use-of-react-error-boundaries
     */
    props.history.listen(() => {
      if (this.state.hasError) {
        this.setState({ hasError: false });
      }
    });
  }

  componentDidCatch(errorMessage, errorInfo) {
    this.setState({ hasError: true, errorMessage, errorInfo });
  }

  render() {
    const {
      appBar,
      children,
      classes,
      className,
      error,
      dashboard,
      logout,
      menu,
      notification,
      open,
      sidebar,
      title,
      staticContext, // extract this prop so that it wont be pass to the div
      ...props
    } = this.props;
    const { hasError, errorMessage, errorInfo } = this.state;
    return (
      <>
        <div
          className={classnames('layout', classes.root, className)}
          {...props}
        >
          <div className={classes.appFrame}>
            {createElement(sidebar, {
              children: createElement(menu, {
                logout,
                hasDashboard: !!dashboard,
              }),
            })}
            <main className={classes.contentWithSidebar}>
              {createElement(appBar, { title, open, logout })}
              <div className={classes.content}>
                {hasError
                  ? createElement(error, {
                      error: errorMessage,
                      errorInfo,
                      title,
                    })
                  : children}
              </div>
            </main>
          </div>
        </div>
        {createElement(notification)}
      </>
    );
  }

  static propTypes = {
    appBar: ComponentPropType,
    children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    classes: PropTypes.object,
    className: PropTypes.string,
    dashboard: ComponentPropType,
    error: ComponentPropType,
    history: PropTypes.object.isRequired,
    logout: PropTypes.element,
    menu: ComponentPropType,
    notification: ComponentPropType,
    open: PropTypes.bool,
    sidebar: ComponentPropType,
    title: PropTypes.node.isRequired,
  };

  static defaultProps = {
    appBar: DefaultAppBar,
    error: DefaultError,
    menu: DefaultMenu,
    notification: DefaultNotification,
    sidebar: DefaultSidebar,
  };
}

const mapStateToProps = (state) => ({
  open: state.admin.ui.sidebarOpen,
});

const EnhancedLayout = compose(
  connect(
    mapStateToProps,
    {} // Avoid connect passing dispatch in props
  ),
  withRouter,
  withStyles(styles, { name: 'RaLayout' })
)(LayoutWithoutTheme);

const Layout = ({ theme: themeOverride, ...props }) => {
  const themeProp = useRef(themeOverride);
  const [theme, setTheme] = useState(createMuiTheme(themeOverride));

  useEffect(() => {
    if (themeProp.current !== themeOverride) {
      themeProp.current = themeOverride;
      setTheme(createMuiTheme(themeOverride));
    }
  }, [themeOverride, themeProp, theme, setTheme]);

  return (
    <ThemeProvider theme={theme}>
      <EnhancedLayout {...props} />
    </ThemeProvider>
  );
};

Layout.propTypes = {
  theme: PropTypes.object,
};

Layout.defaultProps = {
  theme: defaultTheme,
};

export default Layout;
