import { makeStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import WarningRounded from '@material-ui/icons/WarningRounded';
import { red } from 'src/status';

const useStyles = makeStyles((theme) => ({
  tooltip: {
    background: '#F8F8F8',
    border: '1px solid #A0A0A0',
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.2)',
    borderRadius: theme.shape.borderRadius,
    fontSize: '12px',
    color: '#202020',
  },
  warningIcon: {
    color: red,
  },
}));

const AlertTooltip = ({ title }) => {
  const classes = useStyles();
  return (
    <Tooltip
      title={title}
      classes={{ tooltip: classes.tooltip }}
      placement="top"
    >
      <WarningRounded className={classes.warningIcon} />
    </Tooltip>
  );
};

AlertTooltip.propTypes = {
  title: PropTypes.string,
};

export default AlertTooltip;
