import { get } from 'lodash';
import { TextField } from 'react-admin';
import { Card, CardContent, Box, Grid } from '@material-ui/core';
import CustomTypography from 'components/CustomTypography';
import ImageWrapper from 'components/ImageWrapper';
import StatusToggle from '../components/StatusToggle';
import VehicleStatusField from '../components/VehicleStatusField';
import { VEHICLE_STATUS } from '../const';
import ReservedToggle from '../components/ReservedToggle';
import MarkedAsUnavailableDate from '../components/MarkedAsUnavailableDate';

const SideCard = ({ record }) => {
  const make = get(record, 'vehicle_make.name', '--');
  const model = get(record, 'vehicle_model.name', '--');
  const year = get(record, 'vehicle_year', '--');
  const mainPhotoUrl = get(record, 'vehicle_images.0.url', null);
  const enabled = get(record, 'status') !== VEHICLE_STATUS.UNAVAILABLE;

  return (
    <Card>
      <CardContent>
        <Box textAlign="right">
          <VehicleStatusField record={record} />
          {!enabled && (
            <Box mt={1}>
              <TextField
                record={record}
                source="deactivate_reason"
                emptyText="--"
              />
            </Box>
          )}
        </Box>
        <Box mt={2}>
          <ImageWrapper src={mainPhotoUrl} />
        </Box>
        <Box mt={3}>
          <CustomTypography variant="h6" component="span" fontWeight="bold">
            {`${make} ${model} `}
          </CustomTypography>
          <CustomTypography variant="h6" component="span">
            {year}
          </CustomTypography>
        </Box>
        <Box component={Grid} container justify="space-between" pt={2}>
          <StatusToggle record={record} source="status" />
          <ReservedToggle record={record} source="reserved" />
        </Box>
        {enabled && (
          <Box
            p={3}
            mt={2}
            textAlign="center"
            border={1}
            borderRadius={5}
            borderColor="common.silverSand"
          >
            <MarkedAsUnavailableDate id={record?.id} />
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default SideCard;
