import { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import SettingsIcon from '@material-ui/icons/Settings';
import { useMediaQuery, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate, DashboardMenuItem, MenuItemLink } from 'react-admin';
import * as MuiIcons from '@material-ui/icons';
import Logo from './Logo';
import SubMenu from './SubMenu';

const useMenuItemStyles = makeStyles((theme) => ({
  root: {
    color: '#FFFFFF',
    fontSize: theme.typography.fontSize,
    padding: theme.spacing(0, 3),
    height: 40,
    lineHeight: 40,
  },
  active: {
    fontWeight: 'bold',
    color: '#FFFFFF',
  },
  icon: {
    color: '#FFFFFF',
    minWidth: theme.spacing(4),
  },
}));

const Menu = ({ onMenuClick, logout, dense = false }) => {
  // const classes = useStyles();
  const [menu, setMenu] = useState({
    vehicles: false,
  });

  const menuItemClasses = useMenuItemStyles();
  const translate = useTranslate();
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const open = useSelector((globalState) => globalState.admin.ui.sidebarOpen);
  useSelector((globalState) => globalState.theme); // force rerender on theme change

  const handleToggle = (name) => {
    setMenu((menuState) => ({ ...menuState, [name]: !menuState[name] }));
  };

  return (
    <Box>
      <Box textAlign="center" lineHeight="48px" p="0 18px">
        <Logo />
      </Box>
      <Box mt={3}>
        <DashboardMenuItem
          onClick={onMenuClick}
          sidebarIsOpen={open}
          classes={menuItemClasses}
          leftIcon={<MuiIcons.HomeOutlined />}
        />

        <SubMenu
          handleToggle={() => handleToggle('vehicles')}
          isOpen={menu.vehicles}
          sidebarIsOpen={open}
          name={translate('resources.vehicles.name', { smart_count: 2 })}
          icon={<MuiIcons.DirectionsCarOutlined />}
          dense={dense}
        >
          <MenuItemLink
            to="/vehicles"
            primaryText={translate('resources.vehicles.all_vehicles', {
              smart_count: 2,
            })}
            leftIcon={null}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
            classes={menuItemClasses}
          />
          <MenuItemLink
            to="/import-vehicles"
            primaryText={translate('resources.import_vehicles.name')}
            leftIcon={null}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
            classes={menuItemClasses}
          />
        </SubMenu>
        <MenuItemLink
          to="/invoices-weekly"
          primaryText={translate('resources.invoice_weekly.menuName')}
          classes={menuItemClasses}
          leftIcon={<MuiIcons.ReceiptOutlined />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />

        {isXSmall && (
          <MenuItemLink
            to="/configuration"
            primaryText={translate('pos.configuration')}
            classes={menuItemClasses}
            leftIcon={<SettingsIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
        )}
        {isXSmall && logout}
      </Box>
    </Box>
  );
};

Menu.propTypes = {
  onMenuClick: PropTypes.func,
  logout: PropTypes.node,
  dense: PropTypes.bool,
};

export default Menu;
