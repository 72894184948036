import { Box, Button } from '@material-ui/core';
import { get } from 'lodash';
import { useTranslate } from 'react-admin';
import { useHistory } from 'react-router-dom';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { rootTranslate } from '../const';

const TitleWithBackButton = ({ record }) => {
  const t = useTranslate();
  const { goBack } = useHistory();
  const title = `${t(`${rootTranslate}.title__booking_id`)} ${get(
    record,
    'number',
    ''
  )}`;

  return (
    <Box display="flex" alignItems="center" pr={3}>
      <Button
        variant="outlined"
        color="primary"
        size="medium"
        startIcon={<ChevronLeft />}
        onClick={() => goBack()}
      >
        {t('ra.action.back')}
      </Button>
      <Box ml={2}>{title}</Box>
    </Box>
  );
};

export default TitleWithBackButton;
