import { dashboardActions } from '../actions';
import adminLocalStorage from 'store/localStorage';

const initialState = {
  tasks: {
    fromDate: new Date().toISOString(),
    toDate: new Date().toISOString(),
  },
  reminder: {
    show: false,
  },
};

const dashboardReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case dashboardActions.UPDATE_DATE: {
      return {
        ...state,
        tasks: {
          ...state.tasks,
          ...payload,
        },
      };
    }
    case dashboardActions.SHOW_REMINDER: {
      return {
        ...state,
        reminder: {
          ...state.reminder,
          show: payload,
        },
      };
    }
    case dashboardActions.REMEMBER_REMINDER: {
      const {
        reminder: { show },
      } = state;
      adminLocalStorage.set('reminder', { show });
      return state;
    }
    default:
      return state;
  }
};

export default dashboardReducer;
