import React, { useState } from 'react';
import { get } from 'lodash';
import { Grid, Card, CardContent, makeStyles, Button } from '@material-ui/core';
import { useTranslate, ImageInput, ImageField } from 'react-admin';
import ImageWrapper from 'components/ImageWrapper';

import StatusField, { StatusText } from './StatusField';
import { rootTranslate } from '../const';

const useStyles = makeStyles((theme) => ({
  thumbnail: {
    width: '100%',
    position: 'relative',
    margin: theme.spacing(0, 0, 2, 0),
    '& img': {
      display: 'block',
      margin: '0 auto',
    },
  },
  label: {
    '& span': {
      fontWeight: 'inherit',
    },
  },
  textRight: {
    textAlign: 'right',
  },
  preview: {
    width: '100%',
    height: '100%',
    '& img': {
      maxHeight: '100%',
    },
  },
  removeButton: {
    position: 'absolute',
    top: '0px',
    width: '100%',
    height: '100%',
    '& button': {
      position: 'absolute',
      right: '0px',
    },
  },
  dropZone: {
    position: 'static',
    '& p': {
      fontSize: '15px',
    },
    '& .previews': {
      position: 'absolute',
      top: '0px',
      width: '100%',
      paddingTop: '56.25%',
      background: theme.palette.grey['200'],
      display: ({ fileUpload }) => {
        return fileUpload ? 'block' : 'none';
      },
    },
  },
}));

function ProfileLeft({ record, enableUploadProfile = false, onUploadFile }) {
  const [showUploadPictureButton, setShowUploadPictureButton] = useState(false);
  const [fileUpload, setFileUpload] = useState(null);
  const classes = useStyles({ fileUpload });
  const translate = useTranslate();
  const picture = get(record, 'user.picture');
  const alt = `${get(record, 'user.first_name')} ${get(
    record,
    'user.last_name'
  )}`;

  const handleDropImage = (file) => {
    setFileUpload(file);
    setShowUploadPictureButton(true);
  };

  const handleRemoveFile = () => {
    setFileUpload(null);
    setShowUploadPictureButton(false);
  };

  const handleUploadFile = (e) => {
    e.preventDefault();
    if (fileUpload && onUploadFile) {
      onUploadFile(fileUpload);
      setShowUploadPictureButton(false);
    }
  };

  const renderImageInput = () => {
    if (!enableUploadProfile || !record) {
      return null;
    }
    const newRecord = {
      ...record,
      picture: {
        src: record.user.picture,
        title: alt,
      },
    };
    return (
      <>
        <ImageInput
          record={newRecord}
          source="picture"
          label=""
          accept="image/*"
          options={{
            onDropAccepted: handleDropImage,
            onRemove: handleRemoveFile,
          }}
          classes={{
            root: classes.dropZone,
            preview: classes.preview,
            removeButton: classes.removeButton,
          }}
        >
          <ImageField source="src" title={alt} />
        </ImageInput>
        {showUploadPictureButton && (
          <Button
            variant="contained"
            color="primary"
            type="submit"
            label={translate(`${rootTranslate}.messages__drop_zone_label`)}
            fullWidth
            onClick={handleUploadFile}
          >
            {translate(`${rootTranslate}.messages__button_upload_title`)}
          </Button>
        )}
      </>
    );
  };

  return (
    <Card>
      <CardContent>
        <div className={classes.thumbnail}>
          <ImageWrapper src={picture} alt={alt} />
          {renderImageInput()}
        </div>
        <Grid
          container
          alignContent="space-between"
          justify="space-between"
          spacing={2}
        >
          <Grid item xs={6}>
            <StatusField record={record} source="status" />
          </Grid>
          <Grid item xs={6} className={classes.textRight}>
            <StatusText record={record} source="status" />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default ProfileLeft;
