import { useTranslate } from 'react-admin';
import {
  Box,
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Grid,
  Link,
  Typography,
} from '@material-ui/core';
import { rootTranslate } from '../const';

/**
 * Show WHOLE result of vehile import process
 * @param  {[number]} options.totalImported         [Total imported raw vehicles]
 * @param  {[number]} options.totalFailed           [Total failed to import]
 * @param  {[string]} options.failedResultFileUrl   [Absolute file URL to download failed raw vehicles]
 * @param  {[boolean]} options.completeLoading      ["true" while calling `handleComplete`]
 * @param  {[function]} options.handleComplete      [Handle click on "Complete" button]
 */
const ImportResult = ({
  totalImported,
  totalFailed,
  failedResultFileUrl,
  completeLoading,
  handleComplete,
}) => {
  const translate = useTranslate();

  return (
    <Card>
      <CardHeader title={translate(`${rootTranslate}.result_title`)} />
      <Grid container component={CardContent}>
        <Grid item xs={12}>
          <Typography variant="body2" component="span">
            <Box component="span" fontWeight="bold" color="primary.main">
              {totalImported} {translate(`${rootTranslate}.imported_label`)}
            </Box>
          </Typography>
          <Typography variant="body2" component="span">
            <Box ml={4} component="span" fontWeight="bold" color="error.main">
              {totalFailed} {translate(`${rootTranslate}.failed_label`)}
            </Box>
          </Typography>
        </Grid>
      </Grid>
      <CardActions>
        <Grid container alignItems="center" justify="flex-end">
          {failedResultFileUrl && (
            <Button
              component={Link}
              variant="contained"
              color="primary"
              href={failedResultFileUrl}
            >
              {translate(`${rootTranslate}.download_failed_button_label`)}
            </Button>
          )}
          <Box ml={2}>
            <Button
              variant="contained"
              color="primary"
              disabled={completeLoading}
              onClick={handleComplete}
            >
              {translate(`${rootTranslate}.complete_button_label`)}
            </Button>
          </Box>
        </Grid>
      </CardActions>
    </Card>
  );
};

export default ImportResult;
