import { parseJson } from 'utils/format';

function adminLocalStorage() {
  const key = 'ADMIN_LOCAL_STORAGE';
  const localStorage = window.localStorage;

  const getAll = () => {
    const value = localStorage.getItem(key);
    return parseJson(value, {});
  };

  const get = (k) => {
    const { [k]: value = null } = getAll();
    return value;
  };

  const set = (k, v) => {
    const currentValues = getAll();
    localStorage.setItem(
      key,
      JSON.stringify({
        ...currentValues,
        [k]: v,
      })
    );
  };

  const remove = (k) => {
    const { [k]: excludeKey, ...others } = getAll();
    localStorage.setItem(JSON.stringify(others));
  };

  return {
    get,
    set,
    remove,
    getAll,
  };
}

export default adminLocalStorage();
