import * as React from 'react';
import cs from 'classnames';
import { cloneElement } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
  useListContext,
  TopToolbar,
  CreateButton,
  sanitizeListRestProps,
} from 'react-admin';

const useStyles = makeStyles((theme) => ({
  topToolbar: {
    flexGrow: 1,
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between',
  },
  actionButton: {
    marginLeft: theme.spacing(2),
  },
}));

const ListActions = (props) => {
  const { className, filters, ...rest } = props;
  const {
    resource,
    displayedFilters,
    filterValues,
    basePath,
    hasCreate,
    showFilter,
  } = useListContext();
  const classes = useStyles();
  return (
    <TopToolbar
      className={cs(classes.topToolbar, className)}
      {...sanitizeListRestProps(rest)}
    >
      <div>
        {hasCreate && (
          <CreateButton
            className={classes.actionButton}
            variant="contained"
            color="primary"
            size="medium"
            basePath={basePath}
          />
        )}
      </div>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
          className: classes.actionButton,
          variant: 'contained',
          color: 'primary',
          size: 'medium',
        })}
    </TopToolbar>
  );
};

ListActions.propTypes = {
  basePath: PropTypes.string,
  className: PropTypes.string,
  currentSort: PropTypes.any,
  displayedFilters: PropTypes.object,
  exporter: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  filters: PropTypes.element,
  filterValues: PropTypes.object,
  hasCreate: PropTypes.bool,
  resource: PropTypes.string,
  onUnselectItems: PropTypes.func,
  selectedIds: PropTypes.arrayOf(PropTypes.any),
  showFilter: PropTypes.func,
  total: PropTypes.number,
};

export default ListActions;
