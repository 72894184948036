import React from 'react';
import BookingListByType from '../components/BookingListByType';
import { type } from './const';

const BookingList = (props) => {
  return (
    <BookingListByType
      {...props}
      type={type}
      sort={{ field: 'return_datetime', order: 'ASC' }}
      showPickupDatetime={false}
    />
  );
};

export default BookingList;
