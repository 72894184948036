import React, { useCallback, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  email,
  maxLength,
  minLength,
  number,
  required,
  NumberInput,
  TextInput,
  useTranslate,
  useNotify,
  useMutation,
  refreshView,
} from 'react-admin';
import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  Typography,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { Form } from 'react-final-form';
import { useHistory } from 'react-router-dom';
// import LocationPicker from 'react-location-picker';
import { ReferenceInput, SelectInput } from 'components/OutlinedInputs';
import DependentReferenceInput from 'components/DependentReferenceInput';
import { paymentType, usedCars } from 'src/constants';
import { LogoutButton } from 'layout';
import PhoneInput from 'components/PhoneInput';

const requiredField = required();
const emailField = email();
const numberField = number();
const routingNumberValidation = [
  requiredField,
  numberField,
  minLength(9, 'signup.validations_routing_number'),
  maxLength(9, 'signup.validations_routing_number'),
];
const accountNumberValidation = [requiredField, numberField, maxLength(17)];

const SignupForm = (props) => {
  const translate = useTranslate();
  const { user } = useAuth0();
  const [mutate, { loading }] = useMutation();
  const notify = useNotify();
  const dispatch = useDispatch();
  const history = useHistory();
  const [showDialog, setShowDialog] = useState(true);
  const { name, email } = user || {};
  const initialValues = {
    name,
    primary_contact: {
      name,
      email,
    },
  };
  const onSubmit = useCallback(
    (values) => {
      const data = {
        type: 'create',
        resource: 'signup',
        payload: {
          data: {
            ...values,
          },
        },
      };
      const onSuccess = () => {
        notify('messages__registration_completed', 'info');
        setShowDialog(false);
        dispatch(refreshView());
        history.push('/');
      };
      const onFailure = () => {
        notify('messages__registration_error', 'warning');
      };
      const callback = {
        onSuccess,
        onFailure,
      };
      mutate(data, callback);
    },
    [setShowDialog, notify, dispatch, mutate, history]
  );
  const hideDialog = useCallback(() => {
    setShowDialog(false);
  }, [setShowDialog]);
  const formRender = ({ handleSubmit }) => {
    return (
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">{translate('signup.basic')}</Typography>
          </Grid>
          <Grid item xs={4}>
            <TextInput
              fullWidth
              source="name"
              variant="outlined"
              validate={requiredField}
              label={translate('signup.name')}
              autoFocus
            />
          </Grid>
          <Grid item xs={8}>
            <TextInput
              fullWidth
              source="address"
              variant="outlined"
              validate={requiredField}
              label={translate('signup.address')}
            />
          </Grid>
          <Grid item xs={4}>
            <NumberInput
              fullWidth
              source="coordinate.lat"
              variant="outlined"
              validate={requiredField}
              label={translate('signup.lat')}
            />
          </Grid>
          <Grid item xs={4}>
            <NumberInput
              fullWidth
              source="coordinate.lng"
              variant="outlined"
              validate={requiredField}
              label={translate('signup.long')}
            />
          </Grid>
          <Grid item xs={4}>
            <TextInput
              fullWidth
              source="postal_code"
              variant="outlined"
              validate={requiredField}
              label={translate('signup.postal_code')}
            />
          </Grid>
          <Grid item xs={4}>
            <SelectInput
              fullWidth
              source="number_of_used_vehicles"
              choices={usedCars.map((u) => ({
                id: u,
                name: u,
              }))}
              variant="outlined"
              validate={requiredField}
              label={translate('signup.number_of_used_vehicles')}
            />
          </Grid>
          <Grid item xs={8}></Grid>
          <Grid item xs={4}>
            <ReferenceInput
              source="location.country_id"
              reference="locale/countries"
              label="country"
              filterToQuery={() => {}}
              sort={{
                field: 'name',
                order: 'ASC',
              }}
              fullWidth
              validate={requiredField}
            >
              <SelectInput />
            </ReferenceInput>
          </Grid>
          <Grid item xs={4}>
            <DependentReferenceInput
              dependencySource="location.country_id"
              source="location.state_id"
              reference="locale/states"
              dependencyToFilter={(dependentValue) => ({
                country_id: dependentValue,
              })}
              dependencyToDisabled={(dependentValue) => !dependentValue}
              label={translate('signup.state')}
              filterToQuery={() => {}}
              sort={{
                field: 'name',
                order: 'ASC',
              }}
              fullWidth
              validate={requiredField}
            >
              <SelectInput />
            </DependentReferenceInput>
          </Grid>
          <Grid item xs={4}>
            <DependentReferenceInput
              dependencySource="location.state_id"
              source="location.city_id"
              reference="locale/cities"
              label={translate('signup.city')}
              filterToQuery={() => {}}
              dependencyToFilter={(dependencyValue) => ({
                state_id: dependencyValue,
              })}
              dependencyToDisabled={(dependentValue) => !dependentValue}
              sort={{
                field: 'name',
                order: 'ASC',
              }}
              fullWidth
              validate={requiredField}
            >
              <SelectInput />
            </DependentReferenceInput>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">{translate('signup.contact')}</Typography>
          </Grid>
          <Grid item xs={4}>
            <TextInput
              fullWidth
              source="primary_contact.name"
              variant="outlined"
              validate={requiredField}
              label={translate('signup.name')}
            />
          </Grid>
          <Grid item xs={4}>
            <PhoneInput
              fullWidth
              source="primary_contact.phone"
              phoneCodeSource="primary_contact.country_code"
              variant="outlined"
              validate={requiredField}
              label={translate('signup.phone')}
            />
          </Grid>
          <Grid item xs={4}>
            <TextInput
              type="email"
              fullWidth
              source="primary_contact.email"
              variant="outlined"
              validate={[requiredField, emailField]}
              label={translate('signup.email')}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">{translate('signup.payment')}</Typography>
          </Grid>
          <Grid item xs={4}>
            <TextInput
              fullWidth
              source="payment_bank"
              variant="outlined"
              validate={requiredField}
              label={translate('signup.payment_bank')}
            />
          </Grid>
          <Grid item xs={4}>
            <TextInput
              fullWidth
              source="payment_account_name"
              variant="outlined"
              validate={requiredField}
              label={translate('signup.payment_account_name')}
            />
          </Grid>
          <Grid item xs={4} />
          <Grid item xs={4}>
            <TextInput
              fullWidth
              source="payment_routing_number"
              variant="outlined"
              validate={routingNumberValidation}
              label={translate('signup.payment_routing_number')}
            />
          </Grid>
          <Grid item xs={4}>
            <SelectInput
              fullWidth
              source="payment_account_type"
              defaultValue={Object.values(paymentType)[0].value}
              choices={Object.values(paymentType).map((p) => ({
                id: p.value,
                name: translate(p.text),
              }))}
              variant="outlined"
              validate={requiredField}
              label={translate('signup.payment_account_type')}
            />
          </Grid>
          <Grid item xs={4}>
            <TextInput
              fullWidth
              source="payment_account_number"
              variant="outlined"
              validate={accountNumberValidation}
              label={translate('signup.payment_account_number')}
            />
          </Grid>
        </Grid>

        <Grid container justify="space-between">
          <LogoutButton />
          <Button
            disabled={loading}
            variant="contained"
            color="primary"
            type="submit"
          >
            {translate('ra.action.save')}
          </Button>
        </Grid>
      </form>
    );
  };

  return (
    <Dialog
      maxWidth="md"
      open={showDialog}
      onClose={hideDialog}
      aria-labelledby="finish-registration"
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
    >
      <DialogContent>
        <Form
          initialValues={initialValues}
          onSubmit={onSubmit}
          render={formRender}
        />
      </DialogContent>
    </Dialog>
  );
};

export default SignupForm;
