import React from 'react';
import { useTranslate } from 'react-admin';
import { get } from 'lodash';
import { Typography } from '@material-ui/core';
import { rootTranslate } from '../const';

function ServicesField({ record, source, activeText = '' }) {
  const t = useTranslate();
  const active = get(record, source, false);
  const text = active
    ? activeText
    : t(`${rootTranslate}.constants__service_unactive`);
  return <Typography variant="body2">{text}</Typography>;
}

export default ServicesField;
