import { format } from 'date-fns-tz';
import {
  addDays,
  format as dFormat,
  startOfWeek,
  isValid,
  parse,
} from 'date-fns';
import { stringify, parse as qsParse } from 'qs';
import {
  FOR_BACKEND_DATE_TIME_FORMAT,
  FORMAT_DATE_TIME,
  FOR_BACKEND_DATE_ONLY_FORMAT,
  FOR_BACKEND_TIME_ONLY_FORMAT,
  FORMAT_TIME,
  DISPLAYED_DATE_FORMAT,
  DISPLAYED_DATE_FORMAT_2,
  DISPLAYED_DATE_FORMAT_3,
} from 'src/constants';

/**
 * Format a datetime string to desired format without considering the timezone information
 * @param {*} props
 * @param {string} props.datetime
 * @param {string} props.dateFormat The format of the output string
 * @return {string} The formatted date time string
 */
export const formatLocalDate = ({ datetime, dateFormat }) => {
  const [dateTime] = datetime.split(/[+-]\d+:\d+/);
  const result = format(new Date(dateTime), dateFormat);
  return result;
};

/**
 * Get localized weekday
 * @param {number} number
 * @returns {string} the weekday label like 'Sunday', 'Tuesday'
 */
export const getLocaledWeekDay = (number) => {
  const firstDOW = startOfWeek(new Date());
  const weekDaysArray = Array.from(Array(7)).map((e, i) =>
    dFormat(addDays(firstDOW, i), 'EEEE')
  );
  /**
   * weekDaysArray = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
  */

  return weekDaysArray[number];
};

/**
 * format currency https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat#using_locales
 * @param {number} number
 * @param {string} locales
 * @param {string} currency
 */
export function formatCurrency(number, locales = 'en-US', currency = 'USD') {
  if (Number.isNaN(Number(number)) || number === null) {
    return '';
  }

  const formatter = new Intl.NumberFormat(locales, {
    style: 'currency',
    currency,
  });

  return formatter.format(number);
}

/**
 * If number > 10000 will return short number EX: 10000 = 10k , 10000000 = 10M
 * format number https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat#using_locales
 * @param {number} number
 * @param {string} locales
 * @param {string} currency
 */
export function formatShortCurrencyWithOutTrailingZero(
  number,
  locales = 'en-US',
  currency = 'USD'
) {
  if (Number.isNaN(Number(number)) || number === null) {
    return '';
  }
  const config =
    number >= 10000
      ? {
          style: 'currency',
          notation: 'compact',
          compactDisplay: 'short',
          minimumFractionDigits: 0,
          currency,
        }
      : {
          style: 'currency',
          minimumFractionDigits: 0,
          currency,
        };

  const formatter = new Intl.NumberFormat(locales, config);

  return formatter.format(number);
}

export function removeTimeZonePart(dateString) {
  const [dateTime] = (dateString?.split &&
    dateString?.split(/[+-]\d+:\d+/)) || [dateString];

  return dateTime;
}

export const buildUrlFromParams = (basePath, params = {}) => {
  return `${basePath}?${stringify(params)}`;
};

export const parseQuery = (queryString = '') => {
  return qsParse(queryString);
};

export const parseQueryFromLocation = ({ search }) => {
  const query = parseQuery(search.replace('?', ''));
  return query;
};

/**
 * CustomDateTime {
 *  date:String yyyy-MM-dd,
 *  time:String HH:mm
 * }
 * Convert Date Time from Date object or CustomDateTime
 * @param {*} dateTime {CustomDateTime | Date | String}
 */
export function formatDateTime(dateTime) {
  let value = null;
  if (dateTime instanceof Date) {
    value = dateTime;
  } else if (dateTime instanceof Object && dateTime?.date && dateTime?.time) {
    value = parse(
      `${dateTime?.date} ${dateTime?.time}`,
      FOR_BACKEND_DATE_TIME_FORMAT,
      new Date()
    );
  } else {
    const patt1 = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/; // yyyy-MM-dd
    const patt2 = /^[0-9]{4}-[0-9]{2}-[0-9]{2}\s[0-9]{2}:[0-9]{2}$/; // yyyy-MM-dd HH:mm

    if (patt1.test(dateTime)) {
      value = parse(dateTime, FOR_BACKEND_DATE_ONLY_FORMAT, new Date());
    } else if (patt2.test(dateTime)) {
      value = parse(dateTime, FOR_BACKEND_DATE_TIME_FORMAT, new Date());
    } else {
      value = dateTime ? new Date(removeTimeZonePart(dateTime)) : null;
    }
  }

  const f = (returnFormat) =>
    isValid(value) ? dFormat(value, returnFormat) : null;

  return {
    dateTime: value,
    getDisplayDateTime: () => f(FORMAT_DATE_TIME),
    getDisplayDate: () => f(DISPLAYED_DATE_FORMAT),
    getDisplayDate2: () => f(DISPLAYED_DATE_FORMAT_2),
    getDisplayDate3: () => f(DISPLAYED_DATE_FORMAT_3),
    getDisplayTime: () => f(FORMAT_TIME),
    getReturnDateTime: () => f(FOR_BACKEND_DATE_TIME_FORMAT),
    getReturnDate: () => f(FOR_BACKEND_DATE_ONLY_FORMAT),
    getReturnTime: () => f(FOR_BACKEND_TIME_ONLY_FORMAT),
  };
}

/**
 * convert json to object if false return defaultValue else return value after parse
 * @param {String} str
 * @param {Any} defaultValue
 */
export function parseJson(str, defaultValue = {}) {
  try {
    if (!str) {
      return defaultValue;
    }

    return JSON.parse(str);
  } catch (e) {
    return defaultValue;
  }
}
