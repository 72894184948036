import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import cs from 'classnames';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: 'inline-flex',
      alignItems: 'center',
    },
    circle: {
      display: 'block',
      width: '8px',
      height: '8px',
      borderRadius: '100%',
    },
    unavailable: {
      backgroundColor: theme.palette.common.mustard,
    },
    booked: {
      backgroundColor: theme.palette.common.pink,
    },
    label: {
      fontSize: theme.typography.pxToRem(14),
      marginLeft: theme.spacing(1),
    },
  };
});

export const types = {
  UNAVAILABLE: 'unavailable',
  BOOKED: 'booked',
};

function Indicator({ type = types.UNAVAILABLE, label = '' }) {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Box
        className={cs(classes.circle, {
          [classes.unavailable]: type === types.UNAVAILABLE,
          [classes.booked]: type === types.BOOKED,
        })}
      />
      {label && <Typography className={classes.label}>{label}</Typography>}
    </Box>
  );
}

export default Indicator;
