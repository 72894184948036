import React from 'react';
import { Datagrid, useListContext, Loading } from 'react-admin';

const DataGridWithLoading = ({ children, ...props }) => {
  const { loading } = useListContext(props);

  if (loading) {
    return <Loading />;
  }

  return <Datagrid {...props}>{children}</Datagrid>;
};

export default DataGridWithLoading;
