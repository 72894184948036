import React from 'react';
import { Grid, Box } from '@material-ui/core';
import Tasks from './components/Tasks';
import Alerts from './components/Alerts';
import OngoingEvent from './components/OngoingEvent';
import Inventory from './components/Inventory';
import Earnings from './components/Earnings';
import Welcome from './components/Welcome';
import Reminder from './components/Reminder';

const Dashboard = () => {
  return (
    <Box>
      <Box mb={2.5}>
        <Welcome />
      </Box>
      <Box mb={2.5}>
        <Reminder />
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box display="flex" height="100%" flexDirection="column">
            <Tasks />
            <Box pb={2} />
            <Box flex={1}>
              <OngoingEvent />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Alerts />
          <Box pb={2} />
          <Inventory />
        </Grid>
        <Grid item xs={12}>
          <Earnings />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
