import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { version } from '../package.json';
import 'fontsource-open-sans';

if (process.env.REACT_APP_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_DSN,
    environment: process.env.REACT_APP_ENV,
    release: `${process.env.REACT_APP_SENTRY_PROJECT}@${version}`,
    integrations: [new Integrations.BrowserTracing()],
    // // We recommend adjusting this value in production, or using tracesSampler
    // // for finer control
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
    redirectUri={window.location.origin}
    audience="dealer_api"
  >
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Auth0Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
