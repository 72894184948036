import { useEffect, useState } from 'react';
import { CheckboxGroupInput, useMutation } from 'react-admin';
import { Grid } from '@material-ui/core';
import { VEHICLE_ACTION } from '../../const';

const OperatingServicesForm = (props) => {
  const { record, changeAvailableServices, changeAvailableHubs } = props;
  const [fullDataAvailbleHubs, setFullDataAvailableHubs] = useState([]);
  const [availableServiceTypes, setAvailbleServiceTypes] = useState(
    record ? record.available_delivery_types : []
  );
  const [mutate, { loading }] = useMutation();

  useEffect(() => {
    if (availableServiceTypes?.length > 0) {
      mutate(
        {
          type: 'others',
          resource: 'vehicles',
          payload: {
            action: VEHICLE_ACTION.GET_AVAILABLE_HUBS,
            id: record.id,
            data: availableServiceTypes.reduce((value, type) => {
              value = `${
                value ? value + '&' : ''
              }available_delivery_types[]=${type}`;
              return value;
            }, ''),
          },
        },
        {
          onSuccess: (res) => {
            setFullDataAvailableHubs(res.data);
            changeAvailableServices(res.data);
          },
        }
      );
    } else {
      setFullDataAvailableHubs([]);
      changeAvailableServices([]);
    }
  }, [availableServiceTypes, mutate, record.id]);

  const deliveryTypes = [
    {
      id: 'vehicle_delivery',
      name: 'Hotel Delivery',
    },
    {
      id: 'hub',
      name: 'Airport Pickup',
    },
  ];
  let availableHubs = [];

  if (fullDataAvailbleHubs) {
    availableHubs = fullDataAvailbleHubs.map((hub) => {
      return {
        id: hub.id,
        name: hub.name,
      };
    });
  }

  return (
    <Grid container spacing={4}>
      <Grid
        item
        xs={6}
        md={6}
        source="available_services_types"
        style={{ padding: '0 1rem' }}
      >
        <CheckboxGroupInput
          label="Available service types"
          source="available_delivery_types"
          choices={deliveryTypes}
          row={false}
          style={{ paddingTop: '1rem' }}
          defaultChecked={
            record?.available_delivery_types
              ? record.available_delivery_types
              : []
          }
          onChange={(types) => {
            setAvailbleServiceTypes(types);
          }}
        />
      </Grid>
      {!loading && availableHubs.length > 0 && (
        <Grid
          item
          xs={6}
          md={6}
          source="available_hubs"
          style={{ padding: '0 1rem' }}
        >
          <CheckboxGroupInput
            label="Available hubs"
            source="available_hubs"
            choices={availableHubs}
            row={false}
            style={{ paddingTop: '1rem' }}
            defaultChecked={record?.available_hubs ? record.available_hubs : []}
            onChange={(hubs) => {
              changeAvailableHubs(hubs);
            }}
          />
        </Grid>
      )}
    </Grid>
  );
};

const VehicalEditService = (props) => {
  return <OperatingServicesForm {...props} />;
};

export default VehicalEditService;
