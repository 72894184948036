import React from 'react';
import { Datagrid, TextField } from 'react-admin';
import { CustomList } from 'layout';
import ActionButtons from 'components/ActionButtons';
import VehicleMakeModelYearField from 'components/VehicleMakeModelYearField';
import VehicleStatusField from './components/VehicleStatusField';
import ListFilters from './components/ListFilters';

const VehicleList = (props) => {
  return (
    <CustomList
      {...props}
      filters={<ListFilters />}
      sort={{ field: 'default', order: 'DESC' }}
    >
      <Datagrid>
        <TextField source="vin" />
        <VehicleMakeModelYearField
          source="vehicle_make.name"
          sortable={false}
          label="resources.vehicles.make_model_year"
        />
        <TextField source="plate_number" sortBy="plate_number" emptyText="--" />
        <TextField source="stock_number" sortBy="stock_number" emptyText="--" />
        <TextField source="allocation_type.name" />
        <VehicleStatusField source="status" />
        <ActionButtons showEdit showDetail showDelete={false}></ActionButtons>
      </Datagrid>
    </CustomList>
  );
};

export default VehicleList;
