import * as React from 'react';
import { Admin, Resource } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import { useAuth0 } from '@auth0/auth0-react';

import authProvider from 'providers/authProvider';
import { Layout, UnAuthenticated, LogoutButton } from 'layout';
import { Dashboard, dashboardProvider } from 'features/dashboard';
import englishMessages from 'i18n/en';

import vehicle from 'features/vehicle';
import vehicleListed from 'features/vehicle/VehicleListed';
import vehicleUnListed from 'features/vehicle/VehicleUnListed';
import vehicleBooked from 'features/vehicle/VehicleBooked';
import vehicleReserved from 'features/vehicle/VehicleReserved';
import vehicleInTrip from 'features/vehicle/VehicleInTrip';
import vehicleDelay from 'features/vehicle/VehicleDelay';
import invoiceWeekly from 'features/invoiceWeekly';
import invoice from 'features/invoice';
import importVehicle from 'features/importVehicle';
import dataProviderInstance from 'providers/myDataProvider';
import profile from 'features/profile';
import bookingArrival from 'features/booking/BookingArrival';
import bookingReturning from 'features/booking/BookingReturning';
import bookingNoShow from 'features/booking/BookingNoShow';
import bookingMaintenance from 'features/booking/BookingMaintenance';
import customRoutes from './routes';
import LoadingMessage from 'components/LoadingMessage';
import customReducers from 'store/reducers';

const i18nProvider = polyglotI18nProvider(() => englishMessages, 'en');

const App = () => {
  const {
    isLoading,
    isAuthenticated,
    logout,
    loginWithRedirect,
    getAccessTokenSilently,
    // error, // TODO render error or integrate Sentry later
    user,
  } = useAuth0();
  const customAuthProvider = authProvider({
    isLoading,
    isAuthenticated,
    loginWithRedirect,
    logout,
    user,
    getAccessTokenSilently,
  });

  const dataProvider = dataProviderInstance({
    getAccessTokenSilently,
    extendedProviders: {
      profile: profile.dataProvider,
      'import-vehicles': importVehicle.dataProvider,
      'invoices-weekly': invoiceWeekly.dataProvider,
      invoices: invoice.dataProvider,
      vehicles: vehicle.dataProvider,
      'vehicle-listed': vehicleListed.dataProvider,
      'vehicle-unlisted': vehicleUnListed.dataProvider,
      'vehicle-booked': vehicleBooked.dataProvider,
      'vehicle-reserved': vehicleReserved.dataProvider,
      'vehicle-in-trip': vehicleInTrip.dataProvider,
      'vehicle-delay': vehicleDelay.dataProvider,
      'bookings-arrival': bookingArrival.dataProvider,
      'bookings-returning': bookingReturning.dataProvider,
      'bookings-no-show': bookingNoShow.dataProvider,
      'bookings-maintenance': bookingMaintenance.dataProvider,
      dashboard: dashboardProvider,
    },
  });

  if (!dataProviderInstance) {
    return <LoadingMessage iMessage="messages__homepage_loading_resources" />;
  }

  return (
    <Admin
      disableTelemetry
      title=""
      dataProvider={dataProvider}
      authProvider={customAuthProvider}
      dashboard={Dashboard}
      layout={Layout}
      i18nProvider={i18nProvider}
      loginPage={UnAuthenticated}
      logoutButton={LogoutButton}
      customRoutes={customRoutes}
      customReducers={customReducers}
    >
      <Resource name="vehicles" {...vehicle} />
      <Resource name="vehicle-listed" {...vehicleListed} />
      <Resource name="vehicle-unlisted" {...vehicleUnListed} />
      <Resource name="vehicle-booked" {...vehicleBooked} />
      <Resource name="vehicle-reserved" {...vehicleReserved} />
      <Resource name="vehicle-in-trip" {...vehicleInTrip} />
      <Resource name="vehicle-delay" {...vehicleDelay} />
      <Resource name="invoices-weekly" {...invoiceWeekly} />
      <Resource name="invoices" {...invoice} />
      <Resource name="import-vehicles" {...importVehicle} />
      <Resource name="profile" {...profile} />
      <Resource name="bookings-arrival" {...bookingArrival} />
      <Resource name="bookings-returning" {...bookingReturning} />
      <Resource name="bookings-no-show" {...bookingNoShow} />
      <Resource name="bookings-maintenance" {...bookingMaintenance} />

      <Resource name="features" />
      <Resource name="vehicle-categories" />
      <Resource name="vehicle-makes" />
      <Resource name="vehicle-models" />

      <Resource name="locale/countries" />
      <Resource name="locale/states" />
      <Resource name="locale/cities" />
      <Resource name="users/get-by-ids" />
      <Resource name="vehicles-compact-info" />
      <Resource name="vehicle-categories" />
      <Resource name="assignments/driver/assign" />
      <Resource name="manifests/status" />
      <Resource name="users/role/driver" />
      <Resource name="extras-rates" />
      <Resource name="insurance-packages-rates" />
      <Resource name="states-have-locations" />
      <Resource name="allocation-types" />
      <Resource name="dashboard" />
      <Resource name="booking-extended-status" />
    </Admin>
  );
};

export default App;
