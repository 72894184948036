import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

import BillingPeriodList from './BillingPeriodList';
import dataProvider from './dataProvider';

export default {
  list: BillingPeriodList,
  icon: MonetizationOnIcon,
  dataProvider,
};
