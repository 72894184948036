import React, { useState } from 'react';
import { get } from 'lodash';
import { Box } from '@material-ui/core';
import { Create, useRefresh, useMutation, useNotify } from 'react-admin';
import CircularProgress from '@material-ui/core/CircularProgress';
import VehicleForm from './components/VehicleForm';
import { VEHICLE_ACTION } from './const';

const VehicleCreateForm = (props) => {
  const { record: initRecord, ...otherProps } = props;
  const [mutation, { loading }] = useMutation();
  const notify = useNotify();
  const [record, setRecord] = useState(null);
  const refresh = useRefresh();
  let timer = 0;
  const handleVINChange = (event) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      if (event.target.value) {
        mutation(
          {
            resource: 'vehicles',
            type: 'others',
            payload: {
              id: event.target.value,
              action: VEHICLE_ACTION.GET_VEHICLE_BY_VIN,
            },
          },
          {
            onSuccess: (res) => {
              const { data } = res;
              setRecord(data);
              refresh();
            },
            onFailure: (err) => {
              const errorMessage = get(
                err,
                'body.messages.0.message',
                'ra.page.error'
              );
              notify(errorMessage, 'error');
            },
          }
        );
      }
    }, 500);
  };

  return (
    <Box position="relative">
      {loading && (
        <Box
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
          left={0}
          top={0}
          width="100%"
          height="100%"
          zIndex={2}
        >
          <CircularProgress />
        </Box>
      )}
      <VehicleForm
        {...otherProps}
        view="create"
        record={record || initRecord}
        onVINChange={handleVINChange}
      />
    </Box>
  );
};

const VehicleCreate = (props) => (
  <Create {...props} redirect="list">
    <VehicleCreateForm />
  </Create>
);

export default VehicleCreate;
