import React from 'react';
import { TextField } from 'react-admin';
import { CustomList } from 'layout';
import ActionButtons from 'components/ActionButtons';
import VehicleMakeModelYearField from 'components/VehicleMakeModelYearField';
import DataGridWithLoading from 'components/DataGridWithLoading';
import VehicleType from './VehicleType';
import VehicleListTitle from './VehicleListTitle';
import ListFilters from './ListFilters';
import { rootTranslate } from '../const';

const VehicleListFilterByType = ({ type, ...props }) => {
  return (
    <CustomList
      {...props}
      actions={null}
      filters={
        <ListFilters showReservedFilter={false} showStatusFilter={false} />
      }
      sort={{ field: 'id', order: 'DESC' }}
      title={<VehicleListTitle type={type} />}
    >
      <DataGridWithLoading>
        <TextField source="vin" label={`${rootTranslate}.fields.vin`} />
        <VehicleMakeModelYearField
          source="vehicle_make.name"
          sortable={false}
          label={`${rootTranslate}.make_model_year`}
        />
        <TextField
          source="plate_number"
          sortBy="plate_number"
          label={`${rootTranslate}.fields.plate_number`}
        />
        <TextField
          source="stock_number"
          emptyText="--"
          label={`${rootTranslate}.fields.stock_number`}
          sortable={false}
        />
        <TextField
          source="allocation_type.name"
          label={`${rootTranslate}.fields.allocation_type.name`}
        />
        <VehicleType source="status" type={type} />
        <ActionButtons showEdit showDetail showDelete={false}></ActionButtons>
      </DataGridWithLoading>
    </CustomList>
  );
};

export default VehicleListFilterByType;
