import React from 'react';
import { get } from 'lodash';
import { useTranslate, useQuery } from 'react-admin';
import { Box, Grid } from '@material-ui/core';
import { buildUrlFromParams } from 'utils/format';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import BuildOutlinedIcon from '@material-ui/icons/BuildOutlined';
import { CardHeader, CardContent, Card } from './Card';
import TaskItem from './TaskItem';
import { rootTranslate, actions } from '../const';

function Alerts() {
  const t = useTranslate();
  const { data = {} } = useQuery({
    type: 'others',
    resource: 'dashboard',
    payload: { action: actions.API_GET_ALERTS },
  });
  const toDay = new Date();
  const noShow = get(data, 'no-show', 0);
  const maintenance = get(data, 'maintenance', 0);

  return (
    <Card>
      <CardHeader title={t(`${rootTranslate}.heading__alerts`)} />
      <CardContent>
        <Box
          pt={{
            xs: 2,
            md: 3,
          }}
        />
        <Grid container justify="space-between" spacing={3}>
          <Grid item xs={12} xl={6}>
            <TaskItem
              icon={<VisibilityOffOutlinedIcon fontSize="inherit" />}
              number={noShow}
              label={t(`${rootTranslate}.heading__no_show`)}
              iconBgColor="common.statusRed"
              seeAllUrl={buildUrlFromParams('/bookings-no-show', {
                filter: JSON.stringify({
                  fromDate: toDay.toISOString(),
                }),
              })}
            />
          </Grid>
          <Grid item xs={12} xl={6}>
            <TaskItem
              icon={<BuildOutlinedIcon fontSize="inherit" />}
              number={maintenance}
              label={t(`${rootTranslate}.heading__maintenance`)}
              iconBgColor="common.statusRed"
              seeAllUrl="/bookings-maintenance"
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default Alerts;
