import React from 'react';
import {
  Box,
  Link as MuiLink,
  createStyles,
  withStyles,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useTranslate } from 'react-admin';
import { rootTranslate } from '../const';

const taskItemStyles = (theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    icon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexBasis: '45px',
      height: '45px',
      marginRight: theme.spacing(2),
      fontSize: theme.typography.pxToRem(33),
    },
    label: {
      fontSize: theme.typography.pxToRem(13),
      fontWeight: 'bold',
      color: theme.palette.primary.main,
    },
    link: {
      fontSize: theme.typography.pxToRem(11),
      fontWeight: 'normal',
      color: theme.palette.common.mapBlue,
      marginLeft: theme.spacing(0.8),
      cursor: 'pointer',
    },
    number: {
      fontSize: theme.typography.pxToRem(18),
      color: theme.palette.common.black,
      marginTop: theme.spacing(0.5),
    },
    [theme.breakpoints.up('md')]: {
      icon: {
        flexBasis: '58px',
        height: '58px',
        fontSize: theme.typography.pxToRem(40),
      },
      label: {
        fontSize: theme.typography.pxToRem(18),
      },
      link: {
        fontSize: theme.typography.pxToRem(14),
      },
      number: {
        fontSize: theme.typography.pxToRem(24),
      },
    },
  });

function TaskItem({
  icon,
  label,
  number,
  classes,
  iconBgColor = 'common.brandPurple',
  iconColor = 'common.white',
  seeAllUrl = '',
}) {
  const t = useTranslate();
  const n = !number && number !== 0 ? '--' : number;
  const hideSeeAll = !number;

  return (
    <Box className={classes.root}>
      <Box className={classes.icon} bgcolor={iconBgColor} color={iconColor}>
        {icon}
      </Box>
      <Box className={classes.label}>
        {label}
        {!hideSeeAll && (
          <Link
            component={MuiLink}
            to={seeAllUrl}
            underline="always"
            className={classes.link}
          >
            {t(`${rootTranslate}.label__see_all`)}
          </Link>
        )}
        <Box className={classes.number}>{n}</Box>
      </Box>
    </Box>
  );
}

export default withStyles(taskItemStyles)(TaskItem);
