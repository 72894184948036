import { useEffect, useRef, useState } from 'react';
import {
  Confirm,
  Datagrid,
  TextField,
  Title,
  useNotify,
  useTranslate,
  useUnselectAll,
  List,
  useListContext,
  Pagination,
} from 'react-admin';
import { get } from 'lodash';
import { Box, Paper, makeStyles } from '@material-ui/core';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { rootTranslate } from '../const';
import ImportResult from '../components/ImportResult';
import UploadFormCard from '../components/UploadFormCard';
import UploadFailedResult from '../components/UploadFailedResult';
import BulkImportActionButtons from '../components/BulkImportActionButtons';
import ImportStatus from '../components/ImportStatus';
import useGetJob from '../hooks/useGetJob';
import useUpload from '../hooks/useUpload';
import useImport from '../hooks/useImport';
import useCancelJob from '../hooks/useCancelJob';
import useCompleteJob from '../hooks/useCompleteJob';
import PlateNumberInput from '../components/PlateNumberInput';
import MakeModelYearField from '../components/MakeModelYearField';

const STATUS_IN_PROCESS = 'in-process';
const STATUS_READY_TO_IMPORT = 'ready-to-import';
const STATUS_IMPORTING = 'importing';
const STATUS_IMPORTED = 'imported';
const STATUS_UPLOAD_FAILED = 'failed';

const useListStyle = makeStyles((theme) => ({
  root: {
    background: '#fff',
    borderRadius: theme.shape.borderRadius,
  },
  content: {
    padding: 0,
    border: 'none',
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: 0,
    '& > div:first-child, & > table, & > span': {
      width: '100%',
    },
    '& > div:first-child': {
      order: 3,
      padding: 0,
      height: 'auto',
      background: 'none',
      '& > div:first-child': {
        display: 'none',
      },
      '& > div:last-child': {
        width: '100%',
      },
    },
  },
}));

/**
 * Flow should be (based on `jobStatus`):
 * + 404:
 *   -> No csv file are process OR after import successfully
 *   -> show upload button
 * + `STATUS_IN_PROCESS`:
 *   -> parsing csv to get raw vehicles
 *   -> show loading message
 * + `STATUS_READY_TO_IMPORT`:
 *   -> raw vehicles are ready for selection
 *   -> show list of selectable raw vehicles + import button
 * + `STATUS_IMPORTING`:
 *   -> selected raw vehicles waiting for import
 *   -> show loading message
 * + `STATUS_IMPORTED`:
 *   -> Some or all raw vehicles are imported successfully
 *   -> Show result (total failed, total imported)
 */

const CustomDataGrid = (props) => {
  const { formData = [], data = {}, ...otherProps } = props;
  const newData = { ...data };
  const { selectedIds } = useListContext(props);

  // update data to field when request fail
  formData.forEach((item) => {
    if (!!data[item.id]) {
      newData[item.id].plate_number = item.plate_number;
      newData[item.id].stock_number = item.stock_number;
    }
  });

  return <Datagrid {...otherProps} data={newData} selectedIds={selectedIds} />;
};

const RawVehicleList = (props) => {
  const listStyle = useListStyle();
  const translate = useTranslate();
  const [confirmCancelOpen, setConfirmCancelOpen] = useState(false);
  const [isWaitingPolling, setIsWaitingPolling] = useState(true);
  const [isUploadSuccess, setIsUploadSuccess] = useState(false);
  const [isImportSuccess, setIsImportSuccess] = useState(false);
  const [isCancelSuccess, setIsCancelSuccess] = useState(false);
  const [isCompleteSuccess, setIsCompleteSuccess] = useState(false);
  const [formData, setFormData] = useState([]);
  const [hasError, setHasError] = useState(false);

  const [loadingMessage, setLoadingMessage] = useState(
    translate(`${rootTranslate}.messages__initial_loading`)
  );
  const unselectAll = useUnselectAll();
  const pollingIntervalRef = useRef();
  const notify = useNotify();
  /**
   * We use useMutation instead of `useQueryWithStore`/`useQuery` because
   * we need to call `getJobData` in some specific time:
   * - On page loaded
   * - On user click event on "Re-check" button
   */
  const [getJobData, { data: jobData }] = useGetJob();
  const [uploadFile, { error: uploadError }] = useUpload();
  const [importRawVehicles, { loading: importLoading }] = useImport();
  const [cancelJob, { loading: cancelLoading }] = useCancelJob({
    onSuccess: () => {
      setConfirmCancelOpen(false);
      setIsCancelSuccess(true);
      setIsUploadSuccess(false);
    },
  });
  const [completeJob, { loading: completeLoading }] = useCompleteJob({
    onSuccess: () => {
      setIsUploadSuccess(false);
      setIsImportSuccess(false);
      setIsCompleteSuccess(true);
    },
  });

  const {
    status: jobStatus,
    failed_reason: failedReason = '',
    homenet_vehicle_ids: rawVehicleIds,
    total_imported: totalImported = 0,
    total_failed: totalFailed = 0,
    imported_failed_file_path: failedResultFileUrl,
  } = jobData || {};
  const total = rawVehicleIds ? rawVehicleIds.length : 0;
  const isUploadable = !jobStatus || !!uploadError;
  const isReadyToImport = jobStatus === STATUS_READY_TO_IMPORT;
  const isImported = jobStatus === STATUS_IMPORTED;
  const isUploadFailed = jobStatus === STATUS_UPLOAD_FAILED;

  /**
   * START OF ALL SIDE EFFECTS
   */

  /*
   * Check `jobStatus` on page load
   */
  useEffect(() => {
    getJobData();
  }, [getJobData]);

  /**
   * Long polling request until `jobStatus` changed,
   * `setInterval` AFTER `jobStatus` become 1 of 2 statuses:
   * - `STATUS_IN_PROCESS`
   * - `STATUS_IMPORTING`
   */
  useEffect(() => {
    const isInProcess = jobStatus === STATUS_IN_PROCESS;
    const isImporting = jobStatus === STATUS_IMPORTING;
    const isWaiting = isInProcess || isImporting;

    if (isWaiting) {
      setIsWaitingPolling(true);
      if (isInProcess) {
        setLoadingMessage(
          `${jobData?.file_name} ${translate(
            `${rootTranslate}.messages_import_processing`
          )}`
        );
      }
      const interval = setInterval(() => {
        getJobData();
      }, 3000);

      pollingIntervalRef.current = interval;
    }

    return () => {
      clearInterval(pollingIntervalRef.current);
    };
  }, [jobStatus, getJobData]);

  /**
   * `clearInterval` + hide loading AFTER `jobStatus` become 1 of 3 statuses:
   * - No job status (completed WHOLE process/initial)
   * - `STATUS_READY_TO_IMPORT` => show list raw vehicles
   * - `STATUS_IMPORTED` => show final result for download fail/completion
   */
  useEffect(() => {
    const isNoJob = !jobStatus;
    const isReadyToImport = jobStatus === STATUS_READY_TO_IMPORT;
    const isImported = jobStatus === STATUS_IMPORTED;
    const isUploadFailed = jobStatus === STATUS_UPLOAD_FAILED;

    if (isNoJob || isReadyToImport || isImported || isUploadFailed) {
      setIsWaitingPolling(false);
      setIsCancelSuccess(false);
      setIsCompleteSuccess(false);
      clearInterval(pollingIntervalRef.current);
    }
  }, [jobStatus]);

  /**
   * Ley say "UA" is "upload action"
   * It's action when user upload csv file/click on upload input
   * (at initial state)
   *
   * Check `jobStatus` after "UA"
   */
  useEffect(() => {
    /*
     * Some cases of Upload ERROR:
     * - `uploaded_failed_processing_another_homenet_file`
     * - `uploaded_failed`
     */
    if (isUploadSuccess) {
      getJobData();
    }
  }, [getJobData, isUploadSuccess]);

  /**
   * Let say "IA" is "import action"
   * It's action when user click on "Import"/"Import All" button
   * (when list of raw vehicles are showing)
   *
   * Check `jobStatus` after "IA"
   */
  useEffect(() => {
    /**
     * See node_modules/react-admin/docs/List.md
     * for reason why to call unselectAll()
     */
    if (isImportSuccess) {
      getJobData();
      unselectAll('import-vehicles');
    }
  }, [getJobData, isImportSuccess, unselectAll]);

  /**
   * Let say "CA" is "cancel action"
   * It's action when user click on "cancel" button in the list raw vehicle
   *
   * Check `jobStatus` after "CA" => back to initial state
   */
  useEffect(() => {
    /*
     * For this API
     * We can not determine success based on `cancelledData`
     * because `cancelledData` is still `null` when success
     * Note: `cancelError` is `null` **UNTIL** `cancelled` become true
     */

    if (isCancelSuccess) {
      getJobData();
      unselectAll('import-vehicles');
    }
  }, [getJobData, isCancelSuccess, unselectAll]);

  /**
   * Let say "CA" is "complete action"
   * It's action when user click on "complete" button in the final result
   *
   * Check `jobStatus` after "CA" => back to initial state
   */
  useEffect(() => {
    /*
     * For this API
     * We can not determine success based on `completedData`
     * because `completedData` is still `null` when success
     * Note: `completeError` is `null` **UNTIL** `completed` become true
     */
    if (isCompleteSuccess) {
      getJobData();
    }
  }, [getJobData, isCompleteSuccess]);

  /**
   * END OF ALL SIDE EFFECTS
   */

  const onOpenConfirmCancel = () => {
    setConfirmCancelOpen(true);
  };

  const onCloseConfirmCancel = () => {
    setConfirmCancelOpen(false);
  };

  const onImport = (ids, isPartial = false) => {
    const isNotEmpty = ids && ids.length;
    const isPartialConfirmed = isNotEmpty && isPartial && ids.length < total;
    const isFullImport = isNotEmpty && ids.length === total;
    const isEnable = isPartialConfirmed || isFullImport;

    if (isEnable) {
      setIsWaitingPolling(true);
      setLoadingMessage(translate(`${rootTranslate}.messages__import_loading`));
      importRawVehicles(
        {
          type: 'others',
          resource: 'import-vehicles',
          payload: {
            action: 'IMPORT',
            data: ids,
          },
        },
        {
          onSuccess: () => {
            setIsUploadSuccess(false);
            setIsImportSuccess(true);
          },
          onFailure: (error) => {
            const message = get(error, 'body.messages[0].message');
            setIsWaitingPolling(false);
            notify(message, 'error');
          },
        }
      );
    }
  };

  const onUpload = (data) => {
    setIsWaitingPolling(true);
    setLoadingMessage(translate(`${rootTranslate}.messages__upload_loading`));
    uploadFile(data, {
      onSuccess: () => {
        setIsUploadSuccess(true);
      },
      onFailure: (error) => {
        const message = get(error, 'body.messages[0].message');
        notify(message, 'warning');
        setIsWaitingPolling(false);
      },
    });
  };

  const handleError = () => {
    setHasError(true);
    notify(
      `${rootTranslate}.messages_please_fill_all_plate_number`,
      'warning',
      [],
      false,
      2000
    );
  };

  const handleLicensePlateChange = (data) => {
    setFormData((prevValues) => {
      const newValues = [...prevValues];
      const { source, error, id, value } = data;
      const index = newValues.findIndex((item) => item.id === id);

      if (index >= 0) {
        newValues[index] = {
          ...newValues[index],
          [source]: value,
          errors: {
            ...newValues[index]?.errors,
            [source]: error,
          },
        };
        return newValues;
      }

      return [
        ...newValues,
        {
          id,
          [source]: value,
          errors: { [source]: error },
        },
      ];
    });
  };

  /**
   * IMPORTANT!
   * Always check loading status before do anything
   */
  if (isWaitingPolling) {
    return <ImportStatus textLeft={loadingMessage} />;
  }

  if (isImported) {
    return (
      <ImportResult
        totalImported={totalImported}
        totalFailed={totalFailed}
        failedResultFileUrl={failedResultFileUrl}
        completeLoading={completeLoading}
        handleComplete={completeJob}
      />
    );
  }

  if (isUploadFailed && !!failedReason) {
    return (
      <>
        <UploadFailedResult
          failedMessage={failedReason}
          cancelLoading={cancelLoading}
          onOpenConfirmCancel={onOpenConfirmCancel}
        />
        <Confirm
          isOpen={confirmCancelOpen}
          loading={cancelLoading}
          title={translate(`${rootTranslate}.cancel_dialog_title`)}
          content={translate(`${rootTranslate}.messages__cancel_job`)}
          onConfirm={cancelJob}
          onClose={onCloseConfirmCancel}
        />
      </>
    );
  }

  if (isReadyToImport) {
    // https://github.com/marmelab/react-admin/issues/2000
    // Github issue with selectedIds does not work
    // Source code of DataGrid
    // https://github.com/marmelab/react-admin/blob/master/packages/ra-ui-materialui/src/list/datagrid/Datagrid.tsx
    // See line 126 to 137
    return (
      <>
        <Box pb={2}>
          <ImportStatus
            textLeft={jobData?.file_name}
            textRight={
              <Box display="flex" alignItems="center" color="#08B4A4">
                <CheckCircleIcon />
                <Box ml={0.5} fontWeight="bold">
                  {translate(`${rootTranslate}.messages_complete`)}
                </Box>
              </Box>
            }
          />
        </Box>
        <List
          {...props}
          title=" "
          actions={null}
          bulkActionButtons={
            <BulkImportActionButtons
              total={total}
              formData={formData}
              onConfirm={onImport}
              onCancel={onOpenConfirmCancel}
              importLoading={importLoading}
              onError={handleError}
            />
          }
          pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
          classes={listStyle}
        >
          <CustomDataGrid formData={formData}>
            <TextField
              source="vin"
              label={translate(`resources.vehicles.fields.vin`)}
            />
            <MakeModelYearField
              label={`${translate(
                `resources.vehicles.fields.vehicle_make`
              )} / ${translate(
                `resources.vehicles.fields.vehicle_model`
              )} / ${translate(`resources.vehicles.fields.vehicle_year`)}`}
            />
            <TextField
              source="seats"
              label={translate(`resources.vehicles.fields.seats`)}
            />
            <TextField source="doors" />

            <PlateNumberInput
              source="plate_number"
              onChange={handleLicensePlateChange}
              label={`${translate(`resources.vehicles.fields.plate_number`)} *`}
              showError={hasError}
              placeholder={translate(
                `${rootTranslate}.placeholder__plate_number`
              )}
              notSelectedPlaceholder={translate(
                `${rootTranslate}.placeholder__plate_number_not_selected`
              )}
            />
            <PlateNumberInput
              source="stock_number"
              onChange={handleLicensePlateChange}
              label={`${translate(`resources.vehicles.fields.stock_number`)} *`}
              showError={hasError}
              placeholder={translate(
                `${rootTranslate}.placeholder__stock_number`
              )}
              notSelectedPlaceholder={translate(
                `${rootTranslate}.placeholder__stock_number_not_selected`
              )}
            />
          </CustomDataGrid>
        </List>
        <Confirm
          isOpen={confirmCancelOpen}
          loading={cancelLoading}
          title={translate(`${rootTranslate}.cancel_dialog_title`)}
          content={translate(`${rootTranslate}.messages__cancel_job`)}
          onConfirm={cancelJob}
          onClose={onCloseConfirmCancel}
        />
      </>
    );
  }

  if (isUploadable) {
    return (
      <UploadFormCard
        onUpload={onUpload}
        isLoading={isWaitingPolling}
        setIsLoading={setIsWaitingPolling}
      />
    );
  }

  return null;
};

const RawVehicleListWrapper = (props) => {
  const translate = useTranslate();
  return (
    <>
      <Title title={translate(`${rootTranslate}.name`)} />
      <Box component={Paper} p={3} bgcolor="#fff">
        <RawVehicleList {...props} />
      </Box>
    </>
  );
};

export default RawVehicleListWrapper;
