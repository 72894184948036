import { useState, useCallback } from 'react';
import { Grid, AppBar, Tabs, Tab } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TabPanel, TabContext } from '@material-ui/lab';
import common from '@material-ui/core/colors/common';
import * as React from 'react';
import { Show, useTranslate } from 'react-admin';
import VehicleTitle from '../components/VehicleTitle';
import SideCard from './SideCard';
import InfoTabContent from './InfoTabContent';
import RegistrationTabContent from './RegistrationTabContent';
import ServiceTabContent from './ServiceTabContent';

const useStyles = makeStyles((theme) => ({
  appBar: {
    boxShadow: 'none',
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
  },
  tabPanel: {
    backgroundColor: common.white,
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
  },
}));

const VehicleDetail = ({ record }) => {
  const trans = useTranslate();
  const classes = useStyles();
  const [value, setValue] = useState('tab_1');
  const handleChangeTab = useCallback((event, tabValue) => {
    setValue(tabValue);
  }, []);
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <SideCard record={record} />
        </Grid>
        <Grid item xs={12} md={8}>
          <TabContext value={value}>
            <AppBar
              classes={{ root: classes.appBar }}
              position="static"
              color="secondary"
            >
              <Tabs
                value={value}
                onChange={handleChangeTab}
                indicatorColor="primary"
              >
                <Tab
                  value="tab_1"
                  label={trans('resources.vehicles.tab_information')}
                />
                <Tab
                  value="tab_2"
                  label={trans('resources.vehicles.tab_registration')}
                />
                <Tab
                  value="tab_3"
                  label={trans('resources.vehicles.tab_services')}
                />
              </Tabs>
            </AppBar>
            <TabPanel className={classes.tabPanel} value="tab_1">
              <InfoTabContent record={record} />
            </TabPanel>
            <TabPanel className={classes.tabPanel} value="tab_2">
              <RegistrationTabContent record={record} />
            </TabPanel>
            <TabPanel className={classes.tabPanel} value="tab_3">
              <ServiceTabContent record={record} />
            </TabPanel>
          </TabContext>
        </Grid>
      </Grid>
    </>
  );
};

const VehicleShow = (props) => {
  const { basePath } = props;
  return (
    <Show
      title={<VehicleTitle basePath={basePath} />}
      // actions={false}
      component="div"
      {...props}
    >
      <VehicleDetail />
    </Show>
  );
};

export default VehicleShow;
