import { ListButton, SaveButton, DeleteWithConfirmButton } from 'react-admin';
import PropTypes from 'prop-types';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { Box, Toolbar } from '@material-ui/core';

const FormToolbar = (props) => {
  const {
    actionLabel,
    alwaysEnableSaveButton,
    basePath,
    children,
    className,
    handleSubmit,
    handleSubmitWithRedirect,
    invalid,
    pristine,
    record,
    redirect,
    resource,
    saving,
    submitOnEnter,
    undoable,
    width,
    showDelete = false,
    showBack = false,
    ...rest
  } = props;
  return (
    <Box
      component={Toolbar}
      {...rest}
      display="flex"
      justifyContent="space-between"
      bgcolor="#F5F5F5"
    >
      {showBack && (
        <ListButton
          variant="outlined"
          color="primary"
          size="medium"
          label="ra.action.back"
          basePath={basePath}
          icon={<ChevronLeft />}
        />
      )}
      {showDelete && record && typeof record.id !== 'undefined' && (
        <DeleteWithConfirmButton
          basePath={basePath}
          record={record}
          resource={resource}
          undoable={undoable}
          confirmTitle="messages__confirm_delete"
        />
      )}
      <Box
        ml="auto"
        component={SaveButton}
        variant="contained"
        label={actionLabel}
        handleSubmitWithRedirect={handleSubmitWithRedirect || handleSubmit}
        invalid={invalid}
        redirect={redirect}
        saving={saving}
        submitOnEnter={submitOnEnter}
      />
    </Box>
  );
};
FormToolbar.propTypes = {
  actionLabel: PropTypes.string,
};

FormToolbar.defaultProps = {
  actionLabel: 'ra.action.save',
};

export default FormToolbar;
