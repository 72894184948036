import { get } from 'lodash';
import { useTranslate } from 'react-admin';
import Chip from '@material-ui/core/Chip';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { bookingStatus } from 'src/status';

const useStyle = makeStyles((theme) => ({
  chip: {
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 'bold',
    color: 'white',
  },
}));

const CustomChip = ({ record }) => {
  const translate = useTranslate();
  const classes = useStyle();

  const {
    palette: { secondary },
  } = useTheme();

  const state = get(record, 'state', '');

  return (
    <Chip
      className={classes.chip}
      style={{
        backgroundColor: get(bookingStatus, [state, 'color'], secondary),
      }}
      label={translate(get(bookingStatus, [state, 'iLabel'], state))}
    />
  );
};

CustomChip.propTypes = {
  record: PropTypes.shape({
    state: PropTypes.oneOf(['booked', 'completed', 'in-trip', 'cancelled']),
  }),
};

export default CustomChip;
