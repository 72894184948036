import { useMutation, useNotify } from 'react-admin';
import { rootTranslate } from '../const';

const useCompleteJob = ({ onSuccess }) => {
  const notify = useNotify();
  const mutationData = useMutation(
    {
      type: 'others',
      resource: 'import-vehicles',
      payload: {
        action: 'COMPLETE',
      },
    },
    {
      onSuccess,
      onFailure: (error) => {
        notify(`${rootTranslate}.messages__complete_failed`, 'warning');
      },
    }
  );

  return mutationData;
};

export default useCompleteJob;
