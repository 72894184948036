import * as React from 'react';
import { Route } from 'react-router-dom';
import FinishRegistration from './features/FinishRegistration';
import Profile from './features/profile';
// import Configuration from './configuration/Configuration';
// import Segments from './segments/Segments';

export default [
  <Route
    exact
    path="/finish-registration"
    render={() => <FinishRegistration />}
  />,
  <Route exact path="/profile" component={Profile.edit} />,
  // <Route exact path="/configuration" render={() => <Configuration />} />,
  // <Route exact path="/segments" render={() => <Segments />} />,
  // <Route exact path="/logout" render={() => <Logout />} />,
];
