export function buildProviderByType(type) {
  return {
    getList: ({ params }) => {
      const { filter = {}, ...otherParams } = params;
      const {
        search = '',
        fromDate = '',
        toDate = '',
        ...otherFilter
      } = filter;
      let extraParams = {};

      if (type) {
        extraParams = {
          ...extraParams,
          type,
          from_date: fromDate,
          to_date: toDate,
          search,
        };
      }

      if (search) {
        extraParams = {
          ...extraParams,
          search,
          from_date: fromDate,
          to_date: toDate,
        };
      }

      return {
        url: '/bookings',
        extraParams,
        params: {
          ...otherParams,
          filter: { ...otherFilter },
        },
        parser: (json) => {
          const { data = {}, meta } = json;
          const { bookings = [] } = data;
          const { pagination } = meta || {};
          const { total } = pagination || 0;

          return {
            data: bookings,
            total,
          };
        },
      };
    },
    getOne: () => {
      return {
        url: '/bookings',
      };
    },
    update: () => {
      return {
        url: '/bookings',
      };
    },
    delete: () => {
      return {
        url: '/bookings',
      };
    },
  };
}
