import { Grid } from '@material-ui/core';
import { Labeled, TextField, DateField } from 'react-admin';

const RegistrationTabContent = ({ record }) => (
  <Grid container spacing={3}>
    <Grid
      component={Labeled}
      item
      xs={4}
      source="plate_number"
      resource="vehicles"
    >
      <TextField source="plate_number" record={record} emptyText="--" />
    </Grid>
  </Grid>
);

export default RegistrationTabContent;
