import { Typography } from '@material-ui/core';
import { get } from 'lodash';
import { formatCurrency } from 'utils/format';

export default function CurrencyField({
  record,
  source,
  locales = 'en-US',
  currency = 'USD',
}) {
  const value = get(record, source);

  return (
    <Typography variant="body2">
      {formatCurrency(value, locales, currency)}
    </Typography>
  );
}
