import React, { useEffect } from 'react';
import { makeStyles, Button, Box, Typography } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import adminLocalStorage from 'store/localStorage';
import { useTranslate, useQuery } from 'react-admin';
import { useSelector, useDispatch } from 'react-redux';
import { dashboardSelectors } from 'store/selectors';
import { dashboardActionCreators } from 'store/actions';
import { actions } from '../const';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      padding: theme.spacing(2, 3, 2, 3),
      borderColor: theme.palette.common.silverSand,
      background: theme.palette.common.lavender,
    },
    icon: {
      display: 'none',
    },
    action: {
      marginRight: 0,
    },
    content: {
      '& > *:first-child': {
        paddingTop: 0,
        marginTop: 0,
      },
      '& > *:last-child': {
        paddingBottom: 0,
        marginBottom: 0,
      },
    },
  };
});

const useAlertTitleStyles = makeStyles((theme) => {
  return {
    root: {
      fontSize: theme.typography.pxToRem(20),
      fontWeight: 'bold',
      color: theme.palette.common.brandPurple,
    },
  };
});

const useBtnStyles = makeStyles((theme) => {
  return {
    root: {
      height: '38px',
      width: '140px',
      boxShadow: 'none',
      fontWeight: 700,
    },
  };
});

function ReminderActionBtn({ onClick }) {
  const classes = useBtnStyles();
  const t = useTranslate();
  return (
    <Button
      color="primary"
      onClick={onClick}
      variant="contained"
      classes={classes}
    >
      {t('common__okay')}
    </Button>
  );
}

function Reminder() {
  const dispatch = useDispatch();
  const { data = {} } = useQuery({
    type: 'others',
    resource: 'dashboard',
    payload: { action: actions.API_GET_REMINDER },
  });
  const { show } = useSelector(dashboardSelectors.getReminder);
  const { content: contentClassName, ...classes } = useStyles();
  const alertTitleClasses = useAlertTitleStyles();

  const { title, content, is_active: isActive = 0 } = data;

  const handleOnClose = () => {
    dispatch(dashboardActionCreators.showReminder(false));
    dispatch(dashboardActionCreators.rememberReminder());
  };

  useEffect(() => {
    const { show = true } = adminLocalStorage.get('reminder');
    dispatch(dashboardActionCreators.showReminder(show));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!show || !isActive) {
    return null;
  }

  return (
    <Alert
      icon={null}
      severity="info"
      classes={classes}
      action={<ReminderActionBtn onClick={handleOnClose} />}
    >
      {title && <AlertTitle classes={alertTitleClasses}>{title}</AlertTitle>}
      {content && (
        <Box className={contentClassName}>
          <Typography variant="body2">{content}</Typography>
        </Box>
      )}
    </Alert>
  );
}

export default Reminder;
