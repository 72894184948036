import { useTranslate } from 'react-admin';
import StatusChip from 'components/StatusChip';
import { vehicleStatus } from 'status';

const StatusField = (props) => {
  const trans = useTranslate();
  const { record } = props;
  const { status } = record;
  const { iLabel, color } = vehicleStatus[status] || {};
  return <StatusChip text={iLabel && trans(iLabel)} color={color} />;
};

export default StatusField;
