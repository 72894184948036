const authProvider = ({
  isLoading,
  isAuthenticated,
  loginWithRedirect,
  logout,
  user,
  getAccessTokenSilently,
}) => ({
  login: () => {
    if (isAuthenticated) {
      return Promise.resolve();
    } else {
      loginWithRedirect();
      return Promise.resolve();
    }
  },
  logout: () => {
    if (isAuthenticated) {
      logout({ returnTo: `${window.location.origin}/#/login` });
    }

    // window.location.href = `${window.location.origin}/#/login`;
  },
  checkError: (error = {}) => {
    if (
      error.error === 'login_required' ||
      error.status === 401 ||
      error.status === 403
    ) {
      return Promise.reject();
    }
    if (isAuthenticated || isLoading) {
      return Promise.resolve();
    }

    return Promise.resolve();
  },
  checkAuth: async () => {
    return isAuthenticated ? Promise.resolve() : Promise.reject();
  },
  getPermissions: () => Promise.reject('Unknown method'),
  getIdentity: () =>
    Promise.resolve(
      isAuthenticated
        ? {
            id: user.id,
            fullName: user.name,
            avatar: user.picture,
          }
        : {}
    ),
});

export default authProvider;
