import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import _ from 'lodash';
import InputAdornment from '@material-ui/core/InputAdornment';
import {
  TabbedForm,
  TextInput,
  NumberInput,
  FormTab,
  ReferenceInput,
  AutocompleteInput,
  required,
  number,
  maxValue,
  minValue,
  TabbedFormTabs,
  SelectInput,
} from 'react-admin';
import FormToolbar from 'components/FormToolbar';
import MultipleImagesInput from 'components/MultipleImagesInput';
import { useFormState } from 'react-final-form';
import VehicalEditService from './VehicalEditService';

const useStyles = makeStyles((theme) => ({
  tabbedForm: {
    background: '#fff',
  },
  formTab: {
    textAlign: 'center',
  },
  formTabContent: {
    display: 'block',
    textAlign: 'left',
    paddingTop: theme.spacing(2),
    paddingLeft: 0,
    paddingRight: 0,
  },
  shortInput: {
    width: 166,
  },
  fullInput: {
    padding: theme.spacing(0, 1),
    width: '530px',
    maxWidth: '100%',
  },
  inputW330: {
    padding: theme.spacing(0, 1),
    width: '330px',
    maxWidth: '100%',
    boxSizing: 'border-box',
    display: 'inline-block',
  },
  inputW215: {
    padding: theme.spacing(0, 1),
    width: '215px',
    maxWidth: '100%',
    boxSizing: 'border-box',
    display: 'inline-block',
  },

  inputWrapper: {
    display: 'inline-block',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  fullWidth: {
    width: '100%',
  },
}));

const validateYear = [
  required(),
  number(),
  minValue(1990),
  maxValue(new Date().getFullYear()),
];
const RowBreaker = () => <Box />;
const doNothing = () => {};

const recordToInitialValues = (record) => {
  if (!record) {
    return {};
  }
  return {
    ...record,
    category_id: _.get(record, 'category.id'),
    model_id: _.get(record, 'vehicle_model.id'),
    make_id: _.get(record, 'vehicle_make.id'),
    year: _.get(record, 'vehicle_year'),
    allocation_type_id: _.get(record, 'allocation_type.id'),
  };
};

/**
 * The custom TabbedFormTabs that only highlights invalid tabs after user click submit
 */
const Tabs = ({ tabsWithErrors, ...props }) => {
  const { submitFailed } = useFormState();

  return (
    <TabbedFormTabs
      tabsWithErrors={submitFailed ? tabsWithErrors : []}
      {...props}
    />
  );
};

const VehicleForm = (props) => {
  const { record, view = 'edit', onVINChange } = props;
  const classes = useStyles();
  const inputClasses = {
    formClassName: classes.inputWrapper,
    className: classes.input,
  };

  const shortInputClasses = {
    formClassName: classes.inputWrapper,
    className: classes.shortInput,
  };

  const fullInputClasses = {
    formClassName: classes.fullInput,
    className: classes.input,
  };

  const intialValues = recordToInitialValues({
    ...record,
    available_delivery_types: ['vehicle_delivery', 'hub'],
  });

  const [newRecord, setRecord] = useState({
    ...record,
    available_hubs: record.available_hubs
      ? record.available_hubs.map((hub) => {
          return hub.id;
        })
      : [],
  });

  const changeAvailableServices = (data) => {
    setRecord({
      ...newRecord,
      available_hubs: newRecord.available_hubs
        ? newRecord.available_hubs.reduce((array, hub) => {
            const foundData = _.findIndex(data, (d) => {
              return d.id === hub;
            });
            if (foundData >= 0) {
              array.push(hub);
            }
            return array;
          }, [])
        : [],
    });
  };

  const changeAvailableHubs = (data) => {
    setRecord({
      ...newRecord,
      available_hubs: data,
    });
  };

  return (
    <TabbedForm
      className={classes.tabbedForm}
      classes={{ content: classes.formTab }}
      {...props}
      toolbar={<FormToolbar showDelete={true} />}
      variant="outlined"
      initialValues={intialValues}
      redirect="show"
      tabs={<Tabs />}
      record={newRecord}
    >
      <FormTab
        label="resources.vehicles.tab_information"
        contentClassName={classes.formTabContent}
      >
        <TextInput
          fullWidth
          source="vin"
          validate={required()}
          formClassName={classes.inputW330}
          className={classes.input}
          disabled={view === 'edit'}
          onChange={onVINChange}
        />
        <ReferenceInput
          source="allocation_type_id"
          reference="allocation-types"
          filterToQuery={doNothing}
          validate={required()}
          label="resources.vehicles.fields.allocation_type.name"
          fullWidth
          formClassName={classes.inputW215}
          className={classes.input}
        >
          <SelectInput validate={required()} />
        </ReferenceInput>
        <RowBreaker />
        <ReferenceInput
          source="make_id"
          reference="vehicle-makes"
          perPage={1000}
          filterToQuery={doNothing}
          validate={required()}
          {...shortInputClasses}
        >
          <AutocompleteInput />
        </ReferenceInput>
        <ReferenceInput
          source="model_id"
          reference="vehicle-models"
          perPage={1000}
          filterToQuery={doNothing}
          validate={required()}
          {...shortInputClasses}
        >
          <AutocompleteInput />
        </ReferenceInput>
        <NumberInput
          source="year"
          validate={validateYear}
          {...shortInputClasses}
        />
        <RowBreaker />
        <NumberInput
          source="number_of_seats"
          min={1}
          validate={required()}
          {...inputClasses}
        />
        <NumberInput source="number_of_doors" min={1} {...inputClasses} />
        <RowBreaker />
        <TextInput source="fuel_type" validate={required()} {...inputClasses} />
        <NumberInput source="fuel_tank_capacity" min={1} {...inputClasses} />
        <RowBreaker />
        <TextInput source="exterior_color" {...inputClasses} />
        <TextInput source="interior_color" {...inputClasses} />
        <RowBreaker />
        <RowBreaker />
        <NumberInput
          source="book_value"
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          {...inputClasses}
        />
        <NumberInput
          source="selling_price"
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          {...inputClasses}
        />
        <RowBreaker />
        <TextInput
          source="stock_number"
          validate={required()}
          {...inputClasses}
        />
      </FormTab>
      <FormTab
        label="resources.vehicles.tab_registration"
        contentClassName={classes.formTabContent}
      >
        <TextInput
          source="plate_number"
          {...fullInputClasses}
          validate={required()}
        />
      </FormTab>
      <FormTab
        label="resources.vehicles.tab_others"
        contentClassName={classes.formTabContent}
      >
        <MultipleImagesInput
          fullWidth
          source="vehicle_images"
          sourceDelete="deleted_images"
          sourceUpload="uploaded_images"
        />
      </FormTab>
      <FormTab
        label="resources.vehicles.tab_services"
        contentClassName={classes.formTabContent}
        sx={{ paddingBottom: '100px' }}
      >
        <VehicalEditService
          changeAvailableServices={changeAvailableServices}
          changeAvailableHubs={changeAvailableHubs}
        />
      </FormTab>
    </TabbedForm>
  );
};

export default VehicleForm;
