import React, { useState, useEffect, useCallback } from 'react';
import {
  useMutation,
  Loading,
  useNotify,
  useTranslate,
  SimpleForm,
  Title,
} from 'react-admin';
import { Grid, makeStyles, Card } from '@material-ui/core';
import ProfileRight from './components/ProfileRight';
import ProfileLeft from './components/ProfileLeft';
import { rootTranslate } from './const';

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: '100%',
  },
  form: {
    '& > .MuiCardContent-root': {
      padding: 0,
    },
  },
}));

function transform(data) {
  const { user = {} } = data;
  const { email, ...restUser } = user;
  return {
    ...data,
    location: {
      city_id: data.city.id,
      state_id: data.state.id,
      country_id: data.country.id,
    },
    user: {
      ...restUser,
    },
  };
}

const ProfileEdit = ({ staticContext, ...props }) => {
  const [getProfileMutate, { loading: getProfileLoading }] = useMutation();
  const [
    updateProfileMutate,
    { loading: updateProfileLoading },
  ] = useMutation();
  const [uploadPictureMutate] = useMutation();
  const translate = useTranslate();
  const classes = useStyles();
  const [record, setRecord] = useState(null);

  const notify = useNotify();

  const getProfile = useCallback(() => {
    getProfileMutate(
      {
        type: 'others',
        resource: 'profile',
        payload: {
          action: 'GET_PROFILE',
        },
      },
      {
        onSuccess: (res) => {
          const { data } = res;
          setRecord(data);
        },
      }
    );
  }, [getProfileMutate]);

  const handleUpdateProfile = useCallback(
    (data, redirectTo, options) => {
      updateProfileMutate(
        {
          type: 'others',
          resource: 'profile',
          payload: {
            action: 'UPDATE_PROFILE',
            id: record.id || '',
            data: transform(data),
          },
        },
        {
          onSuccess: () => {
            notify(
              translate(`${rootTranslate}.messages__update_profile_success`)
            );
          },
          onFailure: (error) =>
            notify(
              translate(`${rootTranslate}.messages__update_profile_unsuccess`)
            ),
        }
      );
    },
    [updateProfileMutate, record, translate, notify]
  );

  const handleUploadPicture = useCallback(
    (file) => {
      uploadPictureMutate(
        {
          type: 'others',
          resource: 'profile',
          payload: {
            action: 'UPLOAD_PICTURE',
            data: {
              image: file[0],
            },
          },
        },
        {
          onSuccess: (res) => {
            const { url } = res.data;
            notify(
              translate(`${rootTranslate}.messages__updated_profile_picture`)
            );
            setRecord({
              ...record,
              user: {
                ...record.user,
                picture: url,
              },
            });
          },
        }
      );
    },
    [record, uploadPictureMutate, notify, translate]
  );

  useEffect(() => {
    getProfile();
  }, [getProfile]);

  return (
    <>
      <Title title="Profile" />
      {getProfileLoading ? (
        <Loading />
      ) : (
        <Grid container spacing={2} className={classes.fullWidth}>
          <Grid item xs={4}>
            <SimpleForm record={record} toolbar={null} className={classes.form}>
              <ProfileLeft
                enableUploadProfile
                onUploadFile={handleUploadPicture}
              />
            </SimpleForm>
          </Grid>
          <Grid item xs={8}>
            <Card>
              <ProfileRight
                saving={updateProfileLoading}
                record={record}
                onSave={handleUpdateProfile}
              />
            </Card>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ProfileEdit;
