import * as React from 'react';
import { useTheme } from '@material-ui/core/styles';

const Logo = (props) => {
  const theme = useTheme();
  return (
    <svg
      width="123"
      height="16"
      viewBox="0 0 123 16"
      xmlns="http://www.w3.org/2000/svg"
      fill={theme.palette.secondary.light}
      {...props}
    >
      <path
        d="M43.8168 2.20465H46.0379V0H39.0171L38.999 2.20465H41.2156L41.2563 12.7814H39.0397L39.0171 14.986H46.0379L46.0605 12.7814H43.8394L43.8168 2.20465Z"
        fill="white"
      />
      <path
        d="M83.2636 7.00466C84.182 6.29303 84.7791 5.16745 84.7791 3.89768C84.7791 1.74885 83.0827 0.00466032 80.9927 0.00466032H68.8058V14.9907L80.6625 15.0047C83.2501 14.8605 85.5527 13.5581 85.3853 10.5116C85.3038 9.09303 84.7429 7.70233 83.2636 7.00466ZM80.3232 2.26978C81.3501 2.26978 82.187 3.12559 82.187 4.18606C82.187 5.24652 81.3546 6.10233 80.3232 6.10233H71.4477L71.4251 2.26978H80.3232ZM80.9113 12.6605H71.4477L71.4251 8.33024H80.9113C82.0739 8.33024 83.0194 9.30233 83.0194 10.4977C83.0194 11.693 82.0739 12.6605 80.9113 12.6605Z"
        fill="white"
      />
      <path
        d="M110.216 12.6512L110.193 8.56279H119.77L119.752 6.33488H110.216L110.153 2.33488H122.665V0H107.551V14.986H122.977L123 12.6512H110.216Z"
        fill="white"
      />
      <path
        d="M9.78481 12.5861H9.20125L2.9359 9.06679e-06H0L7.5139 14.9861H11.3048L18.9137 9.06679e-06H16.0954L9.78481 12.5861Z"
        fill="white"
      />
    </svg>
  );
};

export default Logo;
