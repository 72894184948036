export const BACKEND_DATE_FORMAT = 'yyyy-MM-dd';
export const FORMAT_DATE_TIME = 'MM/dd/yyyy hh:mm a';
export const DISPLAYED_DATE_FORMAT = 'MM/dd/yyyy';
export const DISPLAYED_DATE_FORMAT_2 = 'MMM dd';
export const DISPLAYED_DATE_FORMAT_3 = 'dd MMMM yyyy';
export const FOR_BACKEND_DATE_ONLY_FORMAT = 'yyyy-MM-dd';
export const FOR_BACKEND_TIME_ONLY_FORMAT = 'HH:mm';
export const FOR_BACKEND_DATE_TIME_FORMAT = 'yyyy-MM-dd HH:mm';
export const FORMAT_TIME = 'hh:mm a';
export const MAX_SIZE_IMAGE_UPLOAD = 5000; // 5MB

export const paymentType = {
  checking: { value: 'checking', text: 'constants__payment_type__checking' },
  saving: { value: 'saving', text: 'constants__payment_type__saving' },
};

export const usedCars = ['1-10', '11-25', '26-50', '51-100', '101+'];
