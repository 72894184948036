const dataProvider = {
  getList: ({ params }) => {
    const url = `/homenet-vehicles/list`;

    return {
      url,
      parser: (json) => {
        const { data, meta } = json;
        const { pagination } = meta || {};
        const { total } = pagination || 0;

        return {
          data,
          total,
        };
      },
    };
  },
  others: ({ params }) => {
    const { action, data } = params;
    switch (action) {
      case 'UPLOAD':
        const body = new FormData();
        body.append('file', params.data);

        return {
          url: '/homenet-vehicles/upload',
          method: 'POST',
          body,
          parser: (json) => json,
        };
      case 'GET_ACTIVE_JOB':
        return {
          url: `/homenet-vehicles/active-job`,
          method: 'GET',
          body: JSON.stringify(data),
          parser: (json) => json,
        };
      case 'GET_LIST_RAW_VEHICLES': {
        return {
          url: '/homenet-vehicles/list',
          method: 'GET',
          isFile: true,
          parser: (json) => json,
        };
      }
      case 'IMPORT': {
        return {
          url: '/homenet-vehicles/import',
          method: 'POST',
          isFile: true,
          body: JSON.stringify({
            temp_vehicles: data,
          }),
          parser: (json) => json,
        };
      }
      case 'CANCEL': {
        return {
          url: '/homenet-vehicles/cancel',
          method: 'DELETE',
          isFile: true,
          body: JSON.stringify(data),
          parser: (json) => json,
        };
      }
      case 'COMPLETE': {
        return {
          url: '/homenet-vehicles/complete',
          method: 'POST',
          isFile: true,
          body: JSON.stringify(data),
          parser: (json) => json,
        };
      }
      default:
        break;
    }
  },
};

export default dataProvider;
