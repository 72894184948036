import { useTranslate } from 'react-admin';
import { get } from 'lodash';
import StatusChip from 'components/StatusChip';
import { rootTranslate } from '../const';

const VehicleType = ({ type = '' }) => {
  const t = useTranslate();
  const types = {
    listed: {
      label: t(`${rootTranslate}.label__type_listed`),
      color: '#316EE5',
    },
    unlisted: {
      label: t(`${rootTranslate}.label__type_unlisted`),
      color: '#FFB946',
    },
    booked: {
      label: t(`${rootTranslate}.label__type_booked`),
      color: '#9673BB',
    },
    reserved: {
      label: t(`${rootTranslate}.label__type_reserved`),
      color: '#442C5D',
    },
    'in-trip': {
      label: t(`${rootTranslate}.label__type_in_trip`),
      color: '#64309A',
    },
    'return-delayed': {
      label: t(`${rootTranslate}.label__type_in_trip`),
      color: '#64309A',
    },
  };

  const currentType = get(types, type, null);

  if (!currentType) {
    return null;
  }

  return <StatusChip text={currentType.label} color={currentType.color} />;
};

export default VehicleType;
