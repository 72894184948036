import React from 'react';
import { Box } from '@material-ui/core';
import Typography from 'components/CustomTypography';

function ImportProcessing({ textLeft = '', textRight = '' }) {
  return (
    <Box bgcolor="#F4F4F4" p={2} display="flex" justifyContent="space-between">
      <Typography>{textLeft}</Typography>
      <Typography>{textRight}</Typography>
    </Box>
  );
}

export default ImportProcessing;
