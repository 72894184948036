/* eslint-disable import/prefer-default-export */
import { purple, green, yellow } from './statusColors';

export const vehicleStatus = {
  1: {
    color: green,
    iLabel: 'resources.vehicles.constants__status_available',
  },
  2: {
    color: yellow,
    iLabel: 'resources.vehicles.constants__status_unavailable',
  },
  3: {
    color: purple,
    iLabel: 'resources.vehicles.constants__status_pending',
  },
};
export default vehicleStatus;
