import * as React from 'react';
import { Grid } from '@material-ui/core';
import { Edit, Loading, useEditContext } from 'react-admin';
import VehicleForm from '../components/VehicleForm';
import VehicleTitle from '../components/VehicleTitle';
import SideCard from './SideCard';

const Content = (props) => {
  const { record } = props;
  const { loading } = useEditContext();

  if (loading) {
    return <Loading />;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <SideCard record={record} />
      </Grid>
      <Grid item xs={12} md={8}>
        <VehicleForm {...props} view="edit" />
      </Grid>
    </Grid>
  );
};

const VehicleEdit = (props) => {
  const { basePath } = props;
  return (
    <Edit
      {...props}
      component="div"
      title={<VehicleTitle basePath={basePath} />}
      undoable={false}
      redirect="show"
    >
      <Content />
    </Edit>
  );
};
export default VehicleEdit;
