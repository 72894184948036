import React from 'react';
import {
  TextInput,
  TabbedForm,
  FormTab,
  required,
  ReferenceInput,
  SelectInput,
  useTranslate,
  number,
  minLength,
  maxLength,
  email,
} from 'react-admin';
import FormToolbar from 'components/FormToolbar';
import { makeStyles, Typography } from '@material-ui/core';
import DependentReferenceInput from 'components/DependentReferenceInput';
import { rootTranslate } from '../const';
import { paymentType, usedCars } from 'src/constants';
import NumberInput from 'components/NumberInput';
import PhoneInput from 'components/PhoneInput';

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: 'auto',
  },
  formTabContent: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingTop: theme.spacing(2),
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

const routingNumberValidation = [
  required(),
  number(),
  minLength(9, 'signup.validations_routing_number'),
  maxLength(9, 'signup.validations_routing_number'),
];
const accountNumberValidation = [required(), number(), maxLength(17)];

function CustomSelect(props) {
  const { choices, ...otherProps } = props;
  if (!choices.length) {
    const emptyProps = {
      ...otherProps,
      input: { ...otherProps.input, value: '' },
    };
    return <SelectInput choices={choices} {...emptyProps} />;
  }
  return <SelectInput {...props} />;
}

function Heading({ formClassName, basePath, children, ...props }) {
  return <Typography {...props}>{children}</Typography>;
}

function ProfileRight({ onSave, saving = false, ...props }) {
  const classes = useStyles();
  const translate = useTranslate();
  const formTabClasses = `${classes.formTabContent} MuiGrid-container MuiGrid-spacing-xs-2`;

  return (
    <TabbedForm
      {...props}
      variant="outlined"
      toolbar={<FormToolbar />}
      save={onSave}
      saving={saving}
      redirect={false}
    >
      <FormTab
        contentClassName={formTabClasses}
        label={translate(`${rootTranslate}.person`)}
      >
        <TextInput
          fullWidth
          source="user.first_name"
          variant="outlined"
          validate={[required()]}
          label={translate(`${rootTranslate}.fields.first_name`)}
          autoFocus
          formClassName="MuiGrid-item MuiGrid-grid-xs-6"
        />
        <TextInput
          fullWidth
          source="user.last_name"
          variant="outlined"
          validate={[required()]}
          label={translate(`${rootTranslate}.fields.last_name`)}
          formClassName="MuiGrid-item MuiGrid-grid-xs-6"
        />
        <PhoneInput
          fullWidth
          source="user.phone"
          phoneCodeSource="user.country_code"
          variant="outlined"
          validate={[required()]}
          label={translate(`${rootTranslate}.fields.phone`)}
          formClassName="MuiGrid-item MuiGrid-grid-xs-6"
        />
        <TextInput
          fullWidth
          source="user.email"
          variant="outlined"
          validate={[required()]}
          label={translate(`${rootTranslate}.fields.email`)}
          disabled
          formClassName="MuiGrid-item MuiGrid-grid-xs-6"
        />
      </FormTab>
      <FormTab
        contentClassName={formTabClasses}
        label={translate(`${rootTranslate}.dealer_info`)}
      >
        <TextInput
          fullWidth
          source="name"
          variant="outlined"
          validate={[required()]}
          label={translate(`${rootTranslate}.fields.name`)}
          formClassName="MuiGrid-item MuiGrid-grid-xs-4"
        />
        <TextInput
          fullWidth
          source="address"
          variant="outlined"
          validate={[required()]}
          label={translate(`${rootTranslate}.fields.address`)}
          formClassName="MuiGrid-item MuiGrid-grid-xs-8"
        />
        <NumberInput
          fullWidth
          source="coordinate.lat"
          variant="outlined"
          validate={[required()]}
          label={translate(`${rootTranslate}.fields.lat`)}
          formClassName="MuiGrid-item MuiGrid-grid-xs-4"
        />
        <NumberInput
          fullWidth
          source="coordinate.lng"
          variant="outlined"
          validate={[required()]}
          label={translate(`${rootTranslate}.fields.lng`)}
          formClassName="MuiGrid-item MuiGrid-grid-xs-4"
        />
        <TextInput
          fullWidth
          source="postal_code"
          variant="outlined"
          validate={[required()]}
          label={translate(`${rootTranslate}.fields.postal_code`)}
          formClassName="MuiGrid-item MuiGrid-grid-xs-4"
        />
        <ReferenceInput
          source="country.id"
          reference="locale/countries"
          label={translate(`${rootTranslate}.fields.country`)}
          filterToQuery={() => {}}
          sort={{
            field: 'name',
            order: 'ASC',
          }}
          fullWidth
          variant="outlined"
          formClassName="MuiGrid-item MuiGrid-grid-xs-4"
        >
          <SelectInput validate={[required()]} />
        </ReferenceInput>
        <DependentReferenceInput
          dependencySource="country.id"
          source="state.id"
          reference="locale/states"
          dependencyToFilter={(dependentValue) => ({
            country_id: dependentValue,
          })}
          dependencyToDisabled={(dependentValue) => !dependentValue}
          label={translate(`${rootTranslate}.fields.state`)}
          filterToQuery={() => {}}
          sort={{
            field: 'name',
            order: 'ASC',
          }}
          fullWidth
          variant="outlined"
          formClassName="MuiGrid-item MuiGrid-grid-xs-4"
        >
          <CustomSelect validate={[required()]} />
        </DependentReferenceInput>
        <DependentReferenceInput
          dependencySource="state.id"
          source="city.id"
          reference="locale/cities"
          label={translate(`${rootTranslate}.fields.city`)}
          filterToQuery={() => {}}
          dependencyToFilter={(dependencyValue) => ({
            state_id: dependencyValue,
          })}
          dependencyToDisabled={(dependentValue) => !dependentValue}
          sort={{
            field: 'name',
            order: 'ASC',
          }}
          fullWidth
          variant="outlined"
          formClassName="MuiGrid-item MuiGrid-grid-xs-4"
        >
          <CustomSelect />
        </DependentReferenceInput>
        <SelectInput
          fullWidth
          source="number_of_used_vehicles"
          choices={usedCars.map((u) => ({
            id: u,
            name: u,
          }))}
          variant="outlined"
          validate={[required()]}
          label={translate(`${rootTranslate}.fields.number_of_used_vehicles`)}
          formClassName="MuiGrid-item MuiGrid-grid-xs-6"
        />
      </FormTab>
      <FormTab
        contentClassName={formTabClasses}
        label={translate(`${rootTranslate}.contact`)}
      >
        <Heading variant="h6" formClassName="MuiGrid-item MuiGrid-grid-xs-12">
          {translate(`${rootTranslate}.primary_contact`)}
        </Heading>

        <TextInput
          fullWidth
          source="primary_contact.name"
          variant="outlined"
          validate={[required()]}
          label={translate(`${rootTranslate}.fields.name`)}
          formClassName="MuiGrid-item MuiGrid-grid-xs-4"
        />

        <PhoneInput
          fullWidth
          source="primary_contact.phone"
          phoneCodeSource="user.country_code"
          variant="outlined"
          validate={[required()]}
          label={translate(`${rootTranslate}.fields.phone`)}
          formClassName="MuiGrid-item MuiGrid-grid-xs-4"
        />
        <TextInput
          type="email"
          fullWidth
          source="primary_contact.email"
          variant="outlined"
          validate={[required(), email()]}
          label={translate(`${rootTranslate}.fields.email`)}
          formClassName="MuiGrid-item MuiGrid-grid-xs-4"
        />
        <Heading variant="h6" formClassName="MuiGrid-item MuiGrid-grid-xs-12">
          {translate(`${rootTranslate}.secondary_contact`)}
        </Heading>
        <TextInput
          fullWidth
          source="secondary_contact.name"
          variant="outlined"
          label={translate(`${rootTranslate}.fields.name`)}
          formClassName="MuiGrid-item MuiGrid-grid-xs-4"
        />
        <PhoneInput
          fullWidth
          source="secondary_contact.phone"
          phoneCodeSource="user.country_code"
          variant="outlined"
          label={translate(`${rootTranslate}.fields.phone`)}
          formClassName="MuiGrid-item MuiGrid-grid-xs-4"
        />
        <TextInput
          fullWidth
          source="secondary_contact.email"
          variant="outlined"
          validate={[email()]}
          label={translate(`${rootTranslate}.fields.email`)}
          formClassName="MuiGrid-item MuiGrid-grid-xs-4"
        />
        <Heading variant="h6" formClassName="MuiGrid-item MuiGrid-grid-xs-12">
          {translate(`${rootTranslate}.additional_contact`)}
        </Heading>
        <TextInput
          fullWidth
          source="additional_contact.name"
          variant="outlined"
          label={translate(`${rootTranslate}.fields.name`)}
          formClassName="MuiGrid-item MuiGrid-grid-xs-4"
        />
        <PhoneInput
          fullWidth
          phoneCodeSource="user.country_code"
          source="additional_contact.phone"
          variant="outlined"
          label={translate(`${rootTranslate}.fields.phone`)}
          formClassName="MuiGrid-item MuiGrid-grid-xs-4"
        />
        <TextInput
          fullWidth
          source="additional_contact.email"
          variant="outlined"
          validate={[email()]}
          label={translate(`${rootTranslate}.fields.email`)}
          formClassName="MuiGrid-item MuiGrid-grid-xs-4"
        />
      </FormTab>
      <FormTab
        contentClassName={formTabClasses}
        label={translate(`${rootTranslate}.payment`)}
      >
        <TextInput
          fullWidth
          source="payment_bank"
          variant="outlined"
          validate={[required()]}
          label={translate(`${rootTranslate}.fields.payment_bank`)}
          formClassName="MuiGrid-item MuiGrid-grid-xs-4"
        />
        <TextInput
          fullWidth
          source="payment_account_name"
          variant="outlined"
          validate={[required()]}
          label={translate(`${rootTranslate}.fields.payment_account_name`)}
          formClassName="MuiGrid-item MuiGrid-grid-xs-4"
        />
        <TextInput
          fullWidth
          source="payment_routing_number"
          variant="outlined"
          validate={routingNumberValidation}
          label={translate(`${rootTranslate}.fields.payment_routing_number`)}
          formClassName="MuiGrid-item MuiGrid-grid-xs-4"
        />
        <SelectInput
          fullWidth
          source="payment_account_type"
          defaultValue={Object.values(paymentType)[0].value}
          choices={Object.values(paymentType).map((p) => ({
            id: p.value,
            name: translate(p.text),
          }))}
          variant="outlined"
          validate={[required()]}
          label={translate(`${rootTranslate}.fields.payment_account_type`)}
          formClassName="MuiGrid-item MuiGrid-grid-xs-4"
        />
        <TextInput
          fullWidth
          source="payment_account_number"
          variant="outlined"
          validate={accountNumberValidation}
          label={translate(`${rootTranslate}.fields.payment_account_number`)}
          formClassName="MuiGrid-item MuiGrid-grid-xs-4"
        />
      </FormTab>
    </TabbedForm>
  );
}

export default ProfileRight;
