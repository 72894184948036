const parseBillingPeriods = (billingPeriods) => {
  return billingPeriods.map((billingPeriod) => {
    const { start_date: startDate, end_date: endDate } = billingPeriod;
    const id = `${startDate}--${endDate}`;

    return {
      ...billingPeriod,
      id,
    };
  });
};

export default parseBillingPeriods;
