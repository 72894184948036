export const actions = {
  API_GET_TASKS: 'API_GET_TASKS',
  API_GET_INVENTORY: 'API_GET_INVENTORY',
  API_GET_ALERTS: 'API_GET_ALERTS',
  API_GET_EARNINGS: 'API_GET_EARNINGS',
  API_GET_ON_GOING: 'API_GET_ON_GOING',
  API_GET_REMINDER: 'API_GET_REMINDER',
};

export const rootTranslate = 'resources.dashboard';
