import { createStyles, withStyles } from '@material-ui/core/styles';
import MuiCardHeader from '@material-ui/core/CardHeader';
import MuiCardContent from '@material-ui/core/CardContent';
import MuiCard from '@material-ui/core/Card';

const cardContentStyles = (theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2, 3),
    },
  });

const cardHeaderStyle = (theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2, 3, 0, 3),
    },
    title: {
      fontSize: '1.25rem',
      fontWeight: 700,
    },
  });

const cardStyle = (theme) =>
  createStyles({
    root: {
      borderRadius: theme.shape.borderRadius * 2,
    },
  });

export const CardHeader = withStyles(cardHeaderStyle)(MuiCardHeader);
export const CardContent = withStyles(cardContentStyles)(MuiCardContent);
export const Card = withStyles(cardStyle)(MuiCard);
