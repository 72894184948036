import { Sidebar } from 'react-admin';
import { Box, withStyles, createStyles } from '@material-ui/core';

const style = (theme) =>
  createStyles({
    drawerPaper: {
      [theme.breakpoints.only('xs')]: {
        backgroundColor: '#442C5D',
      },
    },
  });

const CustomSidebar = (props) => (
  <Box component={Sidebar} bgcolor="#442C5D" size={200} {...props} />
);

export default withStyles(style)(CustomSidebar);
