import * as React from 'react';
import { useTranslate } from 'react-admin';
import StatusChip from 'components/StatusChip';
import { Typography } from '@material-ui/core';
import { profileStatus } from 'status';

const StatusField = ({ source, record }) => {
  const trans = useTranslate();

  if (!record) {
    return null;
  }

  const { iLabel, color } =
    record[source] === 'active' ? profileStatus.active : profileStatus.inactive;
  return (
    <StatusChip
      text={
        iLabel &&
        trans(iLabel, {
          smart_count: 1,
        })
      }
      color={color}
    />
  );
};

export const StatusText = ({ source, record }) => {
  const trans = useTranslate();
  if (!record) {
    return null;
  }

  const { iLabel } =
    record[source] === 'active' ? profileStatus.active : profileStatus.inactive;
  return (
    <Typography>
      {iLabel &&
        trans(iLabel, {
          smart_count: 2,
        })}
    </Typography>
  );
};

export default StatusField;
