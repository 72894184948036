import VehicleList from './VehicleList';
import VehicleShow from './VehicleShow';
import VehicleEdit from './VehicleEdit';
import VehicleCreate from './VehicleCreate';
import dataProvider from './dataProvider';

export default {
  list: VehicleList,
  show: VehicleShow,
  edit: VehicleEdit,
  create: VehicleCreate,
  dataProvider,
};
