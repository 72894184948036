import { useMutation } from 'react-admin';

const useGetJob = () => {
  return useMutation({
    type: 'others',
    resource: 'import-vehicles',
    payload: {
      action: 'GET_ACTIVE_JOB',
    },
  });
};

export default useGetJob;
