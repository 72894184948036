import React, { useRef } from 'react';
import { Box } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { useSwipeable } from 'react-swipeable';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';

const datePickerStyles = (theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.common.white,
      paddingTop: theme.spacing(3),
    },
    wrapper: {
      position: 'relative',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
      },
    },
    interactionDisabled: {},

    navBar: {
      '& span': {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        cursor: 'pointer',
        '&:before': {
          content: '""',
          border: `solid ${theme.palette.primary.main}`,
          borderWidth: '0 2px 2px 0',
          display: 'inline-block',
          padding: '4px',
        },
      },
    },
    navButtonNext: {
      right: '2px',
      '&:before': {
        transform: 'rotate(-45deg)',
      },
    },
    navButtonPrev: {
      left: '2px',
      '&:before': {
        transform: 'rotate(135deg)',
      },
    },
    navButtonInteractionDisabled: {},

    months: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    month: {
      width: '47%',
      display: 'block',
    },
    caption: {
      color: theme.palette.common.brandPurple,
      fontWeight: 'bold',
      fontSize: theme.typography.pxToRem(13),
    }, // The caption element, containing the current month's name and year
    weekdays: {}, // Table header displaying the weekdays names
    weekdaysRow: {
      display: 'flex',
    }, // Table row displaying the weekdays names
    weekday: {
      flex: 1,
      fontSize: theme.typography.pxToRem(14),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '&:before': {
        content: '""',
        display: 'block',
        paddingTop: '100%',
      },
    }, // Cell displaying the weekday name
    body: {}, // Table's body with the weeks
    week: {
      display: 'flex',
    }, // Table's row for each week
    day: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      margin: theme.spacing(0.25, 0.25),
      fontSize: theme.typography.pxToRem(14),
      '&:before': {
        content: '""',
        display: 'block',
        paddingTop: '100%',
      },
    }, // The single day cell

    footer: {}, // The calendar footer (only with todayButton prop)
    todayButton: {}, // The today button (only with todayButton prop)

    /* default modifiers */
    today: {
      color: `${theme.palette.common.mapBlue} !important`,
      fontWeight: 'bold',
      position: 'relative',
      '&:after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: '10%',
        width: theme.spacing(0.625),
        height: theme.spacing(0.625),
        backgroundColor: theme.palette.common.mapBlue,
      },
    }, // Added to the day's cell for the current day
    selected: {
      backgroundColor: theme.palette.common.mustard,
      color: theme.palette.common.white,
    }, // Added to the day's cell specified in the "selectedDays" prop
    disabled: {
      cursor: 'not-allowed',
      color: 'rgba(0, 0, 0, 0.5)',
    }, // Added to the day's cell specified in the "disabledDays" prop
    outside: {
      cursor: 'initial',
      opacity: 0,
    }, // Added to the day's cell outside the current month
  });

const BaseDatePicker = React.forwardRef(
  ({ classes, min, max, datePickerProps = {} }, ref) => {
    const { root, ...otherClasses } = classes;
    const _ref = useRef(null);
    const {
      modifiers: initModifiers,
      disabledDays: initDisabledDays = [],
      selectedDays = [],
      ...props
    } = datePickerProps;
    const disabledDays = [{ before: min, after: max }, ...initDisabledDays];
    const calendarRef = ref || _ref;

    const handlers = useSwipeable({
      onSwipedLeft: () => {
        calendarRef?.current?.showNextMonth();
      },
      onSwipedRight: () => {
        calendarRef?.current?.showPreviousMonth();
      },
      delta: 10, // min distance(px) before a swipe starts
      preventDefaultTouchmoveEvent: false, // preventDefault on touchmove, *See Details*
      trackTouch: true, // track touch input
      trackMouse: true, // track mouse input
      rotationAngle: 0, // set a rotation angle
    });

    const modifiers = {
      ...initModifiers,
      selected: selectedDays,
      disabled: disabledDays,
    };

    return (
      <Box className={root}>
        <div {...handlers}>
          <DayPicker
            ref={calendarRef}
            classNames={otherClasses}
            {...props}
            modifiers={modifiers}
            disabledDays={disabledDays}
            selectedDays={selectedDays}
          />
        </div>
      </Box>
    );
  }
);

export default withStyles(datePickerStyles)(BaseDatePicker);
