import React from 'react';
import { TextInput, number } from 'react-admin';
import { trim } from 'lodash';

/**
 * Because NumberInput of react-admin has a issue when typing a non-digit(label overlap input value). So this component is create to resolve that issue.
 * This component wrapper TextInput of react-admin. It handle for input a number. A error appearance 'Must be a number' When typing a non-digit.
 * @props props same as TextInput https://marmelab.com/react-admin/Inputs.html#textinput
 */
function NumberInput(props) {
  const { validate = [], parse } = props;

  return (
    <TextInput
      {...props}
      validate={
        Array.isArray(validate) ? [...validate, number()] : [validate, number()]
      }
      parse={(value) => {
        let formattedValue = trim(value);

        if (
          isNaN(formattedValue) ||
          /^\d+.$/.test(formattedValue) ||
          formattedValue === ''
        ) {
          return formattedValue;
        }

        formattedValue = Number(formattedValue);
        if (typeof parse === 'function') {
          formattedValue = parse(formattedValue);
        }

        return formattedValue;
      }}
    />
  );
}

export default NumberInput;
