export const dashboardSelectors = {
  getDate: ({ dashboard = {} }) => {
    const {
      tasks: { fromDate, toDate },
    } = dashboard;
    return {
      fromDate,
      toDate,
    };
  },
  getReminder: ({ dashboard = {} }) => {
    const { reminder = {} } = dashboard;
    return reminder;
  },
};
