import React, { useMemo } from 'react';
import parsePhoneNumber, {
  AsYouType,
  isValidPhoneNumber,
} from 'libphonenumber-js';
import { TextInput, useTranslate } from 'react-admin';
import { isEmpty, get } from 'lodash';
import {
  Select,
  MenuItem,
  InputAdornment,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { useField } from 'react-final-form';
import objectCountries, { countries, dialCodes } from './countries';

const checkValidPhoneNumber = (message, phoneCodeSource) => (value, values) => {
  const phoneCode = get(values, phoneCodeSource);
  return isValidPhoneNumber(`${phoneCode}${value}`) || !value
    ? undefined
    : message;
};

export const formatValue = (country = '', phoneNumber = '') => {
  const phoneCode = objectCountries[country]?.dial_code;
  const newPhoneNumber = phoneNumber?.replace(phoneCode, '') || '';

  if (newPhoneNumber?.length <= 3) {
    return newPhoneNumber;
  }

  const result = new AsYouType(country).input(newPhoneNumber);
  return result;
};

const useSelectStyle = makeStyles(() => ({
  select: {
    color: '#000',
    '&:focus': {
      background: 'none',
    },
  },
}));

export function PhoneNumberField({
  record,
  source,
  phoneCodeSource,
  defaultPhoneCode = '+1', // default is US
  emptyText = '',
}) {
  const value = get(record, source);
  const phoneCode = get(record, phoneCodeSource, defaultPhoneCode);
  const country = useMemo(() => {
    const c = countries.find((item) => {
      const { dial_code: dialCode } = item;
      return dialCode === phoneCode;
    });

    return c ? c.code : '';
  }, [phoneCode]);

  if (!value) {
    return emptyText ? (
      <Typography variant="body2">{emptyText}</Typography>
    ) : null;
  }

  return (
    <Typography component="span" variant="body2">
      {`${phoneCode} ${formatValue(country, value)}`}
    </Typography>
  );
}

function PhoneNumberInput({
  disableSelectCode = false,
  validate,
  phoneCodeSource,
  record,
  defaultPhoneCode = '+1', // default is US
  ...props
}) {
  const translate = useTranslate();

  const { input: phoneCodeInput } = useField(phoneCodeSource, {
    initialValue: get(record, phoneCodeSource, defaultPhoneCode),
  });
  const { onChange, value: code } = phoneCodeInput;

  const country = useMemo(() => {
    const c = countries.find((item) => {
      const { dial_code: dialCode } = item;
      return dialCode === code;
    });

    return c ? c.code : '';
  }, [code]);

  const selectClasses = useSelectStyle();
  const validates = useMemo(() => {
    if (Array.isArray(validate)) {
      return [
        ...validate,
        checkValidPhoneNumber(
          translate('messages__unValid_phone_number'),
          phoneCodeSource
        ),
      ];
    }

    return isEmpty(validate)
      ? [
          checkValidPhoneNumber(
            translate('messages__unValid_phone_number'),
            phoneCodeSource
          ),
        ]
      : [
          validate,
          checkValidPhoneNumber(
            translate('messages__unValid_phone_number'),
            phoneCodeSource
          ),
        ];
  }, [validate, translate, phoneCodeSource]);

  const countryCode = useMemo(() => {
    return (
      <Select
        value={code}
        classes={selectClasses}
        onChange={onChange}
        disabled={disableSelectCode}
        disableUnderline
      >
        {dialCodes.map((c) => {
          return <MenuItem key={c} value={c}>{`${c}`}</MenuItem>;
        })}
      </Select>
    );
  }, [code, selectClasses, disableSelectCode, onChange]);

  const handleParse = (value) => {
    const { nationalNumber } = parsePhoneNumber(`${code}${value}`) || {
      nationalNumber: value,
    };

    return nationalNumber;
  };

  const handleFormat = (value) => {
    const result = formatValue(country, value);
    return result;
  };

  return (
    <TextInput
      {...props}
      record={record}
      parse={handleParse}
      format={handleFormat}
      validate={validates}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">{countryCode}</InputAdornment>
        ),
      }}
    />
  );
}

export default PhoneNumberInput;
