import { forwardRef, useState, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { MenuItemLink, useTranslate, Confirm } from 'react-admin';
import ExitIcon from '@material-ui/icons/PowerSettingsNew';
import ActionCheck from '@material-ui/icons/CheckCircle';
import AlertError from '@material-ui/icons/ErrorOutline';

export default forwardRef(() => {
  const { logout } = useAuth0();
  const [openConfirm, setOpenConfirm] = useState(false);
  const translate = useTranslate();

  const handleClick = useCallback(() => {
    setOpenConfirm(true);
  }, []);

  const handleConfirm = useCallback(() => {
    logout({ returnTo: window.location.origin });
  }, [logout]);

  const handleClose = useCallback(() => {
    setOpenConfirm(false);
  }, []);

  return (
    <>
      <MenuItemLink
        component={null}
        primaryText={translate('ra.auth.logout')}
        leftIcon={<ExitIcon />}
        onClick={handleClick}
      />
      <Confirm
        title=""
        isOpen={openConfirm}
        content={translate('messages__logout')}
        confirmColor="primary"
        ConfirmIcon={ActionCheck}
        CancelIcon={AlertError}
        onConfirm={handleConfirm}
        onClose={handleClose}
      />
    </>
  );
});
