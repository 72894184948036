import { useTranslate } from 'react-admin';
import { get } from 'lodash';
import { rootTranslate as dashboardRootTranslate } from 'features/dashboard/const';
import TitleWithBackButton from 'components/TitleWithBackButton';
import { formatDateTime } from 'utils/format';
import { rootTranslate } from '../const';

const VehicleListTitle = ({ type = '', fromDate = null, toDate = null }) => {
  const t = useTranslate();
  const { getDisplayDate: getDisplayFromDate } = formatDateTime(fromDate);
  const { getDisplayDate: getDisplayToDate } = formatDateTime(toDate);
  let fromDateStr = getDisplayFromDate();
  let toDateStr = getDisplayToDate();
  let rangeDate =
    fromDateStr && toDateStr ? `(${fromDateStr} - ${toDateStr})` : '';
  const titles = {
    arriving: t(`${dashboardRootTranslate}.label__arrival`),
    returning: t(`${dashboardRootTranslate}.label__returning`),
    'no-show': t(`${dashboardRootTranslate}.label__no_show`),
    maintenance: t(`${dashboardRootTranslate}.label__maintenance`),
    'in-trip': t(`${dashboardRootTranslate}.label__in_trip`),
    delay: t(`${dashboardRootTranslate}.label__delay`),
  };
  const title = `${get(titles, type, t(`${rootTranslate}.name`))} ${rangeDate}`;

  return <TitleWithBackButton basePath="/">{title}</TitleWithBackButton>;
};

export default VehicleListTitle;
