import { useEffect, useState, useCallback } from 'react';
import _ from 'lodash';
import { Box, LinearProgress } from '@material-ui/core';
import { ImageInput, ImageField, useMutation } from 'react-admin';
import { useForm } from 'react-final-form';

const MultiImageInput = (props) => {
  const {
    sourceDelete = 'deleted_images',
    sourceUpload = 'uploaded_images',
    ...rest
  } = props;
  const { source } = rest;
  const form = useForm();
  const [deletedImages, setDeletedImages] = useState();
  const [uploadedImages, setUploadedImages] = useState();
  const [mutate, { loading: uploading }] = useMutation();
  const onDropAccepted = useCallback(
    (imageFiles) => {
      mutate(
        {
          type: 'uploadFiles',
          resource: 'files',
          payload: {
            data: imageFiles,
          },
        },
        {
          onSuccess: ({ data }) => {
            const imagePaths = _.map(data, ({ path }) => path);
            setUploadedImages((old = []) => [...old, ...imagePaths]);
          },
        }
      );
    },
    [mutate]
  );
  const onRemove = useCallback((image) => {
    if (image.id) {
      setDeletedImages((old = []) => [...old, image.id]);
    }
  }, []);
  useEffect(() => {
    form.change(sourceUpload, uploadedImages);
  }, [form, sourceUpload, uploadedImages]);

  useEffect(() => {
    form.change(sourceDelete, deletedImages);
  }, [form, sourceDelete, deletedImages]);

  return (
    <Box position="relative">
      <ImageInput
        {...rest}
        source={source}
        accept="image/*"
        multiple
        options={{
          onDropAccepted,
          onRemove,
        }}
      >
        <ImageField source="url" />
      </ImageInput>
      {uploading && <LinearProgress color="primary" />}
    </Box>
  );
};

export default MultiImageInput;
