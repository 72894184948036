import * as React from 'react';
import { forwardRef } from 'react';
import { useMediaQuery, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, UserMenu, MenuItemLink, useTranslate } from 'react-admin';
import SettingsIcon from '@material-ui/icons/Settings';
import PersonIcon from '@material-ui/icons/Person';

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  spacer: {
    flex: 1,
  },
});

const ConfigurationMenu = forwardRef((props, ref) => {
  const translate = useTranslate();
  return (
    <MenuItemLink
      ref={ref}
      to="/configuration"
      primaryText={translate('pos.configuration')}
      leftIcon={<SettingsIcon />}
      onClick={props.onClick}
      sidebarIsOpen
    />
  );
});

const ProfileMenu = forwardRef((props, ref) => {
  const translate = useTranslate();
  return (
    <MenuItemLink
      ref={ref}
      to="/profile/1"
      primaryText={translate('resources.profile.name')}
      leftIcon={<PersonIcon />}
      onClick={props.onClick}
    />
  );
});

const CustomUserMenu = (props) => (
  <UserMenu {...props}>
    <ConfigurationMenu />
    <ProfileMenu />
  </UserMenu>
);

const CustomAppBar = (props) => {
  const classes = useStyles();
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  return (
    <AppBar
      position="static"
      {...props}
      elevation={1}
      userMenu={<CustomUserMenu />}
    >
      {isXSmall ? (
        <Typography
          variant="h6"
          color="inherit"
          className={classes.title}
          id="react-admin-title"
        />
      ) : (
        <span className={classes.spacer}>&nbsp;</span>
      )}
    </AppBar>
  );
};

export default CustomAppBar;
