import { VEHICLE_ACTION } from './const';

const dataProvider = {
  getList: ({ params }) => {
    const { filter = {}, ...otherParams } = params;
    const { type = '', search = '', ...otherFilter } = filter;
    let extraParams = {};

    if (type) {
      extraParams = { ...extraParams, type };
    }

    if (search) {
      extraParams = { ...extraParams, search };
    }

    return {
      extraParams,
      params: {
        ...otherParams,
        filter: { ...otherFilter },
      },
      parser: (json) => {
        const { data, meta } = json;
        const { pagination } = meta || {};
        const { total } = pagination || 0;

        return {
          data,
          total,
        };
      },
    };
  },
  others: ({ params }) => {
    const { action, id, data } = params;

    switch (action) {
      case VEHICLE_ACTION.STATUS_CHANGE:
        return {
          url: `/vehicles/${id}/change-status`,
          method: 'PUT',
          body: JSON.stringify(data),
          parser: (json) => json,
        };
      case VEHICLE_ACTION.CHECK_DEACTIVATABLE:
        return {
          url: `/vehicles/${id}/check-deactivatable`,
          method: 'GET',
          parser: (json) => json,
        };
      case VEHICLE_ACTION.UPDATE_RESERVED:
        return {
          url: `/vehicles/${id}/update-reserved`,
          method: 'PUT',
          body: JSON.stringify(data),
          parser: (json) => json,
        };
      case VEHICLE_ACTION.GET_VEHICLE_BY_VIN:
        return {
          url: `/vehicles-data/get-data-by-vin/${id}`,
          method: 'GET',
          parser: (json) => json,
        };
      case VEHICLE_ACTION.GET_OCCUPATIONS:
        return {
          url: `/vehicles/${id}/occupations?from_date=${data?.from_date}`,
          method: 'GET',
          parser: (json) => json,
        };
      case VEHICLE_ACTION.ADD_OCCUPATIONS:
        return {
          url: `/vehicles/${id}/occupations`,
          method: 'POST',
          body: JSON.stringify(data),
          parser: (json) => json,
        };
      case VEHICLE_ACTION.DELETE_OCCUPATIONS:
        return {
          url: `/vehicles/${id}/occupations`,
          method: 'DELETE',
          body: JSON.stringify(data),
          parser: (json) => json,
        };
      case VEHICLE_ACTION.GET_AVAILABLE_HUBS:
        return {
          url: `/vehicles/${id}/available-hubs?${data}`,
          method: 'GET',
          parser: (json) => json,
        };
      default:
        return {};
    }
  },
};

export default dataProvider;
