import { green, red } from './statusColors';

const rootTranslate = 'resources.profile';
export const profileStatus = {
  active: {
    color: green,
    iLabel: `${rootTranslate}.messages__status_available`,
  },
  inactive: {
    color: red,
    iLabel: `${rootTranslate}.messages__status_unavailable`,
  },
};
export default profileStatus;
