import { actions } from './const';
import { formatDateTime } from 'utils/format';

const dataProvider = {
  others: ({ params }) => {
    const { action = '', data = {} } = params;
    const { getReturnDate } = formatDateTime(new Date());
    switch (action) {
      case actions.API_GET_TASKS: {
        const { from, to } = data;
        return {
          url: `/statistic/tasks?from_date=${from}&to_date=${to}`,
          method: 'GET',
          parser: (json) => json,
        };
      }
      case actions.API_GET_INVENTORY: {
        return {
          url: `/statistic/inventory?date=${getReturnDate()}`,
          method: 'GET',
          parser: (json) => json,
        };
      }
      case actions.API_GET_ALERTS: {
        return {
          url: `/statistic/alerts?date=${getReturnDate()}`,
          method: 'GET',
          parser: (json) => json,
        };
      }
      case actions.API_GET_ON_GOING: {
        return {
          url: `/statistic/ongoing-event?date=${getReturnDate()}`,
          method: 'GET',
          parser: (json) => json,
        };
      }
      case actions.API_GET_EARNINGS: {
        return {
          url: '/statistic/earning',
          method: 'GET',
          parser: (json) => json,
        };
      }
      case actions.API_GET_REMINDER: {
        return {
          url: '/dashboard-message',
          method: 'GET',
          parser: (json) => json,
        };
      }

      default:
        return {};
    }
  },
};

export default dataProvider;
