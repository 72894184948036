import React from 'react';
import { useTranslate, useQuery } from 'react-admin';
import { Grid, Box, SvgIcon } from '@material-ui/core';
import DirectionsCarOutlinedIcon from '@material-ui/icons/DirectionsCarOutlined';
import { ReactComponent as CarLockIcon } from 'assets/images/car-lock.svg';
import { ReactComponent as CalendarLock } from 'assets/images/calendar-lock.svg';
import { CardHeader, CardContent, Card } from './Card';
import TaskItem from './TaskItem';
import { rootTranslate, actions } from '../const';

function Inventory() {
  const t = useTranslate();

  const { data = {} } = useQuery({
    type: 'others',
    resource: 'dashboard',
    payload: { action: actions.API_GET_INVENTORY },
  });
  const { listed = 0, unlisted = 0, reserved = 0, booked = 0 } = data;

  return (
    <Card>
      <CardHeader title={t(`${rootTranslate}.heading__inventory`)} />
      <CardContent>
        <Box
          pt={{
            xs: 2,
            md: 3,
          }}
        />
        <Grid container justify="space-between" spacing={3}>
          <Grid item xs={12}>
            <TaskItem
              icon={<DirectionsCarOutlinedIcon fontSize="inherit" />}
              number={listed}
              label={t(`${rootTranslate}.label__listed_vehicle`)}
              iconBgColor="common.mapBlue"
              seeAllUrl="/vehicle-listed"
            />
          </Grid>
          <Grid item xs={12}>
            <TaskItem
              icon={<DirectionsCarOutlinedIcon fontSize="inherit" />}
              number={unlisted}
              label={t(`${rootTranslate}.label__unlisted_vehicle`)}
              iconBgColor="common.statusYellow"
              seeAllUrl="/vehicle-unlisted"
            />
          </Grid>
          <Grid item xs={12}>
            <TaskItem
              icon={
                <SvgIcon
                  viewBox="0 0 42 42"
                  component={CarLockIcon}
                  fontSize="inherit"
                />
              }
              number={reserved}
              label={t(`${rootTranslate}.label__reserved_vehicle`)}
              iconBgColor="common.brandPurple"
              seeAllUrl="/vehicle-reserved"
            />
          </Grid>
          <Grid item xs={12}>
            <TaskItem
              icon={
                <SvgIcon
                  viewBox="0 0 42 42"
                  component={CalendarLock}
                  fontSize="inherit"
                />
              }
              number={booked}
              label={t(`${rootTranslate}.label__booked_vehicle`)}
              iconBgColor="common.pink"
              seeAllUrl="/vehicle-booked"
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default Inventory;
