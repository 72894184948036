import React from 'react';
import { Box, Typography, Grid, makeStyles } from '@material-ui/core';
import { useTranslate, useQuery } from 'react-admin';
import { format } from 'date-fns';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import {
  formatCurrency,
  formatShortCurrencyWithOutTrailingZero,
} from 'utils/format';
import { CardHeader, CardContent, Card } from './Card';
import BusinessMetricItem from './BusinessMetricItem';
import { rootTranslate, actions } from '../const';

const CustomTooltip = ({ active, payload = {}, ...other }) => {
  if (active && payload && payload.length) {
    const { payload: data = {} } = payload[0];
    const { amount = 0, longLabel } = data;
    return (
      <Box boxShadow={6} p={2} bgcolor="common.white">
        <Typography color="textSecondary" variant="body2">
          {longLabel}
        </Typography>
        <Box
          component={Typography}
          fontWeight="fontWeightBold"
          variant="body"
          fontSize={22}
          color="common.lightBlack"
        >
          {formatCurrency(amount)}
        </Box>
      </Box>
    );
  }

  return null;
};

function transformData(data = {}) {
  const { months = [], total_this_year = 0, total = 0 } = data;
  const newMonths = months.map((m, index) => {
    const d = new Date();
    d.setMonth(index);
    return {
      ...m,
      shortLabel: format(d, 'MMM'),
      longLabel: format(d, 'MMMM'),
      q: index % 3 === 0 && index < 11 ? `Q${index / 3 + 1}` : null,
    };
  });
  return {
    total_this_year,
    total,
    months: [...newMonths, { number: 12, amount: null, q: null }],
  };
}

const useStyles = makeStyles((theme) => {
  return {
    chart: {
      fontSize: theme.typography.pxToRem(11),
      '& .yAxis .recharts-cartesian-axis-tick-value': {
        color: theme.palette.common.aluminium,
      },
      '& .xAxis .recharts-cartesian-axis-tick-value': {
        color: theme.palette.common.midGrey,
      },
    },
  };
});

function Earnings(props) {
  const t = useTranslate();
  const classes = useStyles();
  const { data = {} } = useQuery({
    type: 'others',
    resource: 'dashboard',
    payload: { action: actions.API_GET_EARNINGS },
  });
  const { months = [], total_this_year = 0, total = 0 } = transformData(data);
  const d = new Date();

  return (
    <Card>
      <CardHeader title={t(`${rootTranslate}.heading__earnings`)} />
      <CardContent>
        <Box mt={2} mb={1} fontSize={16}>
          <strong>{t(`${rootTranslate}.label__earning_of`)}</strong>{' '}
          <Box component="i" color="primary.main">
            {format(d, 'yyyy')}
          </Box>
        </Box>
        <Box paddingTop="33%" position="relative">
          <Box
            position="absolute"
            left={0}
            top={0}
            width="100%"
            height="100%"
            className={classes.chart}
          >
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={months} margin={{ left: -13 }}>
                <CartesianGrid strokeDasharray="4" />
                <XAxis dataKey="shortLabel" dy={10} height={40} />
                <XAxis
                  axisLine={false}
                  xAxisId="q"
                  orientation="top"
                  dataKey="q"
                  dy={-2}
                  height={35}
                />

                <YAxis
                  axisLine={false}
                  tickFormatter={(value) => {
                    return formatShortCurrencyWithOutTrailingZero(value);
                  }}
                  strokeDasharray="4"
                />
                <Tooltip
                  cursor={{
                    stroke: '#6055DF',
                    strokeWidth: 1,
                    strokeDasharray: 4,
                  }}
                  isAnimationActive={false}
                  content={<CustomTooltip />}
                />

                <Line
                  type="linear"
                  dataKey="amount"
                  stroke="#6055DF"
                  connectNulls
                  strokeWidth={3}
                  dot={{
                    stroke: '#6055DF',
                    strokeWidth: 0,
                    r: 5,
                    fill: '#6055DF',
                  }}
                  activeDot={{
                    stroke: '#6055DF',
                    strokeWidth: 0,
                    r: 5,
                  }}
                />
              </LineChart>
            </ResponsiveContainer>
          </Box>
        </Box>
        <Box mt={4}>
          <Box pb={1} fontSize={16}>
            <strong>{t(`${rootTranslate}.label__business_metrics`)}</strong>
          </Box>
          <Box component={Grid} maxWidth={480} container spacing={2}>
            <Grid item sm={6}>
              <BusinessMetricItem
                iconSolid
                title={t(`${rootTranslate}.label__this_year`)}
                amount={formatCurrency(total_this_year)}
              />
            </Grid>
            <Grid item sm={6}>
              <BusinessMetricItem
                title={t(`${rootTranslate}.label__all_time`)}
                amount={formatCurrency(total)}
              />
            </Grid>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

export default Earnings;
