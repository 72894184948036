export const VEHICLE_ACTION = {
  CHECK_DEACTIVATABLE: 'CHECK_DEACTIVATABLE',
  STATUS_CHANGE: 'STATUS_CHANGE',
  UPDATE_RESERVED: 'UPDATE_RESERVED',
  GET_VEHICLE_BY_VIN: 'GET_VEHICLE_BY_VIN',
  GET_OCCUPATIONS: 'GET_OCCUPATIONS',
  GET_AVAILABLE_HUBS: 'GET_AVAILABLE_HUBS',
  ADD_OCCUPATIONS: 'ADD_OCCUPATIONS',
  DELETE_OCCUPATIONS: 'DELETE_OCCUPATIONS',
};

export const VEHICLE_STATUS_OP = {
  ACTIVATE: 'activate',
  DEACTIVATE: 'deactivate',
};

export const VEHICLE_STATUS = {
  AVAILABLE: 1,
  UNAVAILABLE: 2,
  PENDING: 3,
};

export const rootTranslate = 'resources.vehicles';
