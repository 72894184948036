import { get, map } from 'lodash';
import { stringify } from 'qs';
import request, { parseApi } from './request';

const getApiUrl = (resource) => {
  return `/${resource}`;
};

const defaultParserList = {
  getList: parseApi,
  getOne: (json) => json,
  update: (json) => {
    const { data } = json;
    const id = data && data.id ? data.id : 'unknown';
    return {
      data: {
        id,
      },
      ...json,
    };
  },
  getManyReference: parseApi,
  updateMany: (json) => ({ data: json }),
  create: (json) => json,
  deleteMany: (json) => ({ data: json }),
};

const transformDataProvider = (
  type,
  { resource, params, extendedProviders }
) => {
  const provider = get(extendedProviders, resource, undefined);
  const defaultUrl = getApiUrl(resource);
  const defaultParser = get(defaultParserList, type, undefined);

  const middleware = get(provider, type, undefined);
  const override =
    typeof middleware === 'function' ? middleware({ params }) : {};
  const url = get(override, 'url', defaultUrl);
  const newParams = get(override, 'params', params);
  const extraParams = get(override, 'extraParams', {});
  const parser = get(override, 'parser', defaultParser);

  return {
    url,
    parser,
    params: {
      ...newParams,
      extraParams,
    },
  };
};

const actionList = [
  {
    key: 'uploadFiles',
    data: {
      dataBuilder: ({ resource, params }) => {
        const url = getApiUrl(resource);
        const body = new FormData();
        (params.data || []).forEach((file) => {
          body.append('files[]', file);
        });

        return {
          url,
          body,
          method: 'POST',
          isFile: true,
        };
      },
      onSuccess: () => (json) => ({
        ...json,
        data: map(json.data, (datum) => ({
          ...datum,
          id: datum.url,
        })),
      }),
    },
  },
  {
    key: 'getList',
    data: {
      dataBuilder: (props) => {
        const { url, params } = transformDataProvider('getList', props);
        const { filter, pagination = {}, sort = {}, extraParams } = params;
        const { page, perPage: limit } = pagination;
        const { field, order = '' } = sort;

        const query = {
          page,
          limit,
          sort_by: field,
          sort_order: order.toLowerCase(),
          filter,
          ...extraParams,
        };

        if (Object.keys(query).length) {
          return {
            url: `${url}?${stringify(query)}`,
          };
        }

        return {
          url,
        };
      },
      onSuccess: (props) => (json) => {
        const { parser } = transformDataProvider('getList', props);
        return parser(json);
      },
    },
  },
  {
    key: 'getOne',
    data: {
      dataBuilder: (props) => {
        const { url, params } = transformDataProvider('getOne', props);
        return {
          url: `${url}/${params?.id}`,
        };
      },
      onSuccess: (props) => (json) => {
        const { parser } = transformDataProvider('getOne', props);
        return parser(json);
      },
    },
  },
  {
    key: 'getMany',
    data: {
      dataBuilder: (props) => {
        const { url, params } = transformDataProvider('getMany', props);
        const query = params
          ? stringify(params, { arrayFormat: 'comma', encode: false })
          : '';

        return {
          url: `${url}?${query}`,
        };
      },
    },
  },
  {
    key: 'getManyReference',
    data: {
      dataBuilder: (props) => {
        const { url, params } = transformDataProvider(
          'getManyReference',
          props
        );
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
          sort: JSON.stringify([field, order]),
          range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
          page,
          limit: perPage,
          filter: JSON.stringify({
            ...params.filter,
            [params.target]: params.id,
          }),
        };

        return {
          url: `${url}/${params.id}?${stringify(query)}`,
        };
      },
    },
  },
  {
    key: 'update',
    data: {
      dataBuilder: (props) => {
        const { resource } = props;
        const { url, params } = transformDataProvider('update', props);

        const options = {
          method: 'PUT',
          body: JSON.stringify(params.data),
        };

        const formData = new FormData();
        if (resource === 'features') {
          formData.append('description', params.data.description);
          if (params.data.icon.rawFile) {
            formData.append('icon', params.data.icon.rawFile);
          }
          formData.append('title', params.data.title);
          options.body = formData;
          options.method = 'POST';
        }

        return {
          url: `${url}/${params.id}`,
          method: options.method,
          body: options.body,
        };
      },
      onSuccess: (props) => (json) => {
        const { parser } = transformDataProvider('update', props);
        return parser(json);
      },
    },
  },
  {
    key: 'updateMany',
    data: {
      dataBuilder: (props) => {
        const { url, params } = transformDataProvider('updateMany', props);
        const query = {
          filter: JSON.stringify({ id: params.ids }),
        };

        return {
          url: `${url}?${stringify(query)}`,
          method: 'PUT',
          body: JSON.stringify(params.data),
        };
      },
      onSuccess: (props) => (json) => {
        const { parser } = transformDataProvider('updateMany', props);
        return parser(json);
      },
    },
  },
  {
    key: 'create',
    data: {
      dataBuilder: (props) => {
        const { resource } = props;
        const { url, params } = transformDataProvider('create', props);

        const options = {
          method: 'POST',
          body: JSON.stringify(params.data),
        };

        const formData = new FormData();
        if (resource === 'features') {
          formData.append('description', params.data.description);
          formData.append('icon', params.data.icon.rawFile);
          formData.append('title', params.data.title);
          options.body = formData;
        }

        return {
          url,
          method: 'POST',
          body: options.body,
        };
      },
      onSuccess: (props) => (json) => {
        const { parser } = transformDataProvider('create', props);
        return parser(json);
      },
    },
  },
  {
    key: 'delete',
    data: {
      dataBuilder: (props) => {
        const { url, params } = transformDataProvider('delete', props);

        return {
          url: `${url}/${params.id}`,
          method: 'DELETE',
        };
      },
      onSuccess: ({ params }) => () => ({
        data: {
          id: params.id,
        },
      }),
    },
  },
  {
    key: 'deleteMany',
    data: {
      dataBuilder: (props) => {
        const { url, params } = transformDataProvider('deleteMany', props);
        const query = {
          filter: JSON.stringify({ id: params.ids }),
        };

        return {
          url: `${url}?${stringify(query)}`,
          method: 'DELETE',
          body: JSON.stringify(params.data),
        };
      },
      onSuccess: (props) => (json) => {
        const { parser } = transformDataProvider('deleteMany', props);

        return parser(json);
      },
    },
  },
];

const dataProvider = ({ getAccessTokenSilently, extendedProviders = {} }) => {
  const others = (resource, params) => {
    if (extendedProviders[resource] && extendedProviders[resource].others) {
      const { url, method, body, parser: onSuccess } = extendedProviders[
        resource
      ].others({
        params,
      });

      return request({
        url,
        getAccessTokenSilently,
        method,
        body,
        onSuccess,
      });
    }
    throw new Error(
      `dataProvider.others hasn't been extended to handle the resource "${resource}"`
    );
  };

  return actionList.reduce(
    (result, actionObj) => {
      const { key, data } = actionObj;
      const handler = async (resource, params) => {
        const { dataBuilder, onSuccess } = data;
        const requestData = dataBuilder({
          resource,
          params,
          extendedProviders,
          getAccessTokenSilently,
        });
        /**
         * url,
          isFile,
          method = 'GET',
          body,
         */

        return request({
          ...requestData,
          getAccessTokenSilently,
          onSuccess: onSuccess
            ? onSuccess({ extendedProviders, resource, params })
            : undefined,
        });
      };

      return {
        ...result,
        [key]: handler,
      };
    },
    { others }
  );
};

export default dataProvider;
