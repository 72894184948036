import { purple, green, red } from './statusColors';

export const INTRIP = 'in-trip';
export const BOOKED = 'booked';
export const COMPLETED = 'completed';
export const CANCELLED = 'cancelled';

export const bookingStatus = {
  [INTRIP]: {
    color: purple,
    iLabel: 'resources.bookings.constants__status_in_trip',
  },
  [BOOKED]: {
    color: purple,
    iLabel: 'resources.bookings.constants__status_booked',
  },
  [COMPLETED]: {
    color: green,
    iLabel: 'resources.bookings.constants__status_complete',
  },
  [CANCELLED]: {
    color: red,
    iLabel: 'resources.bookings.constants__status_cancelled',
  },
};

export default bookingStatus;
