import parseBillingPeriods from './parser';

const dataProvider = {
  getList: ({ params }) => {
    const url = `/invoices-weekly`;

    return {
      url,
      parser: (json) => {
        const { data, meta } = json;
        const { pagination } = meta || {};
        const { total } = pagination || 0;

        return {
          data: parseBillingPeriods(data),
          total,
        };
      },
    };
  },
};

export default dataProvider;
