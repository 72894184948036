import React from 'react';
import _ from 'lodash';
import { Filter } from 'react-admin';
import { vehicleStatus } from 'status';
import { useTranslate } from 'react-admin';
import { SelectInput, SearchInput } from 'components/OutlinedInputs';

const statusOptions = _.map(_.entries(vehicleStatus), ([id, { iLabel }]) => ({
  id,
  name: iLabel,
}));

const reservedOptions = [
  {
    id: 1,
    name: 'reserved',
  },
];

const ListFilters = ({
  showStatusFilter = true,
  showReservedFilter = true,
  showSearch = true,
  ...props
}) => {
  const t = useTranslate();
  return (
    <Filter {...props}>
      {showStatusFilter && (
        <SelectInput
          optionText="name"
          source="status"
          choices={[...statusOptions, { id: 'all', name: t('common__all') }]}
          alwaysOn
          allowEmpty={false}
          resettable
        />
      )}
      {showReservedFilter && (
        <SelectInput
          optionText="name"
          source="reserved"
          choices={reservedOptions}
          alwaysOn
          allowEmpty={false}
          resettable
        />
      )}
      {showSearch && (
        <SearchInput source="search" alwaysOn allowEmpty={false} resettable />
      )}
    </Filter>
  );
};

export default ListFilters;
