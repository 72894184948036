import { useCallback } from 'react';
import { FileField, FileInput, useTranslate } from 'react-admin';
import { Form } from 'react-final-form';
import { Box } from '@material-ui/core';
import { rootTranslate } from '../const';
import Typography from 'components/CustomTypography';

/**
 * Uload csv file form
 * @param  {[boolean]} isLoading     [Master loading for whole page]
 * @param  {[function]} onUpload     [Upload file handler]
 */
const UploadFormCard = (props) => {
  const translate = useTranslate();
  const { isLoading, onUpload } = props;

  const onDropAccepted = useCallback(
    (files) => {
      const file = files[0];
      onUpload({
        type: 'others',
        resource: 'import-vehicles',
        payload: {
          action: 'UPLOAD',
          data: file,
        },
      });
    },
    [onUpload]
  );

  const renderUploadForm = () => {
    return (
      <FileInput
        source="files"
        label=""
        accept=".csv"
        style={{ display: 'none' }}
        options={{ onDropAccepted }}
        disabled={isLoading}
      >
        <FileField source="src" title="title" />
      </FileInput>
    );
  };

  return (
    <Box>
      <Box pb={3}>{translate(`${rootTranslate}.messages_import`)}</Box>
      <Form onSubmit={() => {}} render={renderUploadForm} />
    </Box>
  );
};

export default UploadFormCard;
