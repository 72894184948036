import React, { useState, useCallback, useMemo, useRef } from 'react';
import {
  Grid,
  Box,
  IconButton,
  Button,
  FormHelperText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { DateUtils } from 'react-day-picker';
import { formatDateTime } from 'utils/format';
import { useTranslate } from 'react-admin';
import BaseDatePicker from 'components/DatePicker/BaseDatePicker';
import { rootTranslate } from '../../const';
import Indicator, { types } from './Indicator';

const useDatePickerStyles = makeStyles((theme) => {
  return {
    day: {
      '&.bookedDay': {
        color: `${theme.palette.common.pink} !important`,
        fontWeight: 'bold',
      },
    },
  };
});

const useStyles = makeStyles((theme) => {
  return {
    root: {
      backgroundColor: theme.palette.common.brandPurple,
    },
    right: {
      padding: theme.spacing(2, 2, 2, 2),
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(2, 4, 2, 4),
      },
    },
    indicators: {
      margin: theme.spacing(3, 0, 3, 2),
    },
    note: {
      margin: theme.spacing(0, 0, 3, 4),
      whiteSpace: 'pre-line',
    },
    unavailableDays: {
      position: 'relative',
      height: '100%',
    },
    unavailableDaysHead: {
      height: '88px',
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.common.white,
      fontSize: theme.typography.pxToRem(13),
      fontWeight: 'bold',
      padding: theme.spacing(0, 1),
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(0, 4),
      },
    },
    unavailableDaysItems: {
      position: 'absolute',
      top: '88px',
      bottom: '10px',
      left: 0,
      right: 0,
      overflow: 'auto',
    },
    unavailableDaysItem: {
      fontSize: theme.typography.pxToRem(14),
      color: theme.palette.common.white,
      padding: theme.spacing(1, 1),
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        '& button': {
          display: 'inline-block',
        },
      },
      '& button': {
        display: 'none',
      },
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(1, 4),
      },
    },
    unavailableDaysItemBtn: {
      padding: 0,
      fontSize: theme.typography.pxToRem(14),
      marginLeft: theme.spacing(2),
    },
    actions: {
      textAlign: 'right',
      '& > button': {
        marginLeft: theme.spacing(1),
        height: '36px',
      },
    },
  };
});

function MarkedAsUnavailableDate({
  onConfirm,
  onCancel,
  bookedDays = [],
  inTripDays = [],
  unavailableDays = [],
  isLoading = false,
}) {
  const calendarRef = useRef(null);
  const t = useTranslate();
  const datePickerClasses = useDatePickerStyles();
  const classes = useStyles();
  const [days, setDays] = useState(unavailableDays);

  const bookedDaysKey = useMemo(() => {
    const keys = {};
    if (bookedDays.length > 0) {
      for (let index = 0; index < bookedDays.length; index++) {
        const key = formatDateTime(bookedDays[index]).getReturnDate();
        if (!keys[key]) {
          keys[key] = true;
        }
      }
    }
    return keys;
  }, [bookedDays]);

  // check if UnavailableDate overlap booked days
  const haveOverlapUnavailableDate = useMemo(() => {
    let isExist = false;
    if (days.length > 0) {
      for (let index = 0; index < days.length; index++) {
        const key = formatDateTime(days[index]).getReturnDate();
        if (!!bookedDaysKey[key]) {
          isExist = true;
          break;
        }
      }
    }
    return isExist;
  }, [bookedDaysKey, days]);

  const handleDayClick = useCallback(
    (day, values) => {
      const { selected, disabled } = values;
      const selectedDays = [...days];
      if (!disabled) {
        if (selected) {
          const selectedIndex = selectedDays.findIndex((selectedDay) =>
            DateUtils.isSameDay(selectedDay, day)
          );
          selectedDays.splice(selectedIndex, 1);
        } else {
          selectedDays.push(day);
        }
        setDays(selectedDays);
      }
    },
    [days]
  );

  const handleBtnRemoveDayClick = (day) => {
    const selectedDays = [...days];
    const selectedIndex = selectedDays.findIndex((selectedDay) =>
      DateUtils.isSameDay(selectedDay, day)
    );
    selectedDays.splice(selectedIndex, 1);
    setDays(selectedDays);
  };

  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm({
        days,
      });
    }
  };

  return (
    <Box className={classes.root}>
      <Grid container>
        <Grid item xs={3}>
          <Box className={classes.unavailableDays}>
            <Box className={classes.unavailableDaysHead}>
              {t(`${rootTranslate}.marked_as_unavailable`)}
            </Box>
            <Box className={classes.unavailableDaysItems}>
              {days
                .sort((a, b) => {
                  return a - b;
                })
                ?.map((item) => {
                  const { getDisplayDate3, getReturnDate } = formatDateTime(
                    item
                  );
                  const isBookedDay = !!bookedDaysKey[getReturnDate()];
                  return (
                    <Box
                      key={getReturnDate()}
                      className={classes.unavailableDaysItem}
                      onClick={() => {
                        calendarRef?.current?.showMonth(item);
                      }}
                    >
                      <Box>
                        <Grid container spacing={1}>
                          <Grid item>
                            <Indicator type={types.UNAVAILABLE} />
                          </Grid>
                          {isBookedDay && (
                            <Grid item>
                              <Indicator type={types.BOOKED} l />
                            </Grid>
                          )}
                        </Grid>
                      </Box>

                      <Box ml={1}> {getDisplayDate3()}</Box>
                      <IconButton
                        disableRipple
                        color="inherit"
                        className={classes.unavailableDaysItemBtn}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleBtnRemoveDayClick(item);
                        }}
                      >
                        <CloseIcon fontSize="inherit" color="inherit" />
                      </IconButton>
                    </Box>
                  );
                })}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={9}>
          <Box bgcolor="common.white" className={classes.right}>
            <BaseDatePicker
              datePickerProps={{
                numberOfMonths: 2,
                selectedDays: days,
                onDayClick: handleDayClick,
                modifiers: {
                  bookedDay: bookedDays,
                },
                pagedNavigation: true,
                showOutsideDays: true,
                fixedWeeks: true,
                disabledDays: [...inTripDays, ...bookedDays],
              }}
              min={new Date()}
              classes={datePickerClasses}
              ref={calendarRef}
            />
            <Box className={classes.indicators}>
              <Grid container spacing={1}>
                <Grid item>
                  <Indicator
                    type={types.UNAVAILABLE}
                    label="Date marked unavailable"
                  />
                </Grid>
                <Grid item>
                  <Indicator
                    type={types.BOOKED}
                    label="Vehicle assigned to booking"
                  />
                </Grid>
              </Grid>
            </Box>
            {haveOverlapUnavailableDate && (
              <Box className={classes.note}>
                <FormHelperText error>
                  {t(`${rootTranslate}.message_overlapping_unavailable_date`)}
                </FormHelperText>
              </Box>
            )}

            <Box className={classes.actions}>
              <Button color="secondary" size="medium" onClick={onCancel}>
                {t('ra.action.cancel')}
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={handleConfirm}
                disabled={isLoading}
              >
                {t('ra.action.confirm')}
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default MarkedAsUnavailableDate;
