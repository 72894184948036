import * as React from 'react';
import { ReferenceField } from 'react-admin';
import { useTheme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { get } from 'lodash';
import AlertTooltip from './AlertTooltip';

function ExtendedStatus(props) {
  const { record, source } = props;
  const status = get(record, source, '');
  const theme = useTheme();

  if (!status) {
    return null;
  }

  return (
    <Box display="inline-flex" marginLeft={theme.spacing(0.25)}>
      <AlertTooltip title={status} />
    </Box>
  );
}

const BookingNumberField = (props) => {
  const { record, source } = props;
  const number = get(record, source, '--');

  return (
    <Box display="flex" alignItems="flex-end">
      {number}
      <ReferenceField
        {...props}
        source="id"
        reference="booking-extended-status"
        link={false}
      >
        <ExtendedStatus source="extended_status" />
      </ReferenceField>
    </Box>
  );
};

export default BookingNumberField;
