import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { ReactComponent as LogoIcon } from 'src/assets/images/logoIcon.svg';
import { ReactComponent as LogoText } from 'src/assets/images/logoText.svg';
import BackgroundImage from 'src/assets/images/background.png';

import { Avatar, Button, Card, CardActions, Link } from '@material-ui/core';
import { createMuiTheme, makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import LockIcon from '@material-ui/icons/Lock';
import { useTranslate } from 'react-admin';

import { vibeTheme } from './themes';

import { useAuth0 } from '@auth0/auth0-react';
import { dashboardActionCreators } from 'store/actions';

const useStyles = makeStyles((theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: `url(${BackgroundImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    boxSizing: 'border-box',
    padding: '6em',
  },
  logo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logoIcon: {
    marginBottom: '10px',
  },
  card: {
    minWidth: 300,
  },
  placeholder: {
    height: '106px',
    visibility: 'hidden',
  },
  avatar: {
    margin: '1em',
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
  },
  hint: {
    marginTop: '1em',
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.grey[500],
  },
  actions: {
    marginTop: '1em',
    padding: '0 1em 1em 1em',
    display: 'flex',
    justifyContent: 'center',
  },
  signUpButton: {
    marginTop: '-1em',
  },
}));

const Login = () => {
  const { loginWithRedirect } = useAuth0();
  const translate = useTranslate();
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(dashboardActionCreators.showReminder(true));
    dispatch(dashboardActionCreators.rememberReminder());
  }, []);

  return (
    <div className={classes.main}>
      <div className={classes.logo}>
        <LogoIcon className={classes.logoIcon} />
        <LogoText />
      </div>
      <Card className={classes.card}>
        <div className={classes.avatar}>
          <Avatar className={classes.icon}>
            <LockIcon />
          </Avatar>
        </div>
        <div className={classes.hint}>
          {translate('messages__homepage_login_required')}
        </div>
        <CardActions className={classes.actions}>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            fullWidth
            onClick={() => loginWithRedirect({ screen: 'login' })}
          >
            {translate('ra.auth.sign_in')}
          </Button>
        </CardActions>

        <CardActions className={classes.actions}>
          <Link
            component="button"
            onClick={() => loginWithRedirect({ screen: 'signUp' })}
            underline="always"
            className={classes.signUpButton}
          >
            {translate('messages__sign_up')}
          </Link>
        </CardActions>
      </Card>
      <div className={classes.placeholder} />
    </div>
  );
};

Login.propTypes = {
  authProvider: PropTypes.func,
  previousRoute: PropTypes.string,
};

// We need to put the ThemeProvider decoration in another component
// Because otherwise the useStyles() hook used in Login won't get
// the right theme
const UnAuthenticated = (props) => (
  <ThemeProvider theme={createMuiTheme(vibeTheme)}>
    <Login {...props} />
  </ThemeProvider>
);

export default UnAuthenticated;
