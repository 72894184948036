import React from 'react';
import { get } from 'lodash';
import { Typography } from '@material-ui/core';

const VehicleMakeModelYearField = ({ record }) => {
  const text = `${get(record, 'vehicle_make.name', '--')} ${get(
    record,
    'vehicle_model.name',
    '--'
  )} ${get(record, 'vehicle_year', '--')}`;

  return <Typography variant="body2">{text}</Typography>;
};

export default VehicleMakeModelYearField;
