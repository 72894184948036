import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
} from '@material-ui/core';
import { get } from 'lodash';
import { Alert } from '@material-ui/lab';
import { useMutation, useNotify, useTranslate } from 'ra-core';
import { useState, useEffect } from 'react';
import { VEHICLE_ACTION } from '../const';

const ReservedToggle = ({ record: originalRecord, source }) => {
  const translate = useTranslate();
  const [record, setRecord] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const notify = useNotify();
  const [toggleReserved, { loading: toggleLoading }] = useMutation(
    {
      resource: 'vehicles',
      type: 'others',
    },
    {
      onSuccess: ({ data }) => {
        setRecord({
          ...originalRecord,
          [source]: data[source],
        });
        setOpenAlertDialog(false);
        setErrorMessage('');
        notify('resources.vehicles.messages__toggle_reserved_sucess');
      },
      onFailure: (error) => {
        // TODO
        const errorMessage = get(
          error,
          'body.messages.0.message',
          translate('ra.page.error')
        );

        setErrorMessage(errorMessage);
      },
    }
  );

  const isReserved = !!record[source];
  const iRoot = 'resources.vehicles';
  const iLabel = isReserved
    ? 'mark_unreserved_button_label'
    : 'mark_reserved_button_label';
  const tooltip = isReserved
    ? 'mark_unreserved_tooltip'
    : 'mark_reserved_tooltip';

  const onClick = () => {
    setOpenAlertDialog(true);
  };
  const onCloseDialog = () => {
    setOpenAlertDialog(false);
    setErrorMessage('');
  };
  const onToggle = () => {
    const { id } = record;

    toggleReserved({
      payload: {
        action: VEHICLE_ACTION.UPDATE_RESERVED,
        id,
        data: {
          [source]: !record[source],
        },
      },
    });
  };
  const alertMessage = isReserved
    ? 'constants__mark_unreserved_message'
    : 'constants__mark_reserved_message';

  useEffect(() => {
    setRecord(originalRecord);
  }, [originalRecord]);

  return (
    <>
      <Tooltip placement="top" title={translate(`${iRoot}.${tooltip}`)}>
        <Button
          variant="outlined"
          color="primary"
          onClick={onClick}
          disabled={toggleLoading}
        >
          {translate(`${iRoot}.${iLabel}`)}
        </Button>
      </Tooltip>
      <Dialog
        open={openAlertDialog}
        onClose={onCloseDialog}
        disableBackdropClick={toggleLoading}
        disableEscapeKeyDown={toggleLoading}
      >
        <DialogTitle>
          {translate('resources.vehicles.constants__mark_reserved_title')}
        </DialogTitle>
        <DialogContent>
          {translate(`${iRoot}.${alertMessage}`)}

          {errorMessage && (
            <Box mt={2}>
              <Alert variant="filled" severity="error">
                {errorMessage}
              </Alert>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={onCloseDialog}>
            <Box color="error.main">{translate('common__cancel')}</Box>
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={onToggle}
            disabled={toggleLoading}
          >
            {translate('common__confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ReservedToggle;
