import { Labeled, TextField } from 'react-admin';
import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  emptyText: {
    paddingBottom: '8px',
  },
}));

const ServiceTabContent = (props) => {
  const { record } = props;
  const classes = useStyles();

  const availableDeliveryTypes = record?.available_delivery_types?.reduce(
    (value, type) => {
      let valueType = '';
      switch (type) {
        case 'vehicle_delivery':
          valueType = 'Hotel Delivery';
          break;
        case 'hub':
          valueType = 'Airport Pickup';
          break;
        default:
          break;
      }

      return `${value.length > 0 ? `${value},` : ''} ${valueType}`;
    },
    ''
  );

  const availableHubs = record?.available_hubs?.reduce((value, hub) => {
    return `${value.length > 0 ? `${value},` : ''} ${hub.name}`;
  }, '');

  const newAvailableDeliveryTypes = { availableDeliveryTypes };
  const newAvailableHubs = { availableHubs };

  if (!availableDeliveryTypes) {
    return <div className={classes.emptyText}>No available services types</div>;
  }

  return (
    <Box component={Grid} container>
      <Grid
        component={Labeled}
        item
        xs={6}
        source="available_service_types"
        resource="vehicles"
      >
        <TextField
          source="availableDeliveryTypes"
          record={newAvailableDeliveryTypes}
          emptyText="--"
        />
      </Grid>
      {record?.available_hubs && record.available_hubs.length > 0 && (
        <Grid
          component={Labeled}
          item
          xs={6}
          source="available_hubs"
          resource="vehicles"
        >
          <TextField
            source="availableHubs"
            record={newAvailableHubs}
            emptyText="--"
          />
        </Grid>
      )}
    </Box>
  );
};

export default ServiceTabContent;
