import { useState, useEffect } from 'react';
import { Typography, Box } from '@material-ui/core';
import CustomSidebar from './CustomSidebar';
import CustomLayout from './CustomLayout';
import AppBar from './AppBar';
import Menu from './Menu';
import { vibeTheme } from './themes';

import request from 'providers/request';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory, useLocation } from 'react-router-dom';
import LoadingMessage from 'components/LoadingMessage';

export default (props) => {
  const history = useHistory();
  const location = useLocation();
  const {
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
    loginWithRedirect,
  } = useAuth0();
  const [registrationCompleted, setRegistrationCompleted] = useState(false);
  const [checkingReg, setCheckingReg] = useState(true);

  useEffect(() => {
    const checkReg = async () => {
      const url = '/registration-check';
      const onSuccess = ({ data }) => {
        const { reg_completed } = data;

        if (reg_completed) {
          setRegistrationCompleted(true);
          if (
            location.pathname === '/finish-registration' ||
            location.pathname === '/login'
          ) {
            history.push('/');
          } else {
            setCheckingReg(false);
          }
        } else {
          setCheckingReg(false);
          if (location.pathname !== 'finish-registration') {
            history.push('/finish-registration');
          }
        }
      };
      const onError = (error) => {
        /**
         * - we want to fetch new token with our custom claims here
         * "custom claims" contains some information like roles
         *
         * - getAccessTokenSilently does not work because it should
         * return token in cache if it is existed
         */
        loginWithRedirect();
        history.push('/finish-registration');
      };

      request({
        url,
        getAccessTokenSilently,
        onSuccess,
        onError,
      });
    };

    if (!isLoading) {
      if (isAuthenticated && !registrationCompleted) {
        checkReg();
      } else {
        setCheckingReg(false);
        if (!isAuthenticated && location.pathname !== '/login') {
          history.push('/login');
        }
      }
    }
  }, [
    registrationCompleted,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    getAccessTokenSilently,
    history,
    location,
    setRegistrationCompleted,
  ]);

  if (isLoading) {
    return <LoadingMessage iMessage="messages__homepage_check_user_info" />;
  }

  if (checkingReg) {
    return (
      <LoadingMessage iMessage="messages__homepage_check_registration_status" />
    );
  }

  return (
    <CustomLayout
      {...props}
      appBar={AppBar}
      sidebar={CustomSidebar}
      menu={Menu}
      theme={vibeTheme}
    >
      <Box mb={1} display="flex" justifyContent="space-between">
        <Typography
          id="react-admin-title"
          variant="h5"
          component="h1"
        ></Typography>
      </Box>
      {props.children}
    </CustomLayout>
  );
};
