import { useState } from 'react';
import {
  Dialog,
  DialogContent,
  Box,
  Button,
  makeStyles,
} from '@material-ui/core';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { useTranslate, useQuery, useMutation, useRefresh } from 'react-admin';
import { differenceInDays, addDays } from 'date-fns';
import { formatDateTime } from 'utils/format';
import { rootTranslate, VEHICLE_ACTION } from '../../const';
import SelectedMultipleDate from './SelectedMultipleDate';

const useDialogContentStyles = makeStyles(() => {
  return {
    root: {
      padding: '0 !important',
    },
  };
});

const useDialogStyles = makeStyles(() => {
  return {
    paper: {
      border: 0,
    },
  };
});

const types = {
  UNAVAILABLE: 'unavailable',
  BOOKING: 'booking',
  IN_TRIP: 'in-trip',
};

/**
 * Find booked days, unavailable days and in-trip days
 */
function praseDays(days = []) {
  const bookedDays = [];
  const inTripDays = [];
  const unavailableDays = [];
  const unavailableDaysID = {};

  for (let index = 0; index < days.length; index++) {
    const { event_type: eventType, from, to, id } = days[index];
    const fromDate = formatDateTime(from).dateTime;
    const toDate = formatDateTime(to).dateTime;
    const distance = differenceInDays(toDate, fromDate);
    for (let index = 0; index <= distance; index++) {
      const d = addDays(fromDate, index);
      const { dateTime, getReturnDate } = formatDateTime(d);
      if (eventType === types.UNAVAILABLE) {
        unavailableDays.push(dateTime);
        unavailableDaysID[getReturnDate()] = id;
      } else if (eventType === types.BOOKING) {
        bookedDays.push(dateTime);
      } else if (eventType === types.IN_TRIP) {
        inTripDays.push(dateTime);
      }
    }
  }
  return {
    bookedDays,
    unavailableDays,
    unavailableDaysID,
    inTripDays,
  };
}

const MarkedAsUnavailableDate = ({ id }) => {
  const refresh = useRefresh();
  const { data = [], loading } = useQuery({
    type: 'others',
    resource: 'vehicles',
    payload: {
      action: VEHICLE_ACTION.GET_OCCUPATIONS,
      id,
      data: {
        from_date: formatDateTime(new Date()).getReturnDate(),
      },
    },
  });
  const [addUnavailableDays, { loading: adding }] = useMutation();
  const [deleteUnavailableDays, { loading: deleting }] = useMutation();

  const [openDialog, setOpenDialog] = useState(false);
  const t = useTranslate();
  const dialogContentClasses = useDialogContentStyles();
  const dialogClasses = useDialogStyles();
  const {
    bookedDays,
    unavailableDays,
    unavailableDaysID,
    inTripDays,
  } = praseDays(data);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleConfirm = ({ days = [] }) => {
    const deleteIds = [];
    const addDays = [];
    const calls = [];

    /**
     * Find days added or remove from unavailable days
     * compare between "days" and "unavailableDaysID"
     * The days exist in "days" but not exist "unavailableDaysID" is the added days
     * The days exist in "unavailableDaysID" and "days" is the days do not change
     * The days exist in "unavailableDaysID" and but not exist in "days" is the deleted days
     */
    for (let index = 0; index < days.length; index++) {
      const key = formatDateTime(days[index]).getReturnDate();
      if (unavailableDaysID[key]) {
        unavailableDaysID[key] = 0; // the days do not change
      } else {
        addDays.push(key); // the added days
      }
    }
    // the deleted days
    for (var key in unavailableDaysID) {
      if (unavailableDaysID[key]) {
        deleteIds.push(unavailableDaysID[key]);
      }
    }

    if (deleteIds.length) {
      calls.push(
        new Promise((resolve) => {
          deleteUnavailableDays(
            {
              type: 'others',
              resource: 'vehicles',
              payload: {
                action: VEHICLE_ACTION.DELETE_OCCUPATIONS,
                id,
                data: {
                  ids: [...new Set(deleteIds)].join(','), // remove duplicate value
                },
              },
            },
            {
              onSuccess: (res) => {
                resolve(true);
              },
              onFailure: (res) => {
                resolve(false);
              },
            }
          );
        })
      );
    }

    if (addDays.length) {
      calls.push(
        new Promise((resolve) => {
          addUnavailableDays(
            {
              type: 'others',
              resource: 'vehicles',
              payload: {
                action: VEHICLE_ACTION.ADD_OCCUPATIONS,
                id,
                data: {
                  type: types.UNAVAILABLE,
                  date: addDays,
                },
              },
            },
            {
              onSuccess: (res) => {
                resolve(true);
              },
              onFailure: (res) => {
                resolve(false);
              },
            }
          );
        })
      );
    }

    Promise.all(calls).then((res) => {
      refresh();
      setOpenDialog(false);
    });
  };

  const handleCancel = () => {
    setOpenDialog(false);
  };

  return (
    <Box>
      <Button
        onClick={handleOpenDialog}
        color="primary"
        startIcon={<DateRangeIcon />}
        disabled={loading}
      >
        {t(`${rootTranslate}.add_unavailability`, {
          number: unavailableDays.length,
        })}
      </Button>
      {!loading && (
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          maxWidth="md"
          classes={dialogClasses}
        >
          <DialogContent classes={dialogContentClasses}>
            <Box width="980px" maxWidth="100%">
              <SelectedMultipleDate
                onConfirm={handleConfirm}
                onCancel={handleCancel}
                bookedDays={bookedDays}
                unavailableDays={unavailableDays}
                isLoading={adding || deleting}
                inTripDays={inTripDays}
              />
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </Box>
  );
};

export default MarkedAsUnavailableDate;
