import {
  Datagrid,
  FunctionField,
  TextField,
  useQueryWithStore,
  useTranslate,
} from 'react-admin';
import DateField from 'components/DateField';
import { Box, Grid } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { CustomList } from 'layout';
import CurrencyField from 'components/CurrencyField';
import { formatLocalDate, formatCurrency } from 'utils/format';
import { DISPLAYED_DATE_FORMAT } from 'src/constants';

const renderVehile = (record) => {
  const { make = '--', model = '--', year = '--' } = record;

  return `${make} ${model} ${year}`;
};

const parseRanges = (location) => {
  const { pathname } = location; // example "/invoices/2020-11-13--2020-11-20"
  const date = pathname.replace('/invoices/', ''); // example "2020-11-13--2020-11-20"
  let [startDate, endDate] = date ? date.split('--') : [];

  /**
   * Please note `startDate` and `endDate` here **JUST FOR DISPLAYING IN TITLE**
   */

  if (startDate) {
    startDate = startDate.replace(/-/g, () => '/'); // example 2020-11-13 => 2020/11/13
  }

  if (endDate) {
    endDate = endDate.replace(/-/g, () => '/'); // example 2020-11-20 => 2020/11/20
  }

  return {
    startDate: formatLocalDate({
      datetime: startDate,
      dateFormat: DISPLAYED_DATE_FORMAT,
    }),
    endDate: formatLocalDate({
      datetime: endDate,
      dateFormat: DISPLAYED_DATE_FORMAT,
    }),
  };
};

const InvoiceList = (props) => {
  const translate = useTranslate();
  const location = useLocation();
  const { loaded: totalLoaed, data: totalData } = useQueryWithStore({
    type: 'others',
    resource: 'invoices',
    payload: { action: 'GET_TOTAL' },
  });
  const title = translate('resources.invoices.title', parseRanges(location));
  const { total_earning_net: totalNet, total_earning_gross: totalGross } =
    totalData || {};
  const totalGrossText = `${translate(
    'resources.invoices.gross_label'
  )} ${formatCurrency(totalGross)}`;
  const showTotal =
    totalLoaed &&
    typeof totalGross !== 'undefined' &&
    typeof totalNet !== 'undefined';
  /*
   * Note: we still show total in case
   * `totalGross === 0` OR `totalNet === 0`
   */

  return (
    <>
      {showTotal && (
        <Box component={Grid} my={2} container justify="flex-end">
          <Box textAlign="right">{totalGrossText}</Box>
          <Box ml={4} textAlign="right">
            <Box component="span" fontWeight="bold">
              {translate('resources.invoices.net_earning_label')}
            </Box>
            <Box display="inline-block" ml={1}>
              {formatCurrency(totalNet)}
            </Box>
          </Box>
        </Box>
      )}
      <CustomList
        {...props}
        title={title}
        sort={{ field: 'booking_number', order: 'DESC' }}
        perPage={25}
      >
        <Datagrid>
          <TextField sortable={false} source="booking_number" emptyText="--" />
          <DateField sortable={false} source="start_trip" emptyText="--" />
          <DateField sortable={false} source="end_trip" emptyText="--" />
          <FunctionField
            sortable={false}
            label={translate('resources.invoices.fields.make_model_year')}
            render={renderVehile}
          />
          <TextField sortable={false} source="plate_number" emptyText="--" />
          <DateField sortable={false} source="issued_date" emptyText="--" />
          <CurrencyField sortable={false} source="gross_earning" />
          <CurrencyField sortable={false} source="net_earning" />
        </Datagrid>
      </CustomList>
    </>
  );
};

export default InvoiceList;
