import {
  TabbedShowLayout,
  Tab,
  Show,
  TextField,
  ReferenceField,
  Labeled,
  useTranslate,
} from 'react-admin';
import { Grid, Box } from '@material-ui/core';
import { rootTranslate } from './const';
import VehicleMakeModelYearField from 'components/VehicleMakeModelYearField';
import DateField from 'components/DateField';
import ServicesField from './components/ServicesField';
import TitleWithBackButton from './components/TitleWithBackButton';

const BookingDetail = (props) => {
  const t = useTranslate();
  return (
    <Box component={Grid} container spacing={2} maxWidth={600} px={1} py={2}>
      <Grid
        item
        xs={12}
        component={Labeled}
        label={`${rootTranslate}.constants__make_model_year`}
      >
        <ReferenceField
          {...props}
          basePath="/vehicles"
          reference="vehicles"
          source="vehicle_id"
          linkType="show"
          sortable={false}
        >
          <VehicleMakeModelYearField />
        </ReferenceField>
      </Grid>
      <Grid
        item
        xs={12}
        component={Labeled}
        label={`${rootTranslate}.fields.number`}
        source="number"
      >
        <TextField {...props} source="number" />
      </Grid>
      <Grid
        item
        xs={12}
        component={Labeled}
        label={`${rootTranslate}.fields.pickup_location`}
        source="pickup_location.name"
      >
        <TextField {...props} source="pickup_location.name" />
      </Grid>
      <Grid
        item
        xs={6}
        component={Labeled}
        label={`${rootTranslate}.fields.pickup_datetime`}
        source="pickup_datetime"
      >
        <DateField
          {...props}
          source="pickup_datetime"
          showTime
          sortable={false}
        />
      </Grid>
      <Grid
        item
        xs={6}
        component={Labeled}
        label={`${rootTranslate}.fields.use_pickup_service`}
        source="use_pickup_service"
      >
        <ServicesField
          {...props}
          source="use_pickup_service"
          activeText={t(`${rootTranslate}.constants__pickup_service_active`)}
        />
      </Grid>

      <Grid
        item
        xs={6}
        component={Labeled}
        label={`${rootTranslate}.fields.return_datetime`}
        source="return_datetime"
      >
        <DateField {...props} source="return_datetime" showTime />
      </Grid>
      <Grid
        item
        xs={6}
        component={Labeled}
        label={`${rootTranslate}.fields.use_dropoff_service`}
        source="use_dropoff_service"
      >
        <ServicesField
          {...props}
          source="use_dropoff_service"
          activeText={t(`${rootTranslate}.constants__dropoff_service_active`)}
        />
      </Grid>
    </Box>
  );
};

const BookingShow = (props) => {
  const t = useTranslate();
  return (
    <Show {...props} title={<TitleWithBackButton />}>
      <TabbedShowLayout>
        <Tab label={t(`${rootTranslate}.tab__booking_info`)}>
          <BookingDetail />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default BookingShow;
