import { makeStyles } from '@material-ui/core/styles';
import { List } from 'react-admin';
import ListActions from './ListActions';

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#fff',
    borderRadius: theme.shape.borderRadius,
  },
  content: {
    padding: '0 16px',
    border: 'none',
  },
}));

const CustomList = (props) => {
  const classes = useStyles();
  const { actions, bulkActionButtons = false, ...rest } = props;
  return (
    <List
      {...rest}
      classes={classes}
      bulkActionButtons={bulkActionButtons}
      actions={actions}
    ></List>
  );
};
CustomList.defaultProps = {
  actions: <ListActions />,
};

export default CustomList;
