import { Datagrid, FunctionField, TextField, useTranslate } from 'react-admin';
import DateField from 'components/DateField';
import { Link } from 'react-router-dom';
import CurrencyField from 'components/CurrencyField';
import { CustomList } from 'layout';

const renderBillingPeriodRange = (record) => {
  const { start_date: startDate, end_date: endDate } = record;
  const range = `${startDate}--${endDate}`; // Example `2020-11-13--2020-11-20`
  const url = `/invoices/${range}`;

  return (
    <Link to={url}>
      <DateField source="start_date" record={record} emptyText="--" />
      {' - '}
      <DateField source="end_date" record={record} emptyText="--" />
    </Link>
  );
};

const BillingPeriodList = (props) => {
  const translate = useTranslate();
  const title = translate('resources.invoice_weekly.title');

  return (
    <CustomList
      {...props}
      title={title}
      sort={{ field: 'start_date', order: 'DESC' }}
      perPage={25}
    >
      <Datagrid>
        <FunctionField
          render={renderBillingPeriodRange}
          label={translate('resources.invoice_weekly.fields.billing_period')}
        />
        <TextField sortable={false} source="number_invoices" emptyText="--" />
        <CurrencyField sortable={false} source="net_earning" />
      </Datagrid>
    </CustomList>
  );
};

export default BillingPeriodList;
