import { Typography } from '@material-ui/core';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'i18n/en';

const i18nProvider = polyglotI18nProvider(() => englishMessages, 'en');

const LoadingMessage = (props) => {
  const { iMessage } = props;
  return (
    <div className="loader-container">
      <div className="loader">Loading...</div>
      <Typography variant="h6" gutterBottom>
        {i18nProvider.translate(iMessage)}
      </Typography>
    </div>
  );
};

export default LoadingMessage;
