import React from 'react';
import { get } from 'lodash';
import { useTranslate, useQuery } from 'react-admin';
import { Grid, Box, SvgIcon, makeStyles } from '@material-ui/core';
import { ReactComponent as CarRentalIcon } from 'assets/images/car-rental.svg';
import { ReactComponent as CarRentalLockIcon } from 'assets/images/car-rental-lock.svg';

import { CardHeader, CardContent, Card } from './Card';
import TaskItem from './TaskItem';
import { rootTranslate, actions } from '../const';

const useCardStyles = makeStyles(() => {
  return {
    root: {
      height: '100%',
    },
  };
});

function OngoingEvent() {
  const t = useTranslate();
  const cardClasses = useCardStyles();

  const { data = {} } = useQuery({
    type: 'others',
    resource: 'dashboard',
    payload: { action: actions.API_GET_ON_GOING },
  });
  const inStrip = get(data, 'in-trip', 0);
  const delay = get(data, 'delay', 0);

  return (
    <Card classes={cardClasses}>
      <CardHeader title={t(`${rootTranslate}.heading__ongoing_event`)} />
      <CardContent>
        <Box
          pt={{
            xs: 2,
            md: 3,
          }}
        />
        <Grid container justify="space-between" spacing={3}>
          <Grid item xs={12}>
            <TaskItem
              icon={
                <SvgIcon
                  viewBox="0 0 42 42"
                  component={CarRentalIcon}
                  fontSize="inherit"
                />
              }
              number={inStrip}
              label={t(`${rootTranslate}.label__in_trip`)}
              iconBgColor="common.lightPurple"
              seeAllUrl="/vehicle-in-trip"
            />
          </Grid>
          <Grid item xs={12}>
            <TaskItem
              icon={
                <SvgIcon
                  viewBox="0 0 42 42"
                  component={CarRentalLockIcon}
                  fontSize="inherit"
                />
              }
              number={delay}
              label={t(`${rootTranslate}.label__return_delayed`)}
              iconBgColor="common.lightPurple"
              seeAllUrl="/vehicle-delay"
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default OngoingEvent;
