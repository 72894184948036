import englishMessages from 'ra-language-english';

const customEnglishMessages = {
  ...englishMessages,
  pos: {
    configuration: 'Configuration',
  },
  constants__payment_type__checking: 'Checking',
  constants__payment_type__saving: 'saving',
  signup: {
    basic: 'Basic Information',
    contact: 'Contact Information',
    payment: 'Payment Information',
    address: 'Address',
    number_of_used_vehicles: 'Number of vehicles',
    country: 'Country',
    state: 'State',
    city: 'City',
    postal_code: 'Postal Code',
    name: 'Name',
    email: 'Email',
    phone: 'Phone',
    lat: 'Latitude',
    long: 'Longitude',
    payment_bank: 'Bank Name',
    payment_account_name: 'Bank Account',
    payment_routing_number: 'Routing Number',
    payment_account_type: 'Account Type',
    payment_account_number: 'Account Number',
    validations_routing_number: 'Must be 9 characters',
  },
  resources: {
    vehicles: {
      name: 'Vehicle |||| Vehicles',
      fields: {
        vin: 'VIN Number',
        dealer: 'Dealer',
        vehicle_make: 'Make',
        vehicle_model: 'Model',
        vehicle_year: 'Year',
        category: 'Category',
        plate_number: 'License plate',
        stock_number: 'Stock number',
        body_style: 'Body Style',
        number_of_doors: 'Number of Doors',
        number_of_seats: 'Number of Seats',
        engine: 'Engine',
        transmission: 'Transmission',
        fuel_type: 'Fuel Type',
        fuel_tank_capacity: 'Gas Tank Capacity',
        exterior_color: 'Exterior Color',
        interior_color: 'Interior Color',
        city_mpg: 'City MPG',
        highway_mpg: 'Highway MPG',
        plate_issued_date: 'Registration From',
        insurance_policy_number: 'Insurance Policy Number',
        first_day_in_circulation: 'The 1st Day In Circulation',
        state: 'State',
        vehicle_features: 'Features',
        description: 'Vehicle Description',
        selling_price: 'Selling Price',
        book_value: 'Book Value',
        available_from: 'Available From',
        available_to: 'Available To',
        seats: 'Passenger seats',
        'allocation_type.name': 'Allocation',
        available_service_types: 'Available service types',
        available_hubs: 'Available Hubs',
      },
      constants__status_pending: 'Pending',
      constants__status_available: 'Active',
      constants__status_unavailable: 'Inactive',
      belong_to: 'Belong To',
      make_model_year: 'Make Model Year',
      available: 'Available',
      to_date: 'to %{date}',
      from_date: 'from %{date}',
      tab_information: 'Information',
      tab_registration: 'Registration Document',
      tab_services: 'Services',
      tab_others: 'Features & Others',
      status_desc_available: 'Ready to be rented',
      status_desc_unavailable: 'Not available for rent any longer',
      status_desc_pending: 'Not ready to be rented',
      constants__deactivate_vehicle: 'Deactivate Vehicle',
      constants__deactivate_message:
        'Please provide a reason of why you want to deactivate <strong>%{number}</strong>',
      messages__change_status_sucess: 'Changed status successfully',
      messages__unable_to_deactivate:
        'Cannot deactivate this vehicle. The vehicle has an ongoing booking with booking number %{bookingNumbers}',
      constants__unable_to_deactivate: 'Unable to deactivate',
      all_vehicles: 'All Vehicles',
      mark_reserved_button_label: 'Mark reserved',
      mark_unreserved_button_label: 'Mark unreserved',
      mark_reserved_tooltip:
        'Mark this vehicle is reserved. Reserved vehicles never appear on Customer’s side and not available for Customer Booking flow',
      mark_unreserved_tooltip:
        'Unreserved vehile, it will be available for Customer Booking flow',
      constants__mark_reserved_title: 'Are you sure?',
      constants__mark_reserved_message:
        'Reserved vehicles never appear on Customer’s side and not available for Customer Booking flow.',
      constants__mark_unreserved_message:
        'Unreserved vehiles will be available for Customer Booking flow.',
      messages__toggle_reserved_sucess: 'Updated successfully!',
      label__type_listed: 'Listed',
      label__type_unlisted: 'Unlisted',
      label__type_reserved: 'Reserved',
      label__type_booked: 'Booked',
      label__type_in_trip: 'In trip',
      marked_as_unavailable: 'Marked as unavailable',
      add_unavailability: 'Add Unavailability (%{number})',
      message_overlapping_unavailable_date:
        "There's a booked trip overlapping the date you’ve selected.\nThe trip will be replaced with other vehicles. Are you sure?",
    },
    import_vehicles: {
      name: 'Import vehicles',
      upload_button_label: 'Upload CSV file',
      recheck_button_label: 'Re-check',
      import_button_label: 'Add to system',
      cancel_button_label: 'Cancel',
      import_all_button_label: 'Import All',
      download_failed_button_label: 'Fail Log',
      complete_button_label: 'Complete',
      imported_label: 'Imported',
      failed_label: 'Failed',
      partial_warning_dialog_title: 'Partial Import',
      dialog_title: 'Add vehicles to system',
      dialog_message: 'vehicles selected. Ready to add to the system',
      import_all_dialog_title: 'Import All',
      cancel_dialog_title: 'Cancel importing',
      result_title: 'Import Results',
      upload_failed_result_title: 'Upload Failed',
      messages__startup_loading: 'Checking for any importing process',
      messages__initial_loading: 'Checking status',
      messages__upload_loading: 'File is uploading',
      messages__import_loading: 'Importing to the system',
      messages__cancel_failed: 'Cancel error, please try again later',
      messages__complete_failed: 'Can not complete, please try again later',
      messages__no_inventory: 'There is no inventory to display',
      messages__in_process:
        'The "upload" process is running, you can select vehicles to be imported after that, please come back later or hit "Re-check" button.',
      messages__importing:
        'The "import" process is nearly done, please come back later or hit "Re-check" button.',
      messages__partial_importing_warning:
        'vehicles are not selected, are you sure to continue?',
      messages__all_importing: 'Are you sure to import everything?',
      messages__cancel_job:
        'Are you sure you want to stop this progress? Imported vehicle will not be saved.',
      messages_import:
        'Import your .CSV file by drag it to the space below or browse from your computer',
      messages_import_processing:
        'Importing in progress.... Please come back in a few minutes',
      messages_importing_vehicles: 'Importing vehicles',
      messages_complete: 'Complete!',
      messages_please_fill_all_plate_number:
        'Please fill in all License plate and Stock number That you selected',
      placeholder__plate_number: 'License plate (eg. KTX1111)',
      placeholder__stock_number: 'Stock number',
      placeholder__stock_number_not_selected: 'Stock number is not selected',
      placeholder__plate_number_not_selected: 'Vehicle is not selected',
    },
    invoice_weekly: {
      name: 'Invoice |||| Invoices',
      menuName: 'Earning report',
      title: 'Invoices',
      fields: {
        billing_period: 'Billing period',
        number_invoices: 'Number of issues',
        net_earning: 'Net earning',
      },
    },
    invoices: {
      name: 'Invoice |||| Invoices',
      title: 'Billing Period %{startDate} - %{endDate}',
      fields: {
        booking_number: 'Booking number',
        start_trip: 'Trip starts',
        end_trip: 'Trip ends',
        duration: 'Duration (days)',
        make_model_year: 'Make/Model/Year',
        plate_number: 'License plate',
        issued_date: 'Invoice date',
        gross_earning: 'Gross revenue',
        net_earning: 'Net revenue',
      },
      gross_label: 'Gross',
      net_earning_label: 'Net Earning (70%)',
    },
    profile: {
      name: 'My profile',
      contact: 'Contact Info',
      person: 'Personal Info',
      payment: 'Payment Info',
      dealer_info: 'Dealer Info',
      secondary_contact: 'Secondary Contact',
      primary_contact: 'Primary Contact',
      additional_contact: 'Additional Contact',
      fields: {
        first_name: 'First Name',
        last_name: 'Last Name',
        active: 'Active',
        address: 'Address',
        number_of_used_vehicles: 'Number of vehicles',
        country: 'Country',
        state: 'State',
        city: 'City',
        postal_code: 'Postal Code',
        name: 'Name',
        email: 'Email',
        phone: 'Phone',
        lat: 'Latitude',
        lng: 'Longitude',
        payment_bank: 'Bank Name',
        payment_account_name: 'Bank Account',
        payment_routing_number: 'Routing Number',
        payment_account_type: 'Account Type',
        payment_account_number: 'Account Number',
        validations_routing_number: 'Must be 9 characters',
      },
      messages__update_profile_success: 'Updated Profile Success',
      messages__update_profile_unsuccess: 'Updated Profile UnSuccess',
      messages__status_available: 'Active |||| Approved Dealer',
      messages__status_unavailable: 'Inactive |||| UnApproved Dealer',
      messages__updated_profile_picture: 'Updated profile picture',
      messages__button_upload_title: 'Upload profile picture',
      messages__drop_zone_label:
        'Drop a picture to upload, or click to select it.',
      placeholder__phone: '(xxx) xxx-xxxx or %{number} digit',
    },
    dashboard: {
      heading__tasks: 'Tasks',
      label__from: 'From',
      label__to: 'To',
      label__arrival: 'Arrival',
      label__returning: 'Returning',
      label__no_show: 'No show',
      label__maintenance: 'Maintenance',
      label__see_all: 'See All',
      label__listed_vehicle: 'Listed vehicle',
      label__unlisted_vehicle: 'Unlisted vehicle',
      label__reserved_vehicle: 'Reserved vehicle',
      label__booked_vehicle: 'Booked vehicle',
      label__in_trip: 'In trip',
      label__return_delayed: 'Return delayed',
      label__this_year: 'This year',
      label__all_time: 'All time',
      label__earning_of: 'Earning of',
      label__business_metrics: 'Business metrics',
      heading__no_show: 'No show',
      heading__maintenance: 'Maintenance',
      heading__alerts: 'Alerts',
      heading__ongoing_event: 'Ongoing event',
      heading__inventory: 'Inventory',
      heading__earnings: 'Earnings',
      heading__welcome: 'Hello %{name}, welcome back!',
    },
    bookings: {
      name: 'Booking |||| Bookings',
      fields: {
        number: 'Booking ID',
        customer: 'Customer',
        pickup_datetime: 'Arrival date & time',
        return_datetime: 'Return date & time',
        pickup_location: 'Location',
        state: 'Booking status',
        use_pickup_service: 'Pick-up Service',
        use_dropoff_service: 'Drop-off Service',
      },
      constants__make_model_year: 'Vehicle Assigned (Make, Model, Year)',
      constants__status_in_trip: 'In trip',
      constants__status_booked: 'Booked',
      constants__status_cancel: 'Cancelled',
      constants__status_paid: 'Paid',
      constants__status_complete: 'Completed',
      constants__status_cancelled: 'Cancelled',
      constants__pickup_service_active: 'Arrival pick-up',
      constants__dropoff_service_active: 'Departure drop-off',
      constants__service_unactive: 'Self own',
      tab__booking_info: 'Booking Info',
      title__booking_id: 'Booking ID',
    },
  },
  messages__homepage_check_user_info: 'Checking user info',
  messages__homepage_check_registration_status: 'Checking registration status',
  messages__homepage_loading_resources: 'Loading resources',
  messages__homepage_login_required: 'You have to log in',
  messages__confirm_delete: 'Delete %{name}',
  messages__registration_completed: 'Registration Completed',
  messages__registration_error: 'Registration Error',
  messages__logout: 'Are you sure you want to logout?',
  messages__sign_up: "Don't have an account? Sign up",
  messages__validation_phone: 'Phone is wrong format',
  common__activate: 'Reactivate',
  common__deactivate: 'Deactivate',
  common__description: 'Description',
  common__cancel: 'Cancel',
  common__confirm: 'Confirm',
  common__selected: 'Selected',
  common__okay: 'Okay',
  common__all: 'All',
  messages__unValid_phone_number: 'Please Enter A Valid Phone Number',
};

export default customEnglishMessages;
