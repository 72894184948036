import React, { useEffect, useCallback, useState } from 'react';
import { isBefore } from 'date-fns';
import { useTranslate } from 'react-admin';
import { Box, Grid, useMediaQuery } from '@material-ui/core';
import DatePicker from 'components/DatePicker';
import { formatDateTime } from 'utils/format';
import { rootTranslate } from '../const';

const SelectFromTo = ({ onChange, defaultValue = {} }) => {
  const t = useTranslate();
  const isLg = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const [from, setFrom] = useState(defaultValue?.from || null);
  const [to, setTo] = useState(defaultValue?.to || null);

  const handleChangeFrom = useCallback(
    (d) => {
      if (d && to) {
        const { dateTime: toDate } = formatDateTime(to);
        if (isBefore(toDate, d)) {
          setTo(d.toISOString());
        }
      }
      setFrom(d.toISOString());
    },
    [to]
  );

  const handleChangeTo = useCallback((d) => {
    setTo(d.toISOString());
  }, []);

  useEffect(() => {
    if (onChange) {
      onChange({
        from,
        to,
      });
    }
  }, [from, to]);

  return (
    <Grid container justify="space-between" spacing={isLg ? 3 : 1}>
      <Grid item alignItems="center" xs={12} lg={6} xl={5}>
        <Box display="flex" alignItems="center">
          <Box
            pr={1}
            width={{
              xs: '55px',
              lg: 'auto',
            }}
            fontWeight="bold"
          >
            {`${t(`${rootTranslate}.label__from`)}:`}
          </Box>
          <Box width="100%">
            <DatePicker value={from} onChange={handleChangeFrom} />
          </Box>
        </Box>
      </Grid>
      <Grid item alignItems="center" xs={12} lg={6} xl={5}>
        <Box display="flex" alignItems="center">
          <Box
            pr={1}
            width={{
              xs: '55px',
              lg: 'auto',
            }}
            fontWeight="bold"
          >
            {`${t(`${rootTranslate}.label__to`)}:`}
          </Box>

          <Box width="100%">
            <DatePicker
              minDate={new Date(from)}
              value={to}
              onChange={handleChangeTo}
            />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default SelectFromTo;
