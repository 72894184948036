import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { useQuery, useTranslate } from 'react-admin';
import FlightLandIcon from '@material-ui/icons/FlightLand';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';
import { buildUrlFromParams, formatDateTime } from 'utils/format';
import { useSelector, useDispatch } from 'react-redux';
import { dashboardActionCreators } from 'store/actions';
import { dashboardSelectors } from 'store/selectors';
import { actions } from '../const';
import { CardHeader, CardContent, Card } from './Card';
import SelectFromTo from './SelectFromTo';
import TaskItem from './TaskItem';
import { rootTranslate } from '../const';

function Tasks(props) {
  const t = useTranslate();
  const dispatch = useDispatch();
  const { fromDate, toDate } = useSelector(dashboardSelectors.getDate);
  const rangeDate = {
    from: fromDate,
    to: toDate,
  };
  const { getReturnDate: getReturnFromDate } = formatDateTime(fromDate);
  const { getReturnDate: getReturnToDate } = formatDateTime(toDate);
  const { data = {} } = useQuery({
    type: 'others',
    resource: 'dashboard',
    payload: {
      action: actions.API_GET_TASKS,
      data: { from: getReturnFromDate(), to: getReturnToDate() },
    },
  });

  const { arriving = 0, returning = 0 } = data;

  const handleChangeDate = (values) => {
    dispatch(
      dashboardActionCreators.updateDate({
        fromDate: values?.from,
        toDate: values?.to,
      })
    );
  };

  return (
    <Card>
      <CardHeader title={t(`${rootTranslate}.heading__tasks`)} />
      <CardContent>
        <SelectFromTo defaultValue={rangeDate} onChange={handleChangeDate} />
        <Box
          pt={{
            xs: 2,
            md: 4.5,
          }}
        />
        <Grid container justify="space-between" spacing={3}>
          <Grid item xs={12} xl={5}>
            <TaskItem
              icon={<FlightLandIcon fontSize="inherit" />}
              number={arriving}
              label={t(`${rootTranslate}.label__arrival`)}
              seeAllUrl={buildUrlFromParams('/bookings-arrival', {
                filter: JSON.stringify({
                  fromDate: rangeDate?.from,
                  toDate: rangeDate?.to,
                }),
              })}
            />
          </Grid>
          <Grid item xs={12} xl={5}>
            <TaskItem
              icon={<FlightTakeoffIcon fontSize="inherit" />}
              number={returning}
              label={t(`${rootTranslate}.label__returning`)}
              seeAllUrl={buildUrlFromParams('/bookings-returning', {
                filter: JSON.stringify({
                  fromDate: rangeDate?.from,
                  toDate: rangeDate?.to,
                }),
              })}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default Tasks;
