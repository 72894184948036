import { Title, useTranslate } from 'react-admin';
import {
  Box,
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Grid,
  Typography,
} from '@material-ui/core';
import { rootTranslate } from '../const';

/**
 * Show upload fail reason
 * @param  {[string]}   options.failedMessage         [Message from backend]
 * @param  {[boolean]}  options.cancelLoading         ["true" while calling cancel action]
 * @param  {[function]} options.onOpenConfirmCancel   [Handler to show cancel confirm]
 */
const UploadFailedResult = ({
  failedMessage,
  cancelLoading,
  onOpenConfirmCancel,
}) => {
  const translate = useTranslate();

  return (
    <Card>
      <Title title={translate(`${rootTranslate}.name`)} />
      <CardHeader
        title={translate(`${rootTranslate}.upload_failed_result_title`)}
      />
      <Grid container component={CardContent}>
        <Grid item xs={12}>
          <Typography variant="body2" component="span">
            <Box component="span" fontWeight="bold" color="error.main">
              {failedMessage}
            </Box>
          </Typography>
        </Grid>
      </Grid>
      <CardActions>
        <Grid container alignItems="center" justify="flex-end">
          <Button
            variant="contained"
            onClick={onOpenConfirmCancel}
            disabled={cancelLoading}
          >
            <Box color="error.main">
              {translate(`${rootTranslate}.cancel_button_label`)}
            </Box>
          </Button>
        </Grid>
      </CardActions>
    </Card>
  );
};

export default UploadFailedResult;
