import { useTranslate } from 'react-admin';
import { get } from 'lodash';
import TitleWithBackButton from 'components/TitleWithBackButton';

const VehicleTitle = ({ record, basePath }) => {
  const trans = useTranslate();
  let title = trans('resources.vehicles.name', { smart_count: 1 });
  if (record) {
    const make = get(record, 'vehicle_make.name');
    const model = get(record, 'vehicle_model.name');
    const year = record.vehicle_year;
    title += ` "${make} ${model} ${year}"`;
  }
  return <TitleWithBackButton basePath={basePath}>{title}</TitleWithBackButton>;
};

export default VehicleTitle;
