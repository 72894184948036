const dataProvider = {
  others: ({ params }) => {
    const { action, data } = params;
    switch (action) {
      case 'GET_PROFILE':
        return {
          url: '/profile',
          method: 'GET',
          parser: (json) => json,
        };
      case 'UPDATE_PROFILE':
        return {
          url: `/profile`,
          method: 'PUT',
          body: JSON.stringify(data),
          parser: (json) => json,
        };
      case 'UPLOAD_PICTURE': {
        const body = new FormData();
        body.append('image', params.data.image);
        return {
          url: '/me/profile-picture',
          body,
          method: 'POST',
          isFile: true,
          parser: (json) => json,
        };
      }
      default:
        break;
    }
  },
};

export default dataProvider;
